export const TooltipMessagesTypes = {
        DIMENSIONS_250_250 : 'Dimensions (250 x 250)',
        DIMENSIONS_1024_768 : 'Dimensions (1024 x 768)',
        FORMAT_JPG : 'Format (jpg)',
        SIZE_50 : 'Size (50K)',
        SIZE_250 : 'Size (250K)',
        NOTIFICATIONS_NO_DATA_FOR_ORIENTATION: 'We have no data on users in orientation mode, so there is no segmentation.',
        LATITUDE_EXAMPLE: 'Example: 51.591675041964166',
        ONLY_NUMBERS_NO_CHARACHTERS_SYMBOLS: 'Only numbers are allowed, without special characters or symbols.',
        LONGTITUDE_EXAMPLE: 'Example: 4.784625708463083',
        GENERAL_STUDENT_DISCOUNT: "Please note! Never check this box for companies with the following categories: 'Cafés en kroegen (18+)' and 'Alcohol (18+)'. The NIX18 won’t be visible!",
        ORIENTATION_MODE_NOTE: "User data is not stored while in Orientation mode.",
        ACCOUNT_OWNER_MODE_NOTE:"Only users in Unlimited and Limited Plus modes have Account Owner."
    }