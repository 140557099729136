import React, {useEffect, useState} from 'react';
import {useFilters, useGlobalFilter, usePagination, useSortBy, useTable} from 'react-table';

import {toast} from "react-toastify";

import {Modal} from "react-bootstrap";
import {
    addNewMembershipRegistrationSystemUserCall, getAllMembershipRegistrationSystemUserCall,
    updateMembershipRegistrationSystemUserCall
} from "../../../Api/ApiCalls";
import {localStoresCol, system_user_forms_col} from "../../../components/FilteringTable/Columns";
import {toastOptions} from "../../../config/Constants";
import {handleError} from '../../../utils/SharedFunctions';
import {ApiSearchField} from "../../../components/ApiSearchField";
import {useLocation} from "react-router-dom";


export default function MembershipRegistrationSystemUser() {
    const [staffs, setStaffs] = useState([])
    const [columns, setColumns] = useState([])
    const [totalPages, setTotalPages] = useState(undefined);
    const [currentPage, setCurrentPage] = useState(undefined);
    const [showModal, setShowModal] = useState(false)
    const [showEdit, setShowEdit] = useState(false)

    const [email, setEmail] = useState(undefined)
    const [name, setName] = useState(undefined)
    const [status, setStatus] = useState("active")
    const [type, setType] = useState("viewer")
    const [item, setItem] = useState(undefined)
    const [searchQuery, setSearchQuery] = useState("");

    const location = useLocation();

    useEffect(() => {
        setColumns(localStoresCol);
        getStaffs(1);
    }, []);

    useEffect(() => {
        getStaffs(1);
    }, [location.search]);

    useEffect(() => {
        getStaffs(1);
    }, [searchQuery]);


    useEffect(() => {
        setColumns(system_user_forms_col)
        getStaffs(1)
    }, [])


    const tableInstance = useTable({
        columns,
        data: staffs,
        initialState: {pageIndex: 0}
    }, useFilters, useGlobalFilter, useSortBy, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
    } = tableInstance


    const {pageIndex} = state


    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Membership registration system users</h4>
                    <a className="btn btn-secondary" onClick={() => openModalAdd()}>+ Add new user</a>

                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <ApiSearchField
                            searchQuery={searchQuery}
                            setSearchQuery={setSearchQuery}
                        />
                        <table {...getTableProps()} className="table dataTable display">
                            <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            <span className="ml-1">
                                                {column.isSorted ? (
                                                        column.isSortedDesc ?
                                                            <i className="fa fa-arrow-down ms-2 fs-14"
                                                               style={{opacity: '0.7'}}/>
                                                            :
                                                            <i className="fa fa-arrow-up ms-2 fs-14"
                                                               style={{opacity: '0.7'}}/>
                                                    )
                                                    :
                                                    (<i className="fa fa-sort ms-2 fs-14"
                                                        style={{opacity: '0.3'}}/>)}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>

                            {page.map((row) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return <td {...cell.getCellProps()}
                                                       className={"cursor-pointer"} onClick={() => {
                                                openModalEdit(row.original)
                                            }}> {cell.render('Cell')} </td>
                                        })}

                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                        {/* This is only for footer if u require */}
                        <div className="d-flex justify-content-between">
              <span>
                Page{" "}
                  <strong>
                  {currentPage} of {totalPages}
                </strong>
                  {""}
              </span>
                            <span className="table-index d-none">
                Go to page :{" "}
                                <input
                                    type="number"
                                    className="ml-2"
                                    defaultValue={pageIndex + 1}
                                    onChange={(e) => {
                                        const pageNumber = e.target.value
                                            ? Number(e.target.value)
                                            : 1;
                                        getStaffs(pageNumber);
                                    }}
                                />
              </span>
                        </div>
                        <div className="text-center mb-3">
                            <div className="filter-pagination  mt-3">
                                <button
                                    className=" previous-button"
                                    onClick={() => getStaffs(1)}
                                    disabled={currentPage === 1}
                                >
                                    {"<<"}
                                </button>

                                <button
                                    className="previous-button"
                                    onClick={() => getStaffs(currentPage - 1)}
                                    disabled={currentPage === 1}
                                >
                                    Previous
                                </button>
                                <button
                                    className="next-button"
                                    onClick={() => getStaffs(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                >
                                    Next
                                </button>
                                <button
                                    className=" next-button"
                                    onClick={() => getStaffs(totalPages)}
                                    disabled={currentPage === totalPages}
                                >
                                    {">>"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal className="modal fade" size={"xl"} show={showModal} onHide={setShowModal}>
                <div className="" role="document">
                    <div className="">
                        <form>
                            <div className="modal-header">
                                <h4 className="modal-title fs-20">Users</h4>
                                <button type="button" className="btn-close" onClick={() => setShowModal(false)}
                                        data-dismiss="modal"></button>
                            </div>
                            <div className="modal-body">
                                <i className="flaticon-cancel-12 close"></i>
                                <div className="add-contact-box">
                                    <div className="add-contact-content">
                                        <div className={"row"}>
                                            <div className="form-group mb-3 col-sm-10 col-md-6">
                                                <label className="text-black font-w500">Name</label>
                                                <div className="contact-name">
                                                    <input type="text" className="form-control"
                                                           name="name" required="required"
                                                           defaultValue={name}
                                                           onChange={(val) => setName(val.target.value)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group mb-3 col-sm-10 col-md-6">
                                                <label className="text-black font-w500">Email address</label>
                                                <div className="contact-name">
                                                    <input type="text" className="form-control"
                                                           name="email" required="required"
                                                           defaultValue={email}
                                                           onChange={(val) => setEmail(val.target.value)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group mb-3 col-sm-10 col-md-6">
                                                <label className="text-black font-w500">Status</label>
                                                <div className="contact-name">
                                                    <select className="form-control"
                                                            name="user_type" required="required"
                                                            defaultValue={status}
                                                            onChange={(val) => setStatus(val.target.value)}>
                                                        <option value={"active"}>Active</option>
                                                        <option value={"deleted"}>Deleted</option>
                                                        {/*<option value={"user"}>User</option>*/}

                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group mb-3 col-sm-10 col-md-6">
                                                <label className="text-black font-w500">Type</label>
                                                <div className="contact-name">
                                                    <select className="form-control"
                                                            name="user_type" required="required"
                                                            defaultValue={type}
                                                            onChange={(val) => setType(val.target.value)}>
                                                        <option value={"viewer"}>Viewer</option>
                                                        <option value={"advanced"}>Advanced</option>
                                                        <option value={"secondChance"}>Second Chance</option>

                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {!showEdit && <div className="modal-footer">
                                <a className="btn btn-secondary" onClick={() => createStaff()}>
                                    Add
                                </a>
                                <a className="btn btn-warning" onClick={() => setShowModal(false)}>
                                    Discard
                                </a>

                            </div>}

                            {showEdit && <div className="modal-footer">
                                <a className="btn btn-secondary" onClick={() => updateStaff()}>
                                    Save
                                </a>
                                <a className="btn btn-warning" onClick={() => setShowModal(false)}>
                                    Cancel
                                </a>
                            </div>}
                        </form>

                    </div>
                </div>
            </Modal>

        </>
    )

    function openModalEdit(item) {
        setStatus(item.status)
        setName(item.name)
        setEmail(item.email)
        setType(item.type)
        setItem(item)

        setShowModal(true)
        setShowEdit(true)
    }

    function openModalAdd() {
        setStatus('active')
        setName(undefined)
        setEmail(undefined)


        setShowModal(true)
        setShowEdit(false)
    }


    function createStaff() {
        const payload = { email: email, name: name, status: status , type: type };
        addNewMembershipRegistrationSystemUserCall(payload).then(staff => {
            toast.success('Staff created successfully', toastOptions);
            setStaffs([staff, ...staffs]);
            setShowModal(false);
        }).catch(error => {
            handleError(error)
        })
    }


    function updateStaff() {
        const payload = { email: email, name: name, status: status , type: type };
        updateMembershipRegistrationSystemUserCall(item.id, payload).then(staff => {
            toast.success('Staff updated successfully', toastOptions);
            setStaffs(staffs.map(d => {
                return d.id === staff.id ? staff : d;
            }));
            setShowModal(false);
        }).catch(error => {
            handleError(error)
        })
    }

    function getFilters() {
        const apiFilters = [];
        if (searchQuery?.length) {
            apiFilters.push(`nameWildcard=${encodeURIComponent(searchQuery)}`);
        }
        return apiFilters.join("&");
    }

    function getStaffs(page) {
        getAllMembershipRegistrationSystemUserCall(page, 10, getFilters()).then(result => {
            setStaffs(result?.items);
            setTotalPages(result.totalPages);
            setCurrentPage(result.page);
        }).catch(error => {
            handleError(error)
        })
    }
}
