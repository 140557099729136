import { useState } from "react";
import Entity from "./Entity";
import Section from "./Section";
import Divider from "./Divider";

const DropdownSection = ({ title, entities, onUpdate }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleDropDown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="mt-3">
        <div className="d-flex flex-column">
          <div
            className="w-100 d-flex justify-content-between align-items-center"
            style={{
              backgroundColor: "#EBEBEB",
              cursor: "pointer",
              transition: "all 1s ease-in-out",
              borderRadius: "10px",
              padding: "5px",
            }}
            onClick={handleDropDown}
          >
            <div
              className="h3 font-weight-bold col-3 text-primary"
            >
              {title}
            </div>
            {entities.slice(0, 3).map((entity, index) => (
              <Entity
                key={index}
                {...entity}
                editable={false}
                onUpdate={onUpdate}
              />
            ))}
          </div>
          {isOpen ? (
            <>
              <Section
                entities={entities.slice(3)}
                editable={false}
                onUpdate={onUpdate}
              />
              <Divider />
            </>
          ) : (
            <div className="w-100 pl-30" />
          )}
        </div>
      </div>
    </>
  );
};

export default DropdownSection;
