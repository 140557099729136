import React from "react";

const Button = ({
  text,
  className,
  variant,
  onClick,
  disabled,
  iconClassName,
  svg
}) => {
  const variants = {
    darkGray: {
      backgroundColor: disabled ? "#646464" : "#135846",
    },
    green: {
      backgroundColor: disabled ? "#646464" : "#860e05",
    },
    red: {
      backgroundColor: disabled ? "#646464" : "#FB9F44",
    },
    cyan: {
      backgroundColor: disabled? "#646464" : "#E23428",
    },
    primary: {
      backgroundColor: disabled? "#646464" : "#DF4EA1",
    },
    blue: {
      backgroundColor: disabled? "#646464" : "#0473ae",
    },
    yellow: {
      backgroundColor: disabled ? "#646464" : "#68E365",
    },
  };

  const selectedVariant = variants[variant] || variants.darkGray;

  const buttonStyle = {
    backgroundColor: selectedVariant.backgroundColor,
    color: "#fff",
    padding: "15px",
    borderRadius: "10px",
    cursor: disabled? "default" : "pointer",
    border: "none",
    outline: "none",
    fontSize: "13px",
  };

  const buttonClassName = `custom-button ${className || ""}`;

  return (
    <button
      style={buttonStyle}
      className={buttonClassName}
      onClick={onClick}
      disabled={disabled}
    >
      {iconClassName && <i className={iconClassName} style={{paddingRight:"10px"}}></i>}
      {svg && (
        svg
      )}
      {text}
    </button>
  );
};

export default Button;
