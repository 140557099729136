import React from "react";
import {hasAValue} from "../../../utils/SharedFunctions";
import {toast} from "react-toastify";
import {appToastMessages, toastOptions} from "../../../config/Constants";

export default function Step1({type, setType, setMode, mode, goNextStep}) {
    return (
        <>
            <section>
                <div className="row justify-content-center">
                    <div className="col-lg-6 mb-2">

                        <div className="form-group mb-3">
                            <label className="text-label">Notification Types*</label>
                            <select
                                required
                                defaultValue={type}
                                className="form-control form-control-md"
                                onChange={(name) => {
                                    setType((name).target.value)
                                }}
                            >
                                <option value={"url"}>{"URL"}</option>
                                <option value={"instore"}>{"Instore"}</option>
                                {/*<option value={"instore_specific_discount"}>{"In store (specific discount)"}</option>*/}
                                <option value={"cashback"}>{"Online"}</option>
                                {/*<option value={"online_specific_discount"}>{"Online (specific discount)"}</option>*/}
                                <option value={"jobs"}>{"Jobs"}</option>
                                {/*<option value={"jobs_specific_job_offer"}>{"Jobs (specific job offer)"}</option>*/}
                            </select>
                        </div>
                    </div>
                </div>
            </section>
            <div className="text-end toolbar toolbar-bottom p-2">
                <button className="btn btn-primary sw-btn-next"
                        onClick={() => goNext()}>Next
                </button>
            </div>
        </>
    );

    function goNext(){
        if(hasAValue(mode) && hasAValue(type)){
            goNextStep()
        }else {
            toast.error(appToastMessages.all_fields_are_required, toastOptions)
        }
    }
};
