import { capitalizeFirstLetter, removeHoursFromDateString } from "../helpers";
import check from "../../../../style/images/check.png";

const Entity = ({ label, data }) => {

  const formatData = (data) => {
    if (data && (data === "RENEWED" || data === "CANCELLED")) {
      return capitalizeFirstLetter(data.toLowerCase());
    } else if (
      data &&
      typeof data === "string" &&
      data.match(/^\d{1,2}-\d{1,2}-\d{4}\s\d{1,2}:\d{1,2}:\d{1,2}$/)
    ) {
      return removeHoursFromDateString(data);
    } else if (data && data === "direct_debit") {
        return "Direct Debit";
    }else if (data && data === "Knaek_NL") {
            return "Knaek NL";
    }else if (data && data === "Knaek_BE") {
        return "Knaek BE";
    } else if (typeof data === "object") return data.data;

    return data;
  };

  const renderLabel = () => {
    if (typeof data === "object") {
      if (data?.verified) {
        return (
          <label
            className="font-weight-bold h5"
            style={{ position: "relative" }}
          >
            {label}
            <img
              src={check}
              alt="check"
              width={20}
              height={20}
              style={{ marginLeft: "3px" }}
            />
          </label>
        );
      } else {
        return (
          <label className="font-weight-bold h5" style={{ width: "200px" }}>
            {label}
          </label>
        );
      }
    } else {
      return (
        <label className="font-weight-bold h5" style={{ width: "200px" }}>
          {label}
        </label>
      );
    }
  };

  return (
    <div className="d-flex flex-column col-3">
      {renderLabel()}
      <div
        style={{
          color: "darkgrey",
          fontSize: "12px",
          fontWeight: "500",
          marginBottom: "15px",
          marginTop: "-8px",
          width:"200px"
        }}
        className=""
      >
        {formatData(data)}
      </div>
    </div>
  );
};

export default Entity;
