import React, { useState } from "react";
import Entity from "./Entity";
import Section from "./Section";
import Divider from "./Divider";
import Button from "./Button";
import { stopUserPaymentReminder } from "../../../../Api/ApiCalls";
import {toast} from "react-toastify";

const Payments = ({ userId, payments, confirmEndKwm, subscriptionLength, setShowKWMPaymentStatus }) => {

  return (
    <>
    <div class="d-flex justify-content-between align-items-center mb-3">
        <div class="h3 font-weight-bold text-primary">
          Generated Payment Reminders
        </div>
    </div>
    <table className="static-table">
      <thead>
      <tr>
        <th>Payment request</th>
        <th>Type</th>
        <th>Price</th>
        <th>Payment Status</th>
        <th>KWM</th>
        <th>Payment Reminder</th>
        <th>Payment Link</th>
      </tr>
      </thead>
      <tbody>
      {
          payments &&
          payments.map((payment, index) => {
            return (
                <tr>
                  <td>Payment Sub {payments.length - index}</td>
                  <td>{payment?.type}</td>
                  <td>{parseFloat(payment?.price) || '-'}</td>
                  <td>{payment.status}</td>
                  <td>
                    {
                        payment.isReminderActive && (
                            <Button
                                text="End KWM"
                                variant="red"
                                disabled={payment.status === 'PAID'}
                                onClick={() => {
                                  confirmEndKwm(payment.paymentId)
                                }}
                                iconClassName="fas fa-solid fa-ban"
                            />
                        )
                    }
                  </td>
                  <td>{(payment?.isReminderActive && payment.status !== 'PAID' ? 'Active' : 'Not Active') + " (" + payment?.reminderLevel + " reminders)"}</td>
                  <td>
                    <Button
                        text="Go To Payment"
                        variant="blue"
                        disabled={payment.status === 'PAID'}
                        onClick={() => {
                          window.open(payment.paymentLink, '_blank').focus();
                        }}
                        iconClassName="fas fa-solid fa-globe"
                    />
                    <i className={'fas fa-solid fa-copy cursor-pointer'} style={{paddingLeft: "10px", color: '#0473ae'}}
                       onClick={() => {
                         navigator.clipboard.writeText(payment.paymentLink).then(
                             () => {
                               toast.success('Payment link copied to clipboard')
                             },
                             (err) => {
                               console.error('Failed to copy the payment link: ', err);
                             }
                         );
                       }}
                    ></i>
                  </td>

                </tr>
            );
          })
      }
      </tbody>
    </table>
    </>
  );
};

export default Payments;
