import {apiDelete, apiDeleteMethod, apiGet, apiPost, apiPostFile, apiPut, apiDownload, apiPatch} from "./Api";
import {appUrls} from "../config/Constants";
import {loginAgain} from "../utils/SharedFunctions";

const API_VERSION = "v4";

export async function loginCall(data) {
  return apiPost(appUrls.backend_api + `${API_VERSION}/auth/login/admin`, data);
}

export async function refreshTokenCall(data) {
  return apiPost(appUrls.backend_api + `${API_VERSION}/auth/refresh`, data);
}

export async function resetPasswordCall(data) {
  return apiPost(
    appUrls.backend_api + `${API_VERSION}/auth/password-reset`,
    data
  );
}

export async function updatePasswordCall(data) {
  return apiPost(
    appUrls.backend_api + `${API_VERSION}/auth/password-change`,
    data
  );
}

export async function updateUser(data, userId) {
  return apiPut(appUrls.backend_api + `${API_VERSION}/users/${userId}`, data);
}

export async function getUserPayments(userId) {
  return apiGet(appUrls.backend_api + `${API_VERSION}/users/${userId}/payments`);
}

export async function stopUserPaymentReminder(userId, paymentId, status) {
  return apiPatch(appUrls.backend_api + `${API_VERSION}/users/${userId}/payments/${paymentId}/stop-reminder`, {paymentStatus: status });
}

export async function fetchEducationsCall(page = 1, size = 10, filters) {
  let url =
    appUrls.backend_api + `${API_VERSION}/educations?page=${page}&size=${size}`;
  if (filters?.length > 0) {
    url += `&${filters}`;
  }
  return apiGet(url)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function fetchAllEducationsLevelsCall() {
  return apiGet(appUrls.backend_api + `${API_VERSION}/educations/levels`)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function createEducationInstitutionCall(data) {
  return apiPost(appUrls.backend_api + `${API_VERSION}/educations`, data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function updateEducationalInstitutionCall(id, data) {
  return apiPut(appUrls.backend_api + `${API_VERSION}/educations/${id}`, data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function deleteEducationalInstitutionCall(id) {
  return apiDelete(appUrls.backend_api + `${API_VERSION}/educations/${id}`)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function checkOneTimeAuthTokenCall(data) {
  return apiPost(appUrls.backend_api + "check_one_time_token", data);
}

export async function getInStoreActionsCall() {
  return apiGet(appUrls.backend_api + "valid_actions")
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function getOnlineActionsCall(data) {
  return apiGet(appUrls.backend_api + `sniptech/stores_locals?page=1&size=30&consumer=web&webWeightLT=101&nameWildcard=${data}`)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}


export async function getJobActionsCall() {
  return apiGet(appUrls.backend_api + "jopp/get_all_vacancies_test")
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}


export async function fetchTestUsersNotificationCall() {
  return apiGet(appUrls.backend_api + "v4/notifications/test")
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function createNotificationCall(data) {
  let url = appUrls.backend_api + 'v4/notifications';
  return apiPost(url, data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function testNotificationCall(data) {
  let url = appUrls.backend_api + 'v4/notifications/send-test';
  return apiPost(url, data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function startSendingNotificationCall(id) {
  let url = appUrls.backend_api + `v4/notifications/${id}/send`;
  return apiPost(url)
      .then((result) => {
        return result;
      })
      .catch((err) => {
        throw err;
      });
}

export async function getNotificationsReciepentsCall(data) {
  let url = appUrls.backend_api + 'v4/notifications/total-recipients';
  return apiPost(url, data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function fetchFilterProductionNotificationCall() {
  return apiGet(appUrls.backend_api + 'v4/notifications/filters')
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function fetchAllNotificationsCall(page = 1, size = 10, filters) {
  let url = appUrls.backend_api + `v4/notifications?page=${page}&size=${size}`;
  if (filters?.length > 0) {
    url += `&${filters}`;
  }
  return apiGet(url)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function updateExpirationDateCodeCall(data) {
  return apiPost(
    appUrls.backend_api + "admin/update_expiration_date_code",
    data
  )
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function addNewKnaekIdsCall(data) {
  return apiPost(appUrls.backend_api + "admin/add_new_knaek_ids", data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function addNewKnaekIdsCallWithCsv(data) {
  console.log(data)
  return apiPost(appUrls.backend_api + "admin/upload-knaek-codes", data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function addMandateNumber(data) {
  return apiPost(appUrls.backend_api + "admin/upload-mandate-numbers", data)
  .then((result) => {
    return result;
  })
  .catch((err) => {
    throw err;
  });
}

export async function fetchAllActionsCall() {
  return apiGet(appUrls.backend_api + "valid_actions")
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function updateRatingActionCall(data) {
  return apiPut(appUrls.backend_api + "update_rating_action", data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function updateRatingActionExpCall(data) {
  return apiPost(appUrls.backend_api + "update_rating_action_exp", data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function updateMembershipCancellationActiveReminderAdminCall(
  data
) {
  return apiPut(
    appUrls.backend_api +
      "update_membership_cancellation_active_reminder_admin",
    data
  )
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function fetchLastUpdateNotificationCall(id) {
  return apiGet(appUrls.backend_api + "get_last_update_of_notification/" + id)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function fetchAllCitiesCall() {
  return apiGet(appUrls.backend_api + "cities")
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function getAllIntrosEventsCall(data) {
  return apiGet(appUrls.backend_api + "intro_pages", data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function createIntroEventCall(data) {
  return apiPost(appUrls.backend_api + "intro_page", data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function updateIntroEventCall(data) {
  return apiPut(appUrls.backend_api + "intro_page", data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function unsubscribeMemberCall(data) {
  return apiPost(appUrls.backend_api + "unsubscribe_member_admin", data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function fetchAllMembershipCancellationsAdminCall() {
  return apiGet(appUrls.backend_api + "membership_cancellations_admin")
    .then((result) => {
      return result;
    })
    .catch((err) => {
      loginAgain(err);
      throw err;
    });
}

export async function getAllMembershipRegistrationSystemUserCall(page = 1, size = 10, filters) {
    let url = `v4/staffs?page=${page}&size=${size}`;
    if (filters?.length > 0) {
      url += `&${filters}`;
    }
    return apiGet(appUrls.backend_api + url)
      .then((result) => {
        return result;
      })
      .catch((err) => {
        throw err;
      });
}

export async function addNewMembershipRegistrationSystemUserCall(data) {
  return apiPost(
    appUrls.backend_api + 'v4/staffs',
    data
  )
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function updateMembershipRegistrationSystemUserCall(id, data) {
  return apiPut(
    appUrls.backend_api + `v4/staffs/${id}`,
    data
  )
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function getAllMembershipFormOrderCall() {
  return apiGet(appUrls.backend_api + "membership_form_order")
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function fetchKnaekIdCall(knaekId) {
  return apiGet(
    appUrls.backend_api + "activation_codes/valid_and_available?code=" + knaekId
  )
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function fetchAllLocationsCall() {
  return apiGet(appUrls.backend_api + "locationsmap")
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function fetchAllLocalStoresCall(page = 1, size = 10, filters) {
  let url = `sniptech/stores?page=${page}&size=${size}&consumer=admin`;
  if (filters?.length > 0) {
    url += `&${filters}`;
  }
  return apiGet(appUrls.backend_api + url)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function getAllOrdersCall(page, size, searchQuery = '') {
  return apiGet(appUrls.backend_api + `v4/memberships/orders?page=${page}&size=${size}&query=${searchQuery}`)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function uploadImageToS3Call(id, data) {
  return apiPostFile(appUrls.backend_api + `sniptech/${id}/upload`, data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      if (err.response?.data?.error.includes("authentication invalid")) {
        loginAgain(err);
      }
      throw err;
    });
}

export async function toggleDisabledStoreCall(data) {
  return apiPost(
    appUrls.backend_api + "sniptech/toggle-disabled-ordering",
    data
  )
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function updateStorePriorityCall(data) {
  return apiPost(appUrls.backend_api + "sniptech/update-ordering", data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function updateGeoLocationCall(data) {
  return apiPut(appUrls.backend_api + "update_geo_location", data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      loginAgain(err);
      throw err;
    });
}

export async function searchUserCall(data) {
  let url = appUrls.backend_api + "v4/users/search";
  return apiPost(url, data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log("error is here");
      console.log(err);
      throw err;
    });
}
export async function GetMembersCall(page,size,searchQuery) {
  let url = `${appUrls.backend_api}v4/members?page=${page}&size=${size}&query=${searchQuery}`;
  return apiGet(url)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log("error is here");
      console.log(err);
      throw err;
    });
}

export async function searchCompany(page,size,searchQuery) {
  let url = `${appUrls.backend_api}v4/companies?page=${page}&size=${size}&query=${searchQuery}`;
  return apiGet(url)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}
export async function getCompanyByIdCall(companyId) {
  let url = `${appUrls.backend_api}v4/companies/${companyId}`;
  return apiGet(url)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}
export async function deleteCompanyByIdCall(companyId) {
  let url = `${appUrls.backend_api}v4/companies/${companyId}`;
  return apiDelete(url)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}
export async function getCategories() {
  let url = `${appUrls.backend_api}v4/categories/admin`;
  return apiGet(url)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}
export async function getSubscriptionTypes() {
  const url = `${appUrls.backend_api}v4/companies/subscription-types`;
  return apiGet(url)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}
export async function createCompanyCall(data) {
  let url = `${appUrls.backend_api}v4/companies`;
  return apiPost(url,data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}
export async function updateCompanyCall(data,companyId) {
  let url = `${appUrls.backend_api}v4/companies/${companyId}`;
  return apiPut(url,data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function createCompanybulkFavouritCall(data, companyId) {
  let url = `${appUrls.backend_api}v4/companies/${companyId}/favourites/bulk`;
  return apiPost(url,data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}
export async function createLocationForCompany(data) {
  let url = `${appUrls.backend_api}v4/locations`;
  return apiPost(url,data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}
export async function getLocationsByCompanyId(page, size, companyId, debouncedSearchQuery) {
  let url = `${appUrls.backend_api}v4/locations?page=${page}&size=${size}&companyId=${companyId}&query=${debouncedSearchQuery}`
  return apiGet(url)
      .then((result) => {
        return result;
      })
      .catch((err) => {
        throw err;
      });
}

export async function getDiscountsByCompanyId(page,size,companyId,searchQuery) {
  let url = `${appUrls.backend_api}v4/companies/${companyId}/discounts?page=${page}&size=${size}&query=${searchQuery}`
  return apiGet(url)
  .then((result) => {
    return result;
  })
  .catch((err) => {
    throw err;
  });
}
export async function getDiscountsByGroupId(page,size,companyId,groupId,searchQuery) {
  let url = `${appUrls.backend_api}v4/companies/${companyId}/discount-groups/${groupId}/discounts?page=${page}&size=${size}&query=${searchQuery}`
  return apiGet(url)
  .then((result) => {
    return result;
  })
  .catch((err) => {
    throw err;
  });
}
export async function getDiscountDetailById(companyId, discountId) {
  let url = `${appUrls.backend_api}v4/companies/${companyId}/discounts/${discountId}`
  return apiGet(url)
  .then((result) => {
    return result;
  })
  .catch((err) => {
    throw err;
  });
}
export async function deleteDiscountById(companyId, actionId) {
  let url = `${appUrls.backend_api}v4/companies/${companyId}/discounts/${actionId}`
  return apiDelete(url)
  .then((result) => {
    return result;
  })
  .catch((err) => {
    throw err;
  });
}
export async function deleteCompanyLocation(id) {
  let url = `${appUrls.backend_api}v4/locations/${id}`
  return apiDelete(url)
      .then((result) => {
        return result;
      })
      .catch((err) => {
        throw err;
      });
}
export async function updateLocationCall(data,locationId) {
  let url = `${appUrls.backend_api}v4/locations/${locationId}`
  return apiPut(url,data)
  .then((result) => {
    return result;
  })
  .catch((err) => {
    throw err;
  });
}


export async function cancelMembershipForUser(data) {
  return apiPost(appUrls.backend_api + "unsubscribe_member_admin", data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function deleteUser(userId) {
  return apiDelete(appUrls.backend_api + `v4/users/${userId}/admin`)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function whitelistUser(userId) {
  return apiPut(appUrls.backend_api + `v4/users/${userId}/whitelist`)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function updateMembershipCancellation(data) {
  return apiPut(
    appUrls.backend_api +
      "update_membership_cancellation_active_reminder_admin",
    data
  )
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function fixAmgAccountCall(data) {
  return apiPost(appUrls.backend_api + "fix_amg_account", data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function fetchAllCountryCodesCall() {
  return apiGet(appUrls.backend_api + "country_codes/web")
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function deleteCountryCodeCall(id) {
  return apiDeleteMethod(appUrls.backend_api + `country_codes/${id}`)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function addCountryCodeCall(data) {
  return apiPost(appUrls.backend_api + "country_codes", data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function getUserDetailsById(userId) {
  return apiGet(appUrls.backend_api + `${API_VERSION}/users/${userId}`)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}
export async function getMandateNumberImage(mandateNumber) {
  return apiGet(appUrls.backend_api + `${API_VERSION}/mandates/${mandateNumber}/presigned-url`)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function getChannels() {
  return apiGet(appUrls.backend_api + `${API_VERSION}/channels`)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function getCountries() {
  return apiGet(appUrls.backend_api + `${API_VERSION}/countries`)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function getCities() {
  return apiGet(appUrls.backend_api + `${API_VERSION}/cities`)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function getEducationInstitutions() {
  return apiGet(appUrls.backend_api + `${API_VERSION}/educations/institutions`)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function getAllAdminUsers(data, filters) {
  let url =
    appUrls.backend_api + `admin/users?page=${data.page}&size=${data.size}`;
  if (filters?.length > 0) {
    url += `&${filters}`;
  }
  return apiGet(url)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function updateAdminUser(data) {
  return apiPut(appUrls.backend_api + `admin/users`, data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function deleteAdminUserCall(data) {
  return apiPost(appUrls.backend_api + `admin/users`, data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function addDiscountForCompany(data, companyId) {
  return apiPost(appUrls.backend_api + `${API_VERSION}/companies/${companyId}/discounts`, data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}
export async function updateDiscountCall(data, companyId, discountId) {
  return apiPut(appUrls.backend_api + `${API_VERSION}/companies/${companyId}/discounts/${discountId}`, data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function createUserAdmin(data) {
  return apiPost(
    appUrls.backend_api + `${API_VERSION}/auth/register/admin`,
    data
  )
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function addNewVouchersCall(data, actionId) {
    return apiPost(appUrls.backend_api + "v4/actions/"+actionId+"/vouchers", data)
      .then((result) => {
    return result;
      })
      .catch((err) => {
        throw err;
      });
}

export async function renewSubscriptionsCall(data) {
  return apiPost(appUrls.backend_api + `${API_VERSION}/subscriptions/renew`, data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}


export async function addNewVouchersCallWithCsv(data, actionId) {
  return apiPost(appUrls.backend_api + "v4/actions/"+actionId+"/vouchers/upload", data)
      .then((result) => {
        return result;
      })
      .catch((err) => {
        throw err;
      });
}

export async function sendSubscriptionsToIncasioCall(data) {
  return apiPost(appUrls.backend_api + `${API_VERSION}/subscriptions/pay`, data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}


export async function getVouchersByActionId(page,size,actionId) {
  let url = `${appUrls.backend_api}v4/actions/${actionId}/vouchers?page=${page}&size=${size}`
  return apiGet(url)
  .then((result) => {
    return result;
  })
  .catch((err) => {
    throw err;
  });
}

export async function sendEmailToSubscriptionsCall(data) {
  return apiPost(appUrls.backend_api + `${API_VERSION}/subscriptions/notify`, data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function deleteActionvouchersCall(actionId, data) {
  return apiPost(appUrls.backend_api + `${API_VERSION}/actions/${actionId}/vouchers/delete`, data)
}

export async function deleteAllActionvouchersCall(actionId) {
  return apiDelete(appUrls.backend_api + `${API_VERSION}/actions/${actionId}/vouchers/delete/all`)
}

export async function getGroupsByCompanyId(page,size,companyId,searchQuery) {
  let url = `${appUrls.backend_api}v4/companies/${companyId}/discount-groups?page=${page}&size=${size}&query=${searchQuery}`
  return apiGet(url)
  .then((result) => {
    return result;
  })
  .catch((err) => {
    throw err;
  });
}
export async function getGroupDetailById(companyId, groupId) {
  let url = `${appUrls.backend_api}v4/companies/${companyId}/discount-groups/${groupId}`
  return apiGet(url)
  .then((result) => {
    return result;
  })
  .catch((err) => {
    throw err;
  });
}
export async function deleteGroupById(companyId, groupId) {
  let url = `${appUrls.backend_api}v4/companies/${companyId}/discount-groups/${groupId}`
  return apiDelete(url)
  .then((result) => {
    return result;
  })
  .catch((err) => {
    throw err;
  });
}

export async function addGroupForCompany(data, companyId) {
  return apiPost(appUrls.backend_api + `${API_VERSION}/companies/${companyId}/discount-groups`, data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}
export async function updateGroupCall(data, companyId, groupId) {
  return apiPut(appUrls.backend_api + `${API_VERSION}/companies/${companyId}/discount-groups/${groupId}`, data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}
export async function downloadStatistics(companyId) {
  return apiDownload(appUrls.backend_api + `${API_VERSION}/companies/${companyId}/download-statistics`)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}
export async function downloadGroupStatisticsCall(companyId, groupId) {
  return apiDownload(appUrls.backend_api + `${API_VERSION}/companies/${companyId}/download-group-statistics/${groupId}`)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}
