import React, {Fragment, useEffect, useState} from "react";
import {Stepper, Step} from 'react-form-stepper';
import Step1 from "../../../components/Notifications/NewNotificationWizard/Step1";
import Step2 from "../../../components/Notifications/NewNotificationWizard/Step2";
import Step4 from "../../../components/Notifications/NewNotificationWizard/Step4";
import {handleError, hasAValue} from "../../../utils/SharedFunctions";
import {appToastMessages, toastOptions} from "../../../config/Constants";
import {toast} from "react-toastify";
import {createNotificationCall} from "../../../Api/ApiCalls";
import swal from "sweetalert";
import Step3 from "../../../components/Notifications/NewNotificationWizard/Step3";
import Swal from "sweetalert2";
import {TargetGroup} from "./constants";
import {fetchFilterProductionNotificationCall, testNotificationCall} from "../../../Api/ApiCalls";


export default function NewNotification() {
    const [goSteps, setGoSteps] = useState(1);
    const [mode, setMode] = useState("production");
    const [type, setType] = useState("url");
    const [titleEn, setTitleEn] = useState(undefined);
    const [bodyEn, setBodyEn] = useState(undefined);
    const [titleNl, setTitleNl] = useState(undefined);
    const [bodyNl, setBodyNl] = useState(undefined);
    const [notificationItem, setNotificationItem] = useState(undefined);
    const [userListForTestMode, setUserListForTestMode] = useState([]);
    const [selectedFiltersForProductionMode, setSelectedFiltersForProductionMode] = useState(undefined)
    const [filterLimited, setFilterLimited] = useState(undefined)
    const [filterGender, setFilterGender] = useState(undefined)
    const [filterSession, setFilterSession] = useState(undefined)
    const [filterGroup, setFilterGroup] = useState(undefined)
    const [filterAccountOwner, setFilterAccountOwner] = useState(undefined)
    const [selectedFiltersCities, setSelectedFiltersCities] = useState([])
    const [orientationMode, setOrientationMode] = useState(false)
    const [enableAccountOwner, setEnableAccountOwner] = useState(true)
    const [enableMode, setEnableMode] = useState(true)
    const [country, setCountry] = useState(1)
    const [finalRecipients, setFinalRecipients] = useState(0)
    const [productionFilters, setProductionFilters] = useState([])

    
    useEffect(() => {
        setSelectedFiltersForProductionMode({
            country: country,
            cities: selectedFiltersCities,
            limitation: filterLimited,
            gender: filterGender,
            session: filterSession,
            group: filterGroup,
            accountOwner: filterAccountOwner
        })
    }, [country, filterSession, filterLimited, filterGender, selectedFiltersCities, filterGroup, filterAccountOwner])

    useEffect(() => {
        fetchFilterProductionNotification();
    }, [])
    function fetchFilterProductionNotification() {
        fetchFilterProductionNotificationCall().then(results => {
            setProductionFilters(results)
        }).catch(error => {
            toast.error(error.response.data.error, toastOptions);
        })
    }
    return (
        <Fragment>
            <div className="row justify-content-center">
                <div className="col-xl-12 col-xxl-12">
                    <div className="card">
                        <div className="card-header">
                            {goSteps === 1 && <h4 className="card-title">Notification info
                            </h4>}
                            {goSteps === 2 && <h4 className="card-title">Notification
                            </h4>}
                            {goSteps === 3 && <h4 className="card-title">Target
                            </h4>}
                            {goSteps === 4 && <h4 className="card-title">Control & send
                            </h4>}
                        </div>
                        <div className="card-body">

                            <div className="form-wizard ">
                                <Stepper className="nav-wizard" activeStep={goSteps - 1} label={false}>
                                    <Step className="nav-link" onClick={() => setGoSteps(1)}/>
                                    <Step className="nav-link" onClick={() => setGoSteps(2)}/>
                                    <Step className="nav-link" onClick={() => setGoSteps(3)}/>
                                    <Step className="nav-link" onClick={() => setGoSteps(4)}/>
                                </Stepper>


                                {goSteps === 1 &&
                                    <Step1
                                        type={type} setType={(item) => {
                                        setNotificationItem(undefined)
                                        setType(item)
                                    }}
                                        goNextStep={() => goNextStep()}
                                        mode={mode} setMode={(item) => setMode(item)}
                                    />
                                }


                                {goSteps === 2 &&
                                    <Step2 type={type} mode={mode}
                                           titleEn={titleEn} setTitleEn={(item) => setTitleEn(item)}
                                           bodyEn={bodyEn} setBodyEn={(item) => setBodyEn(item)}
                                           titleNl={titleNl} setTitleNl={(item) => setTitleNl(item)}
                                           bodyNl={bodyNl} setBodyNl={(item) => setBodyNl(item)}
                                           notificationItem={notificationItem}
                                           setNotificationItem={(item) => setNotificationItem(item)}
                                           testNotification={(item) => testNotification(item)}
                                           goNextStep={() => goNextStep()}
                                           goBack={() => goBack()}
                                    />}


                                {goSteps === 3 &&
                                    <Step3 userListForTestMode={userListForTestMode}
                                           setUserListForTestMode={(item) => setUserListForTestMode(item)}
                                           goNextStep={() => goNextStep()}
                                           goBack={() => goBack()}
                                           mode={mode}
                                           country={country}
                                           filterLimited={filterLimited}
                                           filterGender={filterGender}
                                           filterSession={filterSession}
                                           filterGroup={filterGroup}
                                           productionFilters={productionFilters}
                                           setFilterGroup={(value) => setFilterGroup(value)}
                                           filterAccountOwner={filterAccountOwner}
                                           setFilterAccountOwner={(value) => setFilterAccountOwner(value)}
                                           orientationMode={orientationMode}
                                           setOrientationMode={(value) => setOrientationMode(value)}
                                           enableAccountOwner={enableAccountOwner}
                                           setEnableAccountOwner={(value) => setEnableAccountOwner(value)}
                                           enableMode={enableMode}
                                           setEnableMode={(value) => setEnableMode(value)}
                                           selectedFiltersCities={selectedFiltersCities}
                                           setSelectedFiltersCities={(value) => setSelectedFiltersCities(value)}
                                           setCountry={(value) => setCountry(value)}
                                           setFilterLimited={(value) => setFilterLimited(value)}
                                           setFilterGender={(value) => setFilterGender(value)}
                                           setFilterSession={(value) => setFilterSession(value)}
                                    />

                                }

                                {goSteps === 4 &&
                                    <Step4
                                        mode={mode} type={type}
                                        titleEn={titleEn}
                                        bodyEn={bodyEn}
                                        titleNl={titleNl}
                                        bodyNl={bodyNl}
                                        selectedFiltersForProductionMode={selectedFiltersForProductionMode}
                                        notificationItem={notificationItem}
                                        userListForTestMode={userListForTestMode}
                                        finalRecipients={finalRecipients}
                                        goNextStep={() => goNextStep()}
                                        goBack={() => goBack()}
                                        saveNotification={() => saveNotification()}
                                    />

                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );

    function goNextStep() {
        setGoSteps(goSteps + 1)
    }

    function goBack() {
        setGoSteps(goSteps - 1)
    }

    function saveNotification(status = 'Draft') {
        const data = {
            type: type,
            notificationData: {
                titleEn: titleEn,
                bodyEn: bodyEn,
                titleNl: titleNl,
                bodyNl: bodyNl,
                status: status,
            },
            notificationItem: {
                url: notificationItem.url,
                actionId: notificationItem.action?.actionId,
                actionIconId: notificationItem.action?.iconId,
                storeId: notificationItem.store?.id,
                jobId: notificationItem.job?.id,
                logo: notificationItem.store?.logo ?? notificationItem.job?.logo,
            },
            usersFilter: {
                countryId: parseInt(country),
                cityIds: selectedFiltersCities.map(city => city.value),
                limitation: filterLimited === '' ? undefined : filterLimited,
                gender: filterGender === '' ? undefined : filterGender,
                session: filterSession === '' ? undefined : filterSession,
                targetGroup: filterGroup === '' ? undefined : filterGroup,
                accountOwner: filterAccountOwner === '' ? undefined : filterAccountOwner,
            },
        }
        swal({
            title: "Are you sure?",
            text:
                "You want to create this message!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((response) => {
            if (response) {
                Swal.fire({
                    title: "Loading...",
                    allowOutsideClick: true,
                    allowEscapeKey: false,
                    showConfirmButton: false,
                    icon: "info",
                    onBeforeOpen: () => {
                        Swal.showLoading()
                    },
                });
                createNotificationCall(data).then(notification => {
                    Swal.close();
                    if(notification.id){
                        setFinalRecipients(notification.totalReceivers)
                        toast.success(`The notification with id "${notification.id}" has been created successfully`, toastOptions)
                    }
                    else{
                        toast.error(`No Receipents found for the selected criteria !`, toastOptions)   
                        setFinalRecipients(0)
                    }

                }).catch(error => {
                    Swal.close();
                    handleError(error)
                })
            } else {
                toast.warn(appToastMessages.notification_has_not_been_sent, toastOptions);
            }
        })
    }
    async function testNotification() {
        const data = {
            type: type,
            notificationData: {
                titleEn: titleEn,
                bodyEn: bodyEn,
                titleNl: titleNl,
                bodyNl: bodyNl,
            },
            notificationItem: {
                url: notificationItem.url,
                actionId: notificationItem.action?.actionId,
                actionIconId: notificationItem.action?.iconId,
                storeId: notificationItem.store?.id,
                jobId: notificationItem.job?.id,
                logo: notificationItem.store?.logo ?? notificationItem.job?.logo,
            },
        }
        const confirmation =  await swal({
            title: "Are you sure?",
            text: "You want to test the notification !",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        });
        
        if (confirmation)
        {
            Swal.fire({
                title: "Loading...",
                allowOutsideClick: true,
                allowEscapeKey: false,
                showConfirmButton: false,
                icon: "info",
                onBeforeOpen: () => {
                    Swal.showLoading()
                },
            });
            
            testNotificationCall(data).then(notification => {
                Swal.close();
                toast.success("Test notification sent !", toastOptions);

            }).catch(error => {
                Swal.close();
                toast.warn("Test notification not sent !", toastOptions);
            })

        }
    }
};
