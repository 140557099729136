import React, {useEffect, useState} from "react";
import {
    getInStoreActionsCall,
    getJobActionsCall,
    getOnlineActionsCall
} from "../../../Api/ApiCalls";
import {toast} from "react-toastify";
import {
    appToastMessages,
    appUrls,
    toastOptions,
} from "../../../config/Constants";
import PerfectScrollbar from "react-perfect-scrollbar";
import {hasAValue} from "../../../utils/SharedFunctions";

export default function Step2({
                                  type,
                                  notificationItem,
                                  setNotificationItem,
                                  bodyEn,
                                  setBodyEn,
                                  titleEn,
                                  setTitleEn,
                                  goBack,
                                  goNextStep,
                                  titleNl,
                                  setTitleNl,
                                  bodyNl,
                                  setBodyNl,
                                  testNotification
                              }) {
    const [actions, setActions] = useState([]);
    const [actionsFiltered, setActionsFiltered] = useState([]);

    useEffect(() => {
        if (type.toString().includes("instore")) {
            getInStoreActions();
        } else if (type.toString().includes("cashback")) {
            getOnlineActions();
        } else if (type.toString().includes("jobs")) {
            getJobsActions();
        }
    }, []);

    return (
        <>
            <section>
                <div className="row justify-content-center">

                    <div className="mb-2">
                        <div className="d-flex" style={{
                            gap: "100px",
                        }}>

                            <div className="w-50">
                                <div className="p-1" style={{
                                    textAlign: "center",
                                    fontSize: "20px"
                                }}>English
                                </div>
                                <div className="form-group mb-3">
                                    <label className="text-label">Title (EN)*</label>
                                    <input
                                        type="text"
                                        name="title"
                                        className="form-control mb-3"
                                        defaultValue={titleEn}
                                        onChange={(val) => {
                                            setTitleEn(val.target.value);
                                        }}
                                        required
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <div className={"d-flex justify-content-between"}>
                                        <label className="text-label">Body (EN)*</label>
                                        <label className="text-label">
                                            200/{hasAValue(bodyEn) ? bodyEn.toString().length : 0}
                                        </label>
                                    </div>

                                    <textarea
                                        name="body"
                                        rows={4}
                                        maxLength={200}
                                        className="form-control mb-3"
                                        defaultValue={bodyEn}
                                        onChange={(val) => {
                                            setBodyEn(val.target.value);
                                        }}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="w-50">
                                <div className="p-1" style={{
                                    textAlign: "center",
                                    fontSize: "20px"
                                }}>Dutch
                                </div>

                                <div className="form-group mb-3">
                                    <label className="text-label">Title (NL)*</label>
                                    <input
                                        type="text"
                                        name="title"
                                        className="form-control mb-3"
                                        defaultValue={titleNl}
                                        onChange={(val) => {
                                            setTitleNl(val.target.value);
                                        }}
                                        required
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <div className={"d-flex justify-content-between"}>
                                        <label className="text-label">Body (NL)*</label>
                                        <label className="text-label">
                                            200/{hasAValue(bodyEn) ? bodyEn.toString().length : 0}
                                        </label>
                                    </div>

                                    <textarea
                                        name="body"
                                        rows={4}
                                        maxLength={200}
                                        className="form-control mb-3"
                                        defaultValue={bodyNl}
                                        onChange={(val) => {
                                            setBodyNl(val.target.value);
                                        }}
                                        required
                                    />
                                </div>
                            </div>

                        </div>

                        {/*url type*/}
                        {type.toString().includes("url") && (
                            <div className="form-group mb-3">
                                <label className="text-label">URL*</label>
                                <input
                                    type="text"
                                    name="body"
                                    className="form-control mb-3"
                                    defaultValue={notificationItem?.url}
                                    onChange={(val) => {
                                        setNotificationItem({url: val.target.value});
                                    }}
                                    required
                                />
                            </div>
                        )}

                        {/*instore type*/}
                        {type.toString().includes("instore") && (
                            <div className="form-group mb-3">
                                <label className="text-label">Instore Discount*</label>
                                <div className="form-group mb-3">
                                    <div className="card border-0 pb-0">
                                        <div className="card-body border-top shadow rounded">
                                            {notificationItem && (
                                                <div className="widget-media mb-2">
                                                    <ul className="timeline">
                                                        <li className={"cursor-pointer"}>
                                                            <div className={"timeline-panel"}>
                                                                <div className="media me-2">
                                                                    <img
                                                                        alt=""
                                                                        width="50"
                                                                        src={
                                                                            appUrls.backend_api_image +
                                                                            notificationItem.action?.iconId
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="media-body">
                                                                    <h5 className="mb-1">
                                                                        {notificationItem.action?.companyName}
                                                                    </h5>
                                                                    <small className="d-block">
                                                                        {notificationItem.action?.localization.nl.subtitle}
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            )}

                                            <input
                                                type="text"
                                                name="body"
                                                className="form-control mb-2"
                                                onChange={(val) => {
                                                    handleInputSearchItemInStore(val.target.value);
                                                }}
                                            />
                                            <PerfectScrollbar
                                                style={{height: "370px"}}
                                                id="DZ_W_Todo2"
                                                className="widget-media overflow-auto height370 ps ps--active-y"
                                            >
                                                <ul className="timeline">
                                                    {actionsFiltered.map((item, i) => {
                                                        let selected = false;
                                                        if (item === notificationItem) {
                                                            selected = true;
                                                        }
                                                        return (
                                                            <li
                                                                key={i}
                                                                className={"cursor-pointer"}
                                                                onClick={() => setNotificationItem({action: item})}
                                                            >
                                                                <div className={"timeline-panel"}>
                                                                    <div className="media me-2">
                                                                        <img
                                                                            alt=""
                                                                            width="50"
                                                                            src={
                                                                                appUrls.backend_api_image + item.iconId
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="media-body">
                                                                        <h5 className="mb-1">{item.companyName}</h5>
                                                                        <small className="d-block">
                                                                            {item.localization.nl.subtitle}
                                                                        </small>
                                                                    </div>
                                                                    {selected && (
                                                                        <h3>
                                                                            <i className="fas fa-check-circle"></i>
                                                                        </h3>
                                                                    )}
                                                                </div>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </PerfectScrollbar>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/*online type*/}

                        {type.toString().includes("cashback") && (
                            <div className="form-group mb-3">
                                <label className="text-label">Online Discount*</label>
                                <div className="form-group mb-3">
                                    <div className="card border-0 pb-0">
                                        <div className="card-body border-top shadow rounded">
                                            {notificationItem && (
                                                <div className="widget-media mb-2">
                                                    <ul className="timeline">
                                                        <li className={"cursor-pointer"}>
                                                            <div className={"timeline-panel"}>
                                                                <div className="media me-2">
                                                                    <img
                                                                        alt=""
                                                                        width="50"
                                                                        src={notificationItem.store.logo}
                                                                    />
                                                                </div>
                                                                <div className="media-body">
                                                                    <h5 className="mb-1">
                                                                        {notificationItem.store.title}
                                                                    </h5>
                                                                    <small className="d-block">
                                                                        {notificationItem.store.en.shortDes}
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            )}
                                            <input
                                                type="text"
                                                name="body"
                                                className="form-control mb-2"
                                                onChange={(val) => {
                                                    handleInputSearchItemOnline(val.target.value);
                                                }}
                                            />
                                            <PerfectScrollbar
                                                style={{height: "370px"}}
                                                id="DZ_W_Todo2"
                                                className="widget-media overflow-auto height370 ps ps--active-y"
                                            >
                                                <ul className="timeline">
                                                    {actionsFiltered.map((item, i) => {
                                                        let selected = false;
                                                        if (item === notificationItem) {
                                                            selected = true;
                                                        }
                                                        return (
                                                            <li
                                                                key={i}
                                                                className={"cursor-pointer"}
                                                                onClick={() => setNotificationItem({store: item})}
                                                            >
                                                                <div className={"timeline-panel"}>
                                                                    <div className="media me-2">
                                                                        <img
                                                                            alt=""
                                                                            width="50"
                                                                            src={item.logo}
                                                                        />
                                                                    </div>
                                                                    <div className="media-body">
                                                                        <h5 className="mb-1">
                                                                            {item.title}
                                                                        </h5>
                                                                        <small className="d-block">
                                                                            {item.en.shortDes}
                                                                        </small>
                                                                    </div>
                                                                    {selected && (
                                                                        <h3>
                                                                            <i className="fas fa-check-circle"></i>
                                                                        </h3>
                                                                    )}
                                                                </div>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </PerfectScrollbar>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Jobs type */}
                        {type.toString().includes("jobs") && (
                            <div className="form-group mb-3">
                                <label className="text-label">Jobs Discount*</label>
                                <div className="form-group mb-3">
                                    <div className="card border-0 pb-0">
                                        <div className="card-body border-top shadow rounded">
                                            {notificationItem && (
                                                <div className="widget-media mb-2">
                                                    <ul className="timeline">
                                                        <li className={"cursor-pointer"}>
                                                            <div className={"timeline-panel"}>
                                                                <div className="media me-2">
                                                                    <img
                                                                        alt=""
                                                                        width="50"
                                                                        src={notificationItem.job.logo}
                                                                    />
                                                                </div>
                                                                <div className="media-body">
                                                                    <h5 className="mb-1">
                                                                        {notificationItem.job.jobTitle}
                                                                    </h5>
                                                                    <small className="d-block">
                                                                        {notificationItem.job.featured}
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            )}
                                            <input
                                                type="text"
                                                name="body"
                                                className="form-control mb-2"
                                                onChange={(val) => {
                                                    handleInputSearchItemOnline(val.target.value);
                                                }}
                                            />
                                            <PerfectScrollbar
                                                style={{height: "370px"}}
                                                id="DZ_W_Todo2"
                                                className="widget-media overflow-auto height370 ps ps--active-y"
                                            >
                                                <ul className="timeline">
                                                    {actionsFiltered.map((item, i) => {
                                                        let selected = false;
                                                        if (item === notificationItem) {
                                                            selected = true;
                                                        }
                                                        return (
                                                            <li
                                                                key={i}
                                                                className={"cursor-pointer"}
                                                                onClick={() => setNotificationItem({job: item})}
                                                            >
                                                                <div className={"timeline-panel"}>
                                                                    <div className="media me-2">
                                                                        <img
                                                                            alt=""
                                                                            width="50"
                                                                            src={item.logo}
                                                                        />
                                                                    </div>
                                                                    <div className="media-body">
                                                                        <h5 className="mb-1">
                                                                            {item.jobTitle}
                                                                        </h5>
                                                                        <small className="d-block">
                                                                            {item.featured}
                                                                        </small>
                                                                    </div>
                                                                    {selected && (
                                                                        <h3>
                                                                            <i className="fas fa-check-circle"></i>
                                                                        </h3>
                                                                    )}
                                                                </div>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </PerfectScrollbar>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
            <div className="d-flex align-items-center justify-content-end toolbar toolbar-bottom p-2">
                <button
                    className="btn btn-primary sw-btn-prev me-auto"
                    onClick={() => testNotification()}
                >
                    Test Notification
                </button>
                <button
                    className="btn btn-secondary sw-btn-prev me-1"
                    onClick={() => goBack()}
                >
                    Prev
                </button>
                <button
                    className="btn btn-primary sw-btn-next ms-1"
                    onClick={() => {
                        goNext();
                    }}
                >
                    Next
                </button>
            </div>
        </>
    );


    function goNext() {
        if (hasAValue(bodyEn) && hasAValue(titleEn)) {
                if (hasAValue(notificationItem)) {
                    goNextStep();
                } else {
                    return toast.error(
                        appToastMessages.all_fields_are_required,
                        toastOptions
                    );
                }
            goNextStep();
        } else {
            toast.error(appToastMessages.all_fields_are_required, toastOptions);
        }
    }

    function getInStoreActions() {
        getInStoreActionsCall()
            .then((results) => {
                setActions(results);
                setActionsFiltered(results);
            })
            .catch((error) => {
                toast.error(error.response.data.error, toastOptions);
            });
    }

    function getOnlineActions(data = '') {
        getOnlineActionsCall(data)
            .then((results) => {
                setActions(results.items);
                setActionsFiltered(results.items);
            })
            .catch((error) => {
                toast.error(error.response.data.error, toastOptions);
            });
    }

    function getJobsActions() {
        getJobActionsCall()
            .then((results) => {
                setActions(results);
                setActionsFiltered(results);
            })
            .catch((error) => {
                toast.error(error.response.data.error, toastOptions);
            });
    }

    function handleInputSearchItemInStore(searchValue) {
        setActionsFiltered(actions);
        let filteredData = actions.filter((element) => {
            return (
                (element.companyName
                        .toLowerCase()
                        .includes(searchValue.toLowerCase()) ||
                    element.localization.nl.subtitle
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())) &&
                (element.companyName
                        .toLowerCase()
                        .includes(searchValue.toLowerCase()) ||
                    element.localization.nl.subtitle
                        .toLowerCase()
                        .includes(searchValue.toLowerCase()))
            );
        });
        setActionsFiltered(filteredData);
    }

    function handleInputSearchItemOnline(searchValue) {
        getOnlineActions(searchValue)
    }
}
