import { format } from "date-fns";
import { ColumnFilter } from "./ColumnFilter";
import {hasAValue, cellStyle, humanDate, transformDate, getDiscountStatus} from "../../utils/SharedFunctions";
import React from "react";

export const COLUMNS = [
  {
    Header: "Id",
    Footer: "Id",
    accessor: "id",
    Filter: ColumnFilter,
    //disableFilters: true,
  },
  {
    Header: "First Name",
    Footer: "First Name",
    accessor: "first_name",
    Filter: ColumnFilter,
  },
  {
    Header: "Last Name",
    Footer: "Last Name",
    accessor: "last_name",
    Filter: ColumnFilter,
  },
  {
    Header: "Email Id",
    Footer: "Email Id",
    accessor: "email",
    Filter: ColumnFilter,
  },
  {
    Header: "Date of  Birth",
    Footer: "Date of  Birth",
    accessor: "date_of_birth",
    Cell: ({ value }) => {
      return format(new Date(value), "dd/mm/yyyy");
    },
    Filter: ColumnFilter,
  },
  {
    Header: "Country",
    Footer: "Country",
    accessor: "country",
    Filter: ColumnFilter,
  },
  {
    Header: "Phone",
    Footer: "Phone",
    accessor: "phone",
    Filter: ColumnFilter,
  },
];

export const actions_col = [
  {
    Header: "Id",
    Footer: "Id",
    accessor: "actionId",
    Filter: ColumnFilter,
    //disableFilters: true,
  },
  {
    Header: "Title",
    Footer: "Title",
    accessor: "localization",
    Cell: ({ value }) => {
      return cellStyle(value.en.title);
    },
    Filter: ColumnFilter,
  },
  {
    Header: "Company name",
    Footer: "Company name",
    accessor: "companyName",
    Filter: ColumnFilter,
  },
  {
    Header: "Valid untill",
    Footer: "Valid untill",
    accessor: "validUntill",
    Filter: ColumnFilter,
  },
];

export const educationInstitutionCol = [
  {
    Header: "Id",
    Footer: "Id",
    accessor: "id",
    Filter: ColumnFilter,
  },
  {
    Header: "Name",
    Footer: "Name",
    accessor: "name",
    Cell: ({ value }) => {
      return cellStyle(value);
    },
    Filter: ColumnFilter,
  },
  {
    Header: "Domain",
    Footer: "Domain",
    accessor: "domain",
    Filter: ColumnFilter,
  },
  {
    Header: "Education level",
    Footer: "Education level",
    accessor: "educationLevel.name",
    Filter: ColumnFilter,
  },
  {
    Header: "Created at",
    Footer: "Created at",
    accessor: "createdAt",
    Cell: ({ value }) => format(new Date(value), "dd-MM-yyyy"),
    Filter: ColumnFilter,
  },
  {
    Header: "Updated at",
    Footer: "Updated at",
    accessor: "updatedAt",
    Cell: ({ value }) => format(new Date(value), "dd-MM-yyyy"),
    Filter: ColumnFilter,
  },
  {
    Header: "City",
    Footer: "City",
    accessor: "city.name",
    Filter: ColumnFilter,
  },
  {
    Header: "Free Distribution",
    Footer: "Free Distribution",
    accessor: "isFreeDistribution",
    Cell: ({ value }) => {
      return (
        <i
          className={
            value
              ? "text-secondary la la-check fs-24"
              : "text-danger la la-close fs-24"
          }
        ></i>
      );
    },
    Filter: ColumnFilter,
  },
];

export const adminUsersCol = [
  {
    Header: "Id",
    Footer: "Id",
    accessor: "id",
    Filter: ColumnFilter,
  },
  {
    Header: "Email",
    Footer: "Email",
    accessor: "email",
    Cell: ({ value }) => {
      return cellStyle(value);
    },
    Filter: ColumnFilter,
  },
  {
    Header: "Phone Number",
    Footer: "Phone Number",
    accessor: "phoneNumber",
    Filter: ColumnFilter,
  },
];

export const introduction_events_col = [
  {
    Header: "Education name",
    Footer: "Education name",
    accessor: "education_name",
    Cell: ({ value }) => {
      return cellStyle(value);
    },
    Filter: ColumnFilter,
  },
  {
    Header: "Finished processes",
    Footer: "Finished processes",
    accessor: "membership_done_count",
    Filter: ColumnFilter,
  },
  {
    Header: "Account owner",
    Footer: "Account owner",
    accessor: "country",
    Filter: ColumnFilter,
  },
  {
    Header: "Status",
    Footer: "Status",
    accessor: "is_active",
    Cell: ({value}) => (value ? "Active" : "Not Active"),
    Filter: ColumnFilter,
  },
  {
    Header: "Created at",
    Footer: "Created at",
    accessor: "created_at",
    Filter: ColumnFilter,
    Cell: ({ value }) => format(new Date(value), "dd-MM-yyyy"),
  },
  {
    Header: "Updated at",
    Footer: "Updated at",
    accessor: "updated_at",
    Filter: ColumnFilter,
    Cell: ({ value }) => format(new Date(value), "dd-MM-yyyy"),
  },
];

export const company_column_name = [
  {
    Header: "Name",
    Footer: "Name",
    accessor: "name",
    Cell: ({ value }) => {
      return cellStyle(value);
    },
    Filter: ColumnFilter,
  },
  {
    Header: "Category",
    Footer: "Category",
    accessor: "category.name",
    Filter: ColumnFilter,
  },
  {
    Header: "Website",
    Footer: "Website",
    accessor: "website",
    Filter: ColumnFilter,
  },
  {
    Header: "Status",
    Footer: "Status",
    accessor: "isDisabled",
    Filter: ColumnFilter,
    Cell: ({ value }) => {
      return <p>{value === false ? "Visible" : "Hidden"}</p>;
    },
  },
  {
    Header: () => <div style={{ textAlign: "center" }}>Has Live Discounts?</div>,
    Footer: "Has Live Discounts?",
    accessor: "actionsCount",
    Filter: ColumnFilter,
    Cell: ({ value }) => {
      return <p  style={{textAlign: "center"}}>{value > 0 ? "Yes" : "No" }</p>;
    },
  },
  {
    Header: "Send Statistic Emails?",
    Footer: "Send Statistic Emails?",
    accessor: "isSendStatistics",
    Filter: ColumnFilter,
    Cell: ({ value }) => {
      return <p style={{textAlign: "center"}}>{value === true ? "Yes" : "No"}</p>;
    },
  },
];

const isDateInRange = (startDate, endDate) => {
  const today = new Date();
  const start = new Date(startDate);
  const end = new Date(endDate);
  return today >= start && today <= end;
};
export const discounts_column_name = [
  {
    Header: "ID",
    Footer: "ID",
    accessor: "id",
    Filter: ColumnFilter
  },
  {
    Header: "Status",
    Footer: "Status",
    accessor: row => ({ startDate: row.startDate, endDate: row.endDate }),
    Cell: ({ value }) => {
      const { startDate, endDate } = value;
      const status = getDiscountStatus(startDate, endDate);
      let color;

      switch (status) {
        case 'Not Live Yet':
          color = 'orange';
          break;
        case 'Live':
          color = 'green';
          break;
        case 'Expired':
          color = 'red';
          break;
        default:
          color = 'black';
      }

      return (
          <span style={{ color }}>
          {status}
        </span>
      );
    },
  },
  {
    Header: "From",
    Footer: "From",
    accessor: "startDate",
    Filter: ColumnFilter,
    Cell: ({ value }) => {
      return transformDate(value?.toString(), true);
    },
  },
  {
    Header: "Until",
    Footer: "Until",
    accessor: "endDate",
    Filter: ColumnFilter,
    Cell: ({ value }) => {
      return transformDate(value?.toString(), true);
    },
  },
  {
    Header: "Type",
    Footer: "Type",
    accessor: "type",
    Filter: ColumnFilter,
    Cell: ({ value }) => {
     return  value < 0 ? <i className={"text-danger la la-infinity fs-24"}></i> : value;
    },
  },
  {
    Header: () => <div style={{ textAlign: "center" }}>Remaining</div>,
    Footer: "Remaining",
    accessor: "usageDetails",
    Filter: ColumnFilter,
    Cell: ({ value }) => {
      return (
          <div style={{ textAlign: "center" }}>
            {value?.totalCapacity < 0 ? (
                <i className={"text-danger la la-infinity fs-24"}></i>
            ) : (
                value.totalRemaining
            )}
          </div>
      );
    },
  },
  {
    Header: () => <div style={{ textAlign: "center" }}>Auto start</div>,
    Footer: "Auto start",
    accessor: "autoStart",
    Filter: ColumnFilter,
    Cell: ({ value }) => {
      return (
          <div style={{ textAlign: "center"  }}>
            <i
                className={
                  value
                      ? "text-secondary la la-check fs-24"
                      : "text-danger la la-close fs-24"
                }
            ></i>
          </div>
      );
    },
  },
  {
    Header: "Options",
    Footer: "Options",
    accessor: "duplicate",
    Cell: ({ row }) => {
      return (
          <a className="btn btn-secondary">
             Duplicate action
          </a>
      );
    },
  },
  {
    Header: "Title",
    Footer: "Title",
    accessor: "localization",
    Cell: ({ value }) => {
      return (value[0]?.subtitle);
    },
    Filter: ColumnFilter,
  },
];

export const groups_column_name = [
  {
    Header: "ID",
    Footer: "ID",
    accessor: "id",
    Filter: ColumnFilter
  },
  {
    Header: "Name",
    Footer: "Name",
    accessor: "name",
    Filter: ColumnFilter,
    Cell: ({ value }) => {
      return value;
    },
  },
  {
    Header: "Creation",
    Footer: "Creation",
    accessor: "created_at",
    Filter: ColumnFilter,
    Cell: ({ value }) => {
      return transformDate(value?.toString(), true);
    },
  },
  {
    Header: "Statistics Report",
    Footer: "Statistics Report",
    accessor: "statistics_report",
    Filter: ColumnFilter,
    Cell: ({ value }) => {
      return (
        <a className="btn btn-secondary">
            Download
        </a>
      );
    },
  },
];
export const locations_column_name = [
  {
    Header: "City",
    Footer: "City",
    accessor: "city",
    Cell: ({ value }) => {
      return cellStyle(value);
    },
    Filter: ColumnFilter,
  },
  {
    Header: "Address",
    Footer: "Address",
    accessor: "address",
    Filter: ColumnFilter,
  },
  {
    Header: "Postal Code",
    Footer: "Postal Code",
    accessor: "postalCode",
    Filter: ColumnFilter,
  },
];

export const unsubscribe_events_col = [
  // {
  // 	Header : 'Id',
  // 	Footer : 'Id',
  // 	accessor: 'id_cancellation',
  // 	Filter: ColumnFilter,
  // 	//disableFilters: true,
  // },
  {
    Header: "Email",
    Footer: "Email",
    accessor: "email",
    Cell: ({ value }) => {
      return cellStyle(value);
    },
    Filter: ColumnFilter,
  },
  {
    Header: "Subscription id",
    Footer: "Subscription id",
    accessor: "subscription_id",
    Filter: ColumnFilter,
  },
  {
    Header: "Cancellation status",
    Footer: "Cancellation status",
    accessor: "membership_cancellation_status",
    Filter: ColumnFilter,
  },
  {
    Header: "Payment status",
    Footer: "Payment status",
    accessor: "payment_status",
    Filter: ColumnFilter,
  },
  {
    Header: "Next reminder date",
    Footer: "Next reminder date",
    accessor: "next_reminder_date",
    Filter: ColumnFilter,
  },
  {
    Header: "Reminders level",
    Footer: "Reminders level",
    accessor: "reminders_level",
    Filter: ColumnFilter,
  },
  {
    Header: "Active reminder process",
    Footer: "Active reminder process",
    accessor: "active_reminder_process",
    Cell: ({ value }) => {
      return value === true ? "YES" : "NO";
    },
    Filter: ColumnFilter,
  },
  {
    Header: "Mollie payment id",
    Footer: "Mollie payment id",
    accessor: "mollie_payment_id",
    Filter: ColumnFilter,
  },
  {
    Header: "Mollie payment link",
    Footer: "Mollie payment link",
    accessor: "mollie_payment_link",
    Filter: ColumnFilter,
  },

  {
    Header: "Created at",
    Footer: "Created at",
    accessor: "created_at",
    Filter: ColumnFilter,
  },
  {
    Header: "Updated at",
    Footer: "Updated at",
    accessor: "updated_at",
    Filter: ColumnFilter,
  },
];

export const system_user_forms_col = [
  {
    Header: "Id",
    Footer: "Id",
    accessor: "id",
    Filter: ColumnFilter,
    //disableFilters: true,
  },
  {
    Header: "Name",
    Footer: "Name",
    accessor: "name",
    Filter: ColumnFilter,
  },
  {
    Header: "Email",
    Footer: "Email",
    Cell: ({ value }) => {
      return cellStyle(value);
    },
    accessor: "email",
    Filter: ColumnFilter,
  },
  {
    Header: "Forms",
    Footer: "Forms",
    accessor: "forms",
    Filter: ColumnFilter,
  },
  {
    Header: "type",
    Footer: "type",
    accessor: "type",
    Filter: ColumnFilter,
  },
  {
    Header: "Status",
    Footer: "Status",
    accessor: "status",
    Filter: ColumnFilter,
  },
  {
    Header: "Created At",
    Footer: "Created At",
    accessor: "createdAt",
    Filter: ColumnFilter,
  },
  {
    Header: "Updated At",
    Footer: "Updated At",
    accessor: "updatedAt",
    Filter: ColumnFilter,
  },
];

export const membership_form_order_col = [
  {
    Header: "Contributor",
    Footer: "Contributor",
    accessor: "register_user_name",
    Filter: ColumnFilter,
  },
  {
    Header: "Mandate number",
    Footer: "Mandate number",
    accessor: "form_number",
    Filter: ColumnFilter,
  },
  {
    Header: "Knaek Id",
    Footer: "Knaek Id",
    accessor: "knaek_id",
    Filter: ColumnFilter,
    //disableFilters: true,
  },
  {
    Header: "Email",
    Footer: "Email",
    accessor: "email",
    Filter: ColumnFilter,
  },
  {
    Header: "First name",
    Footer: "First name",
    accessor: "first_name",
    Filter: ColumnFilter,
  },
  {
    Header: "Last name",
    Footer: "Last name",
    accessor: "last_name",
    Filter: ColumnFilter,
  },
  {
    Header: "Gender",
    Footer: "Gender",
    accessor: "gender",
    Filter: ColumnFilter,
  },
  {
    Header: "Birthday",
    Footer: "Birthday",
    accessor: "birthday",
    Filter: ColumnFilter,
  },
  {
    Header: "Iban",
    Footer: "Iban",
    accessor: "iban",
    Filter: ColumnFilter,
  },
  {
    Header: "Country code",
    Footer: "Country code",
    accessor: "country_code",
    Filter: ColumnFilter,
  },
  {
    Header: "Phone number",
    Footer: "Phone number",
    accessor: "phone_number",
    Filter: ColumnFilter,
  },

  {
    Header: "Country",
    Footer: "Country",
    accessor: "country",
    Filter: ColumnFilter,
  },

  {
    Header: "created_at",
    Footer: "created_at",
    accessor: "created_at",
    Filter: ColumnFilter,
  },
  {
    Header: "updated_at",
    Footer: "updated_at",
    accessor: "updated_at",
    Filter: ColumnFilter,
  },
];

export const locations_col = [
  {
    Header: "Location Id",
    Footer: "Location Id",
    accessor: "locationId",
    Filter: ColumnFilter,
  },
  {
    Header: "Company Id",
    Footer: "Company Id",
    accessor: "companyId",
    Filter: ColumnFilter,
  },
  {
    Header: "Name",
    Footer: "Name",
    accessor: "name",
    Cell: ({ value }) => {
      return cellStyle(value);
    },
    Filter: ColumnFilter,
  },
  {
    Header: "Address",
    Footer: "Address",
    accessor: "address",
    Filter: ColumnFilter,
  },
  {
    Header: "City",
    Footer: "City",
    accessor: "city",
    Filter: ColumnFilter,
  },
  {
    Header: "Latitude",
    Footer: "Latitude",
    accessor: "latitude",
    Filter: ColumnFilter,
  },
  {
    Header: "Longitude",
    Footer: "Longitude",
    accessor: "longitude",
    Filter: ColumnFilter,
  },
];

export const localStoresCol = [
  {
    Header: "Id",
    Footer: "Id",
    accessor: "id",
    Filter: ColumnFilter,
  },
  {
    Header: "Country",
    Footer: "Country",
    accessor: ({ culture }) => {
      return culture.includes("NL") ? "Netherlands" : "Belgium";
    },
    Filter: ColumnFilter,
  },
  {
    Header: "Title",
    Footer: "Title",
    accessor: "title",
    Cell: ({ value }) => {
      return cellStyle(value);
    },
    Filter: ColumnFilter,
  },
  {
    Header: "App Weight",
    Footer: "App Weight",
    accessor: "appWeight",
    Filter: ColumnFilter,
  },
  {
    Header: "Web Weight",
    Footer: "Web Weight",
    accessor: "webWeight",
    Filter: ColumnFilter,
  },
  {
    Header: "Short Description",
    Footer: "Short Description",
    accessor: "nl.shortDes",
    Filter: ColumnFilter,
  },
  {
    Header: "Full Description",
    Footer: "Full Description",
    accessor: "nl.fullDes",
    Filter: ColumnFilter,
  },
  {
    Header: "Background Image",
    Footer: "Background Image",
    accessor: "background",
    Filter: ColumnFilter,
  },
  {
    Header: "Enabled",
    Footer: "Enabled",
    accessor: "disabled",
    Cell: ({ value }) => {
      return (
        <i
          className={
            value
              ? "text-danger la la-close fs-24"
              : "text-secondary la la-check fs-24"
          }
        ></i>
      );
    },
    Filter: ColumnFilter,
  },
];

export const orders_col = [
  {
    Header: "First name",
    Footer: "First name",
    accessor: "firstName",
    Filter: ColumnFilter,
  },
  {
    Header: "Last name",
    Footer: "Last name",
    accessor: "lastName",
    Filter: ColumnFilter,
  },
  {
    Header: "Email",
    Footer: "Email",
    accessor: "email",
    Cell: ({ value }) => {
      return cellStyle(value);
    },
    Filter: ColumnFilter,
  },
  {
    Header: "Student email",
    Footer: "Student email",
    accessor: "studentEmail",
    Cell: ({ value }) => {
      return cellStyle(value);
    },
    Filter: ColumnFilter,
  },
  {
    Header: "Origin",
    Footer: "Origin",
    accessor: "origin",
    Filter: ColumnFilter,
  },
  {
    Header: "Country code",
    Footer: "Country code",
    accessor: "countryCode",
    Filter: ColumnFilter,
  },
  {
    Header: "Phone number",
    Footer: "Phone number",
    accessor: "phoneNumber",
    Filter: ColumnFilter,
  },
  {
    Header: "Order status",
    Footer: "Order status",
    accessor: "status",
    Filter: ColumnFilter,
  },
  {
    Header: "Channel",
    Footer: "Channel",
    accessor: "channel",
    Filter: ColumnFilter,
  },
  {
    Header: "Country",
    Footer: "Country",
    accessor: "country",
    Filter: ColumnFilter,
  },
  {
    Header: "Created at",
    Footer: "Created at",
    accessor: "createdAt",
    Cell: ({ value }) => {
      return humanDate(value);
    },
    Filter: ColumnFilter,
  },
  // {
  //     Header: 'Mollie payment link',
  //     Footer: 'Mollie payment link',
  //     accessor: 'mollie_payment_link',
  //     Filter: ColumnFilter,
  // },
  // {
  //     Header: 'Mollie payment id',
  //     Footer: 'Mollie payment id',
  //     accessor: 'mollie_payment_id',
  //     Filter: ColumnFilter,
  // },
  {
    Header: "Intro name",
    Footer: "Intro name",
    accessor: "introName",
    Filter: ColumnFilter,
  },
];

export const countryCodesCol = [
  {
    Header: "Id",
    Footer: "Id",
    accessor: "id",
    Filter: ColumnFilter,
  },
  {
    Header: "Label",
    Footer: "Label",
    accessor: "label",
    Filter: ColumnFilter,
  },
  {
    Header: "Value",
    Footer: "Value",
    accessor: "value",
    Filter: ColumnFilter,
  },
];


export const vouchers_column_name = [
  {
    Header: ({ value, checkedAll }) => {
      return (
          <input type="checkbox" checked={checkedAll} ></input>
      );
    },
    Footer: ({ value, checkedAll }) => {
      return (
          <input type="checkbox" checked={checkedAll} ></input>
      );
    },
    accessor: "id",
    Cell: ({ value, checkedAll }) => {
      return (
          <input type="checkbox" checked={checkedAll} ></input>
      );
    },
  },
  {
    Header: "Action",
    Footer: "Action",
    accessor: "translations",
    Filter: ColumnFilter,
    Cell: ({ value }) => {
      let lang = value.filter((item) => item.lang == 'en')

      return lang.length > 0 ? lang[0].subtitle : ''
    },
  },
  {
    Header: "Voucher",
    Footer: "Voucher",
    accessor: "voucher",
    Filter: ColumnFilter,
    Cell: ({ value }) => {
      return  value?.toString() ;
    },
  },
  {
    Header: "Start Date",
    Footer: "Start Date",
    accessor: "startDate",
    Filter: ColumnFilter,
    Cell: ({ value }) => {
      return value ? transformDate(value.toString(), true) : '';
    },
  },
  {
    Header: "End Date",
    Footer: "End Date",
    accessor: "endDate",
    Filter: ColumnFilter,
    Cell: ({ value }) => {
      return value ? transformDate(value.toString(), true) : '';
    },
  },
  {
    Header: "Used",
    Footer: "Used",
    accessor: "userId",
    Filter: ColumnFilter,
    Cell: ({ value }) => {
      return value ? 'Yes' : 'No'
    },
  },
];

export const SubscriptionTableColumn = [
  {
    Header: ({ value, checkedAll }) => {
      return (
          <input type="checkbox" checked={checkedAll} ></input>
      );
    },
    Footer: ({ value, checkedAll }) => {
      return (
          <input type="checkbox" checked={checkedAll} ></input>
      );
    },
    accessor: "id",
    Cell: ({ value, checkedAll }) => {
      return (
          <input type="checkbox" checked={checkedAll}  ></input>
      );
    },
  },
  {
    Header: "First Name",
    Footer: "First Name",
    accessor: "firstName",
    Cell: ({ value }) => {
      return hasAValue(value) ? value : "-";
    },
  },
  {
    Header: "Last Name",
    Footer: "Last Name",
    accessor: "lastName",
    Cell: ({ value }) => {
      return hasAValue(value) ? value : "-";
    },
  },
  {
    Header: "Email",
    Footer: "Email",
    accessor: "email",
    Cell: ({ value }) => {
      return hasAValue(value) ? value : "-";
    },
  },
  {
    Header : 'Expiration Date',
    Footer : 'Expiration Date',
    accessor: 'expirationDate',
    Cell: ({ value }) => {return (value?.toString().substring(0,10) + " " +value?.toString().substring(11,16))},
  },
  {
    Header: "Status",
    Footer: "Status",
    accessor: "status",
    Cell: ({ value }) => {
      return hasAValue(value) ? value : "-";
    },
  },
];
