import React, {useEffect, useState} from 'react';
import {useTable, useGlobalFilter, useFilters, usePagination, useSortBy} from 'react-table';
import './../FilteringTable/filtering.css';
import {columns_notification} from "../FilteringTable/NotificationTabelsColumns";
import {
    fetchAllNotificationsCall,
    fetchLastUpdateNotificationCall,
    startSendingNotificationCall
} from "../../Api/ApiCalls";
import {toast} from "react-toastify";
import {toastOptions} from "../../config/Constants";
import {Modal} from "react-bootstrap";
import {hasAValue, formatToDayNameString} from "../../utils/SharedFunctions";
import {Link} from "react-router-dom";
import {ApiSearchField} from "../ApiSearchField";
import instoreIcon from "../../style/images/icons/instore.png";
import urlIcon from "../../style/images/icons/url.png";
import onlineIcon from "../../style/images/icons/online.png";
import jobIcon from "../../style/images/icons/job.png";
import BelgiumIcon from "../../style/images/icons/Belgium.png";
import NetherlandsIcon from "../../style/images/icons/Netherlands.png";


export default function AllNotification() {
    const [data, setData] = useState([])
    const [totalPages, setTotalPages] = useState(undefined);
    const [currentPage, setCurrentPage] = useState(undefined);
    const [columns, setColumns] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [notificationDetails, setNotificationDetails] = useState(undefined)
    const [notificationSegmentation, setNotificationSegmentation] = useState(undefined)
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        setColumns(columns_notification)
    }, []);

    useEffect(() => {
        fetchAllNotifications(1);
    }, [searchQuery]);


    const tableInstance = useTable({
        columns,
        data,
        initialState: {pageIndex: 0}
    }, useFilters, useGlobalFilter, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
    } = tableInstance


    const {pageIndex} = state

    const typesIcons = {
        url: urlIcon,
        instore: instoreIcon,
        cashback: onlineIcon,
        jobs: jobIcon
    }
    const typesLabels = {
        url: "URL",
        instore: "Instore",
        cashback: "Online",
        jobs: "Jobs"
    }

    const sessions = [
        {label: "Last session within 3 days ago", value: "< 3"} ,
        {label: "Last session more than 3 days ago", value: "> 3"},
        {label: "Last session within 5 days ago", value: "< 5"},
        {label: "Last session more than 5 days ago", value: "> 5"},
        {label: "Last session within 7 days ago", value: "< 7"},
        {label: "Last session more than 7 days ago", value: "> 7"},
        {label: "Last session within 14 days ago", value: "< 14"},
        {label: "Last session more than 14 days ago", value: "> 14"}
    ]

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Notifications</h4>
                    <Link to={"/new-notification"} className="btn btn-secondary">+ New Notification</Link>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <ApiSearchField
                            searchQuery={searchQuery}
                            setSearchQuery={setSearchQuery}
                        />
                        <table {...getTableProps()} className="table dataTable display">
                            <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th style={{'width' : ( column.render('Header') == 'Made by' ? '250px' : 'auto' ) }}>
                                            {column.render('Header')}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>

                            {page.map((row) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()} style={{'textTransform': 'capitalize'}}>
                                        {row.cells.map((cell) => {
                                            return <td {...cell.getCellProps()} className={"cursor-pointer"}
                                                       onClick={() => openModalShow(row.original)}> {cell.render('Cell')} </td>

                                        })}
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                        <div className="d-flex justify-content-between">
              <span>
                Page{" "}
                  <strong>
                  {currentPage} of {totalPages}
                </strong>
                  {""}
              </span>
                            <span className="table-index d-none">
                Go to page :{" "}
                                <input
                                    type="number"
                                    className="ml-2"
                                    defaultValue={pageIndex + 1}
                                    onChange={(e) => {
                                        const pageNumber = e.target.value
                                            ? Number(e.target.value)
                                            : 1;
                                        fetchAllNotifications(pageNumber);
                                    }}
                                />
              </span>
                        </div>
                        <div className="text-center mb-3">
                            <div className="filter-pagination  mt-3">
                                <button
                                    className=" previous-button"
                                    onClick={() => fetchAllNotifications(1)}
                                    disabled={currentPage === 1}
                                >
                                    {"<<"}
                                </button>

                                <button
                                    className="previous-button"
                                    onClick={() => fetchAllNotifications(currentPage - 1)}
                                    disabled={currentPage === 1}
                                >
                                    Previous
                                </button>
                                <button
                                    className="next-button"
                                    onClick={() => fetchAllNotifications(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                >
                                    Next
                                </button>
                                <button
                                    className=" next-button"
                                    onClick={() => fetchAllNotifications(totalPages)}
                                    disabled={currentPage === totalPages}
                                >
                                    {">>"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal className="modal fade" size={"xl"} show={showModal} onHide={setShowModal}>
                <div className="" role="document">
                    <div className="">
                        <form>
                            <div className="modal-header">
                                <h4 className="modal-title fs-20">Notification</h4>
                                <button type="button" className="btn-close" onClick={() => setShowModal(false)}
                                        data-dismiss="modal"></button>
                            </div>
                            {hasAValue(notificationDetails) && <div className="modal-body">
                                <div className="card border-top shadow">
                                    <div className="card-header border-0 pb-0">
                                        <h2 className="card-title">Overview</h2>
                                        {/*{*/}
                                        {/*    notificationDetails.status == 'Sent' ? */}
                                        {/*    (*/}
                                        {/*        <div className='d-flex align-items-center text-green'>*/}
                                        {/*            <svg width="20" height="20" viewBox="0 0 20 20" className='me-2' fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                                        {/*                <rect width="20" height="20" rx="10" fill="#135846"/>*/}
                                        {/*                <path d="M5.91669 10.0002L8.83335 12.9168L14.6667 7.0835" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>*/}
                                        {/*            </svg>*/}
                                        {/*            Sending Completed*/}
                                        {/*        </div>*/}
                                        {/*    )*/}
                                        {/*    : */}
                                        {/*    (*/}
                                        {/*        <div className='d-flex align-items-center text-warning'>*/}
                                        {/*            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{'width': '20px', 'fill': '#FB9F44'}} className='me-2'><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336l24 0 0-64-24 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l48 0c13.3 0 24 10.7 24 24l0 88 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-80 0c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>*/}
                                        {/*            Draft*/}
                                        {/*        </div>*/}
                                        {/*    )*/}
                                        {/*}*/}

                                    </div>
                                    <div className="card-body pb-0">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Type</strong>
                                                <span className={"col-9 fw-bold"}>
                                                    <img className='me-2' src={typesIcons[notificationDetails.type]} />
                                                    <span className='text-primary'>{typesLabels[notificationDetails.type]}</span>
                                                </span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Title (NL)</strong>
                                                <span
                                                    className={"col-9"}>{notificationDetails.notificationData?.titleNl}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Body (NL)</strong>
                                                <span
                                                    className={"col-9"}>{notificationDetails.notificationData?.bodyNl}</span>
                                            </li>
                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Title (EN)</strong>
                                                <span
                                                    className={"col-9"}>{notificationDetails.notificationData?.titleEn}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Body (EN)</strong>
                                                <span
                                                    className={"col-9"}>{notificationDetails.notificationData?.bodyEn}</span>
                                            </li>

                                            {
                                                notificationDetails.type.toString().includes("url") ?
                                                    <li className="list-group-item d-flex px-0 justify-content-between row">
                                                        <strong className={"col-3"}>Target</strong>
                                                        <span
                                                            className={"col-9 fw-bold text-primary"}>{notificationDetails.data?.url}</span>
                                                    </li>
                                                :
                                                <li className="list-group-item d-flex px-0 justify-content-between row">
                                                    <strong className={"col-3"}>Target</strong>
                                                    <span
                                                        className={"col-9 fw-bold text-primary"}>{notificationDetails.data?.name}</span>
                                                </li>
                                            }

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Made by</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{notificationDetails.madeBy}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Created at</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{formatToDayNameString(notificationDetails.createdAt)}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Total Audience</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{notificationDetails.totalReceivers}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-md-3 col-sm-10 mb-2"}>Android</strong>
                                                <div className={"col-9 d-flex flex-wrap"}>
                                                <strong className={"col-md-3 col-sm-10 mb-2"}>Audience</strong>
                                                    <strong className={"col-md-3 col-sm-10 mb-2"}>Received</strong>
                                                    <strong className={"col-md-3 col-sm-10 mb-2"}>Failed</strong>
                                                    <span className={"col-md-3 col-sm-10 mb-2"}></span>
                                                    <span
                                                        className={"col-md-3 col-sm-10 fw-bold text-primary"}>{(hasAValue(notificationDetails.firebaseSent) && hasAValue(notificationDetails.firebaseFailed)) &&
                                                        (parseInt(notificationDetails.firebaseSent) + parseInt(notificationDetails.firebaseFailed))}</span>
                                                    <span
                                                        className={"col-md-3 col-sm-10 fw-bold text-primary"}>{notificationDetails.firebaseSent}</span>
                                                    <span
                                                        className={"col-md-3 col-sm-10 fw-bold text-primary"}>{notificationDetails.firebaseFailed}</span>
                                                </div>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-md-3 col-sm-10 mb-2"}>iOS</strong>
                                                <div className={"col-9 d-flex flex-wrap"}>
                                                <strong className={"col-md-3 col-sm-10 mb-2"}>Audience</strong>
                                                    <strong className={"col-md-3 col-sm-10 mb-2"}>Received</strong>
                                                    <strong className={"col-md-3 col-sm-10 mb-2"}>Failed</strong>
                                                    <span className={"col-md-3 col-sm-10 mb-2"}></span>
                                                    <span
                                                        className={"col-md-3 col-sm-10 fw-bold text-primary"}>{(hasAValue(notificationDetails.iosSent) && hasAValue(notificationDetails.iosFailed)) &&
                                                        (parseInt(notificationDetails.iosSent) + parseInt(notificationDetails.iosFailed))}</span>
                                                    <span
                                                        className={"col-md-3 col-sm-10 fw-bold text-primary"}>{notificationDetails.iosSent}</span>
                                                    <span
                                                        className={"col-md-3 col-sm-10 fw-bold text-primary"}>{notificationDetails.iosFailed}</span>
                                                </div>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Recipients Filter</strong>
                                                <div className={"col-9"}>
                                                    <div className={"col-md-12 px-0 d-flex flex-column"}>
                                                        {
                                                            notificationSegmentation &&
                                                            <>
                                                                <div className={"col-md-12 px-0 d-flex flex-wrap px-0"}>
                                                                    { notificationSegmentation.limitationType && <strong className={"col-md-3 col-sm-10 mb-2"}>Mode</strong> }
                                                                    { notificationSegmentation.Gender && <strong className={"col-md-3 col-sm-10 mb-2"}>Gender</strong> }
                                                                    { notificationSegmentation.targetGroup && <strong className={"col-md-3 col-sm-10 mb-2"}>Target Group</strong> }
                                                                    { notificationSegmentation.accountOwner && <strong className={"col-md-3 col-sm-10 mb-2"}>Account Owner</strong> }
                                                                </div>
                                                                <div className={"col-md-12 px-0 d-flex flex-wrap px-0"}>
                                                                    { notificationSegmentation.limitationType && <span className={"col-md-3 col-sm-10 fw-bold text-primary"} style={{'textTransform': 'capitalize'}}>
                                                                        {
                                                                            notificationSegmentation.limitationType != '' ?
                                                                            notificationSegmentation.limitationType == 'limited_unlimited' ?
                                                                                notificationSegmentation.limitationType.replaceAll('_', ' & ')
                                                                                :
                                                                                notificationSegmentation.limitationType.replaceAll('_', ' ')
                                                                            :
                                                                            'Limited & Unlimited'
                                                                        }
                                                                        </span>
                                                                    }
                                                                    { notificationSegmentation.Gender && <span className={"col-md-3 col-sm-10 fw-bold text-primary"} style={{'textTransform': 'capitalize'}}>{notificationSegmentation.Gender}</span> }
                                                                    { notificationSegmentation.targetGroup && <span className={"col-md-3 col-sm-10 fw-bold text-primary"} style={{'textTransform': 'capitalize'}}>{notificationSegmentation.targetGroup}</span> }
                                                                    { notificationSegmentation.accountOwner && <span className={"col-md-3 col-sm-10 fw-bold text-primary"} style={{'textTransform': 'capitalize'}}>{notificationSegmentation.accountOwner.replaceAll('_', ' ')}</span> }
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                    {
                                                        notificationSegmentation?.countries.map(country => {

                                                            return (
                                                                <div className={"col-md-12 px-0 d-flex flex-column mt-3"}>
                                                                    <div className={"col-md-12 px-0 d-flex flex-wrap"}>
                                                                        <strong className={"col-md-3 col-sm-10 mb-2"}>Country</strong>
                                                                        <strong className={"col-md-3 col-sm-10 mb-2"}>Cities</strong>
                                                                    </div>
                                                                    <div className={"col-md-12 px-0 d-flex flex-wrap"}>
                                                                        <span className={"col-md-3 col-sm-10 fw-bold"}>
                                                                            {
                                                                                country.name == 'Nederland' ?
                                                                                <>
                                                                                    <img src={NetherlandsIcon} className='me-2' />
                                                                                    Netherlands
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <img src={BelgiumIcon} className='me-2' />
                                                                                    Belgium
                                                                                </>
                                                                            }
                                                                        </span>
                                                                        <span className={"col-md-9 col-sm-10 mb-2"}>
                                                                            {
                                                                                country.cities.map((city, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <span className='text-primary fw-bold'>{city.name}</span>
                                                                                            { index < ( country.cities.length - 1 ) && <span className='mx-2'>|</span> }
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    <div className={"col-md-12 px-0 d-flex flex-wrap mt-3"}>
                                                        {
                                                            notificationSegmentation &&
                                                            <>
                                                                { notificationSegmentation.userSession && <strong className={"col-md-3 col-sm-10 mb-2"}>Session</strong> }
                                                                { notificationSegmentation.userSession &&
                                                                    <span className={"col-md-12 col-sm-10 fw-bold text-primary"}>
                                                                        {
                                                                            sessions.filter(item => {
                                                                                return item.value == notificationSegmentation.userSession
                                                                            })[0]?.label
                                                                        }
                                                                    </span>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>}

                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => {
                                        startSendingANotification()
                                    }}>Start sending notification
                                </button>
                                <button type="button" onClick={() => setShowModal(false)}
                                        className="btn btn-warning">Close
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </Modal>
        </>
    )

    function openModalShow(item) {
        setShowModal(true)
        setNotificationDetails(item)
        setNotificationSegmentation(item?.segmentationCriteria)
    }

    function startSendingANotification() {
        startSendingNotificationCall(notificationDetails.id).then(r => {
            toast.success(r.data.message, toastOptions)
        }).catch(error => {
            toast.warn(error.message, toastOptions)
        })
    }

    function fetchLastUpdateNotification(id) {

        let numberOfTimes = 50;
        let delay = 4000;

        for (let i = 0; i < numberOfTimes; i++) {
            setTimeout(() => {
                fetchLastUpdateNotificationCall(id).then(notification => {
                    setNotificationDetails(notification)
                }).catch(error => {

                })
                if (numberOfTimes % i === 0) {
                    fetchAllNotifications()
                }
            }, delay * i);

        }
    }

    function getFilters() {
        const apiFilters = [];
        if (searchQuery?.length) {
            apiFilters.push(`q=${encodeURIComponent(searchQuery)}`);
        }
        return apiFilters.join("&");
    }

    function fetchAllNotifications(page) {
        fetchAllNotificationsCall(page, 10, getFilters()).then(result => {
            setData(result?.items);
            setTotalPages(result.totalPages);
            setCurrentPage(result.page);
        }).catch(error => {
            toast.error(error.response.data.error, toastOptions)
        })
    }
}
