import React, {useEffect, useState} from "react";
import {useFilters, useGlobalFilter, usePagination, useSortBy, useTable,} from "react-table";
import { groups_column_name} from "../../../../components/FilteringTable/Columns";
import {Modal} from "react-bootstrap";
import {
    getGroupsByCompanyId,
    getGroupDetailById,
    deleteGroupById,
    addGroupForCompany,
    updateGroupCall,
    downloadGroupStatisticsCall
} from "../../../../Api/ApiCalls";
import {handleError, transformDate} from "../../../../utils/SharedFunctions";
import {toast} from "react-toastify";
import 'react-tabs/style/react-tabs.css';
import { useDebounce } from "../../../../hooks/useDebounce";
import loader from "../../../../style/images/loader.gif";

export default function  Discounts ({companyId, getDiscountGroups, initialGroups}) {
    const [companyGroups, setCompanyGroups] = useState([]);
    const [columns, setColumns] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [pageSize, setPageSize] = useState(25); // Default page size
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [targetPage, setTargetPage] = useState(0);
    const [isCreating, setIsCreating] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
    });
    const [showLoader, setShowLoader] = useState(false);

    const [searchQuery, setSearchQuery] = useState("");
    const debouncedSearchQuery = useDebounce(searchQuery, 1000);
    const [isInitial, setIsInitial] = useState(true)

    useEffect(() => {
        setColumns(groups_column_name);
        if (!searchQuery) {
            if(isInitial && initialGroups && initialGroups.items?.length > 0){
                setCompanyGroups(initialGroups.items.slice(0, pageSize));
                setTotalPages(Math.ceil(initialGroups.count / pageSize));
                setCurrentPage(initialGroups.page);
                setTargetPage(initialGroups.page + 1);
                setIsInitial(false);
            }
            return;
        }
        getGroups(currentPage, companyId, pageSize);
    }, [debouncedSearchQuery, initialGroups]);

    const tableInstance = useTable(
        {
            columns,
            data: companyGroups,
            initialState: { pageIndex: 0,  pageSize: 50 },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
    } = tableInstance;
    useEffect(() => {
        getGroups(1, companyId);
    }, [pageSize]);
    
    const downloadGroupStatistics = async (groupId) => {
        
        setShowLoader(true);
        const result = await downloadGroupStatisticsCall(companyId, groupId);
        const blob = new Blob([result], { type: result.type});
        const el = document.createElement("a");
        el.href = window.URL.createObjectURL(blob);
        el.download = `${companyId}-stats-report-${transformDate(new Date(), false)}.xlsx`;
        el.click();
        setShowLoader(false);
        
    }
    return (
        <>


        {
              showLoader && (
                  <div id="full-loader">
                      <img src={loader} />
                  </div>
              )
          }
            <div className="col-xl-12 col-xxl-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Discount Groups</h4>
                        <a className="btn btn-secondary" onClick={() => openModalAdd()}>
                            + Add group
                        </a>
                    </div>

                    <div className="card-body">
                        <div className="table-responsive">
                            <table {...getTableProps()} className="table dataTable display">
                                <thead>
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <th>
                                                {column.render("Header")}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                {page.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell, index) => {
                                                return (
                                                    <td
                                                        key={index}
                                                        {...cell.getCellProps()}
                                                        className={"cursor-pointer"}
                                                        onClick={() => 
                                                                    cell.column.Header === "Options" ? 
                                                                        null 
                                                                        : 
                                                                        cell.column.Header === "Statistics Report" ?
                                                                            downloadGroupStatistics(row.original.id)
                                                                            :
                                                                            openModalEdit(row.original)
                                                        }
                                                    >
                                                        {cell.render("Cell")}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                            {/* This is only for footer if u require */}
                            <div className="d-flex justify-content-between">
                                <span>
                                Page{" "}
                                    <strong>
                                    {companyGroups.length === 0
                                        ? "0 of 0"
                                        : currentPage + " of " + totalPages}
                                </strong>
                                    {""}
                                </span>
                                <span className="table-index d-none">
                                    Go to page :{" "}
                                    <input
                                        type="number"
                                        className="ml-2"
                                        value={targetPage}
                                        onChange={(e) => {
                                            setTargetPage(e.target.value);
                                            getGroups(Number(e.target.value), companyId, pageSize);
                                        }}
                                    />
                                </span>
                                <div className="mx-2">
                                    <label>
                                        <select
                                            className={"form-select form-select-lg"}
                                            value={pageSize}
                                            onChange={(e) => {
                                                setPageSize(Number(e.target.value));
                                                setCurrentPage(1);
                                                getGroups(1, companyId, Number(e.target.value));
                                            }}
                                        >
                                            <option value={10}>10</option>
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                            <div className="text-center mb-3">

                                <div className="filter-pagination  mt-3">
                                    <button
                                        className=" previous-button"
                                        onClick={() => {
                                            if (companyGroups.length === 0) return;

                                            getGroups(1, companyId, pageSize);
                                        }}
                                        disabled={currentPage === 1 || companyGroups.length === 0}
                                    >
                                        {"<<"}
                                    </button>

                                    <button
                                        className="previous-button"
                                        onClick={() => {
                                            if (companyGroups.length === 0) return;
                                            getGroups(currentPage - 1, companyId, pageSize);
                                        }}
                                        disabled={currentPage === 1 || companyGroups.length === 0}
                                    >
                                        Previous
                                    </button>
                                    <button
                                        className="next-button"
                                        onClick={() => {
                                            if (companyGroups.length === 0) return;
                                            getGroups(targetPage, companyId, pageSize);
                                        }}
                                        disabled={currentPage === totalPages || companyGroups.length === 0}
                                    >
                                        Next
                                    </button>
                                    <button
                                        className=" next-button"
                                        onClick={() => {
                                            if (companyGroups.length === 0) return;
                                            getGroups(totalPages, companyId, pageSize);
                                        }}
                                        disabled={currentPage === totalPages || companyGroups.length === 0}
                                    >
                                        {">>"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Modal
                className="modal fade"
                size={"xl"}
                show={showModal}
                onHide={setShowModal}
            >
                <div className="" role="document">
                    <div className="">
                            <div className="modal-header">
                                <h4 className="modal-title fs-20"> {showEdit ? "Update Group" : "Add Group"} </h4>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={() => setShowModal(false)}
                                    data-dismiss="modal"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <i className="flaticon-cancel-12 close"></i>
                                <div className="add-contact-box">
                                    <div className="add-contact-content">
                                        <div className={"row"}>

                                            {/*Average Saving*/}
                                            <div className="form-group mb-3 col-sm-12 col-md-12">
                                                <label className="text-black font-w500">
                                                    Name
                                                </label>
                                                <div className="contact-name">
                                                    <input
                                                        type={"text"}
                                                        className="form-control"
                                                        name="name"
                                                        required="required"
                                                        value={formData.name}
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                "name",
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            {!showEdit && (
                                <div className="modal-footer">
                                        {isCreating ? (
                                            <a className="btn btn-secondary" >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <div
                                                        className="spinner-border text-white"
                                                        role="status"
                                                    >
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                            </a>
                                        ) : (
                                            <a className="btn btn-secondary" onClick={() => addGroup()}>
                                                Add
                                            </a>
                                        )}
                                    <a className="btn btn-warning" onClick={() => setShowModal(false)}>
                                        Discard
                                    </a>
                                </div>
                            )}

                            {showEdit && (
                                <div className="modal-footer">
                                    {isCreating ? (
                                        <a className="btn btn-secondary" >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <div
                                                    className="spinner-border text-white"
                                                    role="status"
                                                >
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        </a>
                                    ) : (
                                        <a className="btn btn-secondary" onClick={() => updateGroup()}>
                                            Save
                                        </a>
                                    )}
                                    <a className="btn btn-warning" onClick={() => setShowModal(false)}>
                                        Cancel
                                    </a>
                                    <a className="btn btn-danger" onClick={() => deleteAction()}>
                                        Delete
                                    </a>
                                </div>
                            )}
                    </div>
                </div>
            </Modal>
        </>
    );


    async function getGroups(pageIndex, companyId, pageSize) {
        try {
            const result = await getGroupsByCompanyId(pageIndex, pageSize, companyId,  debouncedSearchQuery);

            setCompanyGroups(result.items);
            setTotalPages(result.totalPages);
            setCurrentPage(result.page);
            setTargetPage(result.page + 1);
        } catch (err) {
            console.log(err);
            handleError(err);
        }
    };

    async function openModalAdd() {
        resetGroupData()
        setShowModal(true);
        setShowEdit(false);
    };

    async function openModalEdit(element) {
        const result = await getGroupDetailById(companyId, element.id);
        setShowEdit(true);
        setShowModal(true);
        setFormData({
            name: result.name,
            id: result.id,
        });
    };

    async function handleInputChange(e, fieldName, value, index) {
        const {name, type, checked} = e.target;
        const update = {...formData, [name]: value};

        switch (type) {
            case "checkbox":
                setFormData({...update, [name]: checked});
                break;
            default:
                setFormData((prevState) => ({
                    ...prevState,
                    [fieldName]: value
                }));
        }

    };
    async function updateGroup() {
        try {

            setIsCreating(false);
            const result = await updateGroupCall({"name" : formData.name}, companyId, formData.id); // Sending formDataToSend to the API function
            setCompanyGroups((prevData) => {
                return prevData.map((item) => {
                    if (item?.id === result?.id) {
                        return {
                            ...item,
                            id: result.id,
                            name: result.name,
                        };
                    }
                    return item;
                });
            });
            await getDiscountGroups()
            toast.success("Group Updated successfully");
            setIsCreating(false);
            setShowModal(false);
        } catch (err) {
            setIsCreating(false);
            handleError(err);
        }
    };


    async function addGroup() {
        try {
            setIsCreating(true);

            await addGroupForCompany({"name" : formData.name}, companyId); // Sending formDataToSend to the API function
            const result = await getDiscountGroups()
            toast.success("Group Added successfully");
            resetGroupData()
            setIsCreating(false);
            setShowModal(false);

            setCompanyGroups(result.items.slice(0, pageSize));
            setTotalPages(Math.ceil(result.count / pageSize));
            setCurrentPage(result.page);
            setTargetPage(result.page + 1);
        } catch (err) {
            setIsCreating(false);
            handleError(err);
        }
    };


    async function deleteAction() {
        try {
            await deleteGroupById(companyId, formData.id);
            setCompanyGroups((prevGroups) =>
                prevGroups.filter((group) => group.id !== formData.id)
            );
            await getDiscountGroups()
            toast.success("Group Deleted successfully");
            setShowModal(false);
            setShowEdit(false);
        } catch (err) {
            setShowModal(false);
            setShowEdit(false);
            handleError(err);
        }
    };

    function resetGroupData() {
        setFormData({
                name: "",
            }
        )
    }

};