import { hasAValue } from "../../utils/SharedFunctions";

export const columns_user = [
  {
    Header: "First Name",
    Footer: "First Name",
    accessor: "firstName",
    Cell: ({ value }) => {
      return hasAValue(value) ? value : "-";
    },
  },
  {
    Header: "Last Name",
    Footer: "Last Name",
    accessor: "lastName",
    Cell: ({ value }) => {
      return hasAValue(value) ? value : "-";
    },
  },
  {
    Header: "Email",
    Footer: "Email",
    accessor: "email",
    Cell: ({ value }) => {
      return hasAValue(value) ? value : "-";
    },
  },
  {
    Header: "Phone number",
    Footer: "Phone number",
    accessor: "phoneNumber",
    Cell: ({ row }) => {
      const { phoneNumber, countryCode } = row.original;
      if (phoneNumber && countryCode) {
        return `${countryCode}${phoneNumber}`;
      }
      else if (phoneNumber) {
        return phoneNumber;
      }
      else if (countryCode) {
        return countryCode;
      }
      return "-";
    },
  }
];
