import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'

export const DetailsCard = ({items}) => {
    
    library.add(far, fas)
    return (
            
        <div className="col-xl-12 col-xxl-12">
            <div className="card">
                <div className="card-body">
                    <div className="d-flex flex-column w-100">
                        <div className="d-flex flex-wrap align-items-center justify-content-start">
                            
                            {items.map((row, index) => {
                                return (
                                    <div className="col-4 details-row my-3">
                                        <FontAwesomeIcon icon={row.icon} />
                                        <div className="detail-row-info">
                                            <span>{row.name}</span>
                                            <h4>{row.value}</h4>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
