import React, { Fragment, useState } from "react";
import { toast } from "react-toastify";
import { handleError, hasAValue } from "../../../utils/SharedFunctions";
import { addMandateNumber } from "../../../Api/ApiCalls";

export default function MandateNumber() {
  const [csv, setCsvFile] = useState();

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const validExtensions = ["csv"];
      const fileExtension = file.name.slice(
        ((file.name.lastIndexOf(".") - 1) >>> 0) + 2
      );

      if (validExtensions.includes(fileExtension.toLowerCase())) {
        setCsvFile(file);
      } else {
        toast.error("Invalid file extension. Please select a CSV file.");
      }
    }
  };

  const submitFile = async () => {
    if (hasAValue(csv)) {
      const formData = new FormData();
      formData.append("file", csv);
      addMandateNumber(formData)
        .then((success) => {
          toast.success("Mandate Numbers received successfully");
          setCsvFile(undefined);
        })
        .catch((error) => {
          handleError(error);
        });
    } else {
      toast.error("File is required");
    }
  };

  return (
    <>
      <Fragment>
        <div className="row justify-content-center">
          <div className="col-xl-12 col-xxl-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Mandate Numbers</h4>
              </div>
              <div className="card-body">
                <section>
                  <div className="row justify-content-center">
                    <div className="">
                      <div className="form-group mb-3">
                        <div
                            className={`form-group mb-3`}
                            style={{width: "300px"}}
                        >
                          <label className="text-black font-w500">
                            Upload CSV File Here (<a href="/mandate_numbers_template.csv" target="_blank"
                                                     className={"text-primary"}>Download example</a>)
                          </label>
                          <input
                              type="file"
                              className="form-control"
                              accept=".csv"
                              onChange={handleFileChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div></div>
                  </div>
                </section>
                <div className="text-end toolbar toolbar-bottom p-2">
                  <button
                      className="btn btn-secondary sw-btn-next ms-1"
                    onClick={submitFile}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
}
