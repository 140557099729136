import React, { Fragment, useEffect, useState } from "react";
import { getChannels, searchUserCall } from "../../../Api/ApiCalls";
import Swal from "sweetalert2";
import { usePagination, useTable } from "react-table";
import { columns_user } from "../../../components/FilteringTable/UserTableColumn";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { handleError } from "../../../utils/SharedFunctions";
import ExtensiveSearchForm from "./forms/ExtensiveSearch/ExtensiveSearchForm";
import { useSelector } from "react-redux";

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export default function UserSearch() {
  const history = useHistory();

  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [channels, setChannels] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQueryError, setSearchQueryError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [targetPage, setTargetPage] = useState(0);
  const [count, setCount] = useState(0);

  const formData = useSelector((state) => state.formData);

  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const [isLoadingQuery, setIsLoadingQuery] = useState(false);

  useEffect(() => {
    if (!searchQuery) return;
    fetchData(1, debouncedSearchQuery);
  }, [debouncedSearchQuery]);

  useEffect(() => {
    setColumns(columns_user);
    fetchData(currentPage, formData);
    fetchChannels().then();
  }, []);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    tableInstance;

  const startLoading = () => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: true,
      allowEscapeKey: false,
      showConfirmButton: false,
      icon: "info",
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
  };

  const fetchChannels = async () => {
    try {
      const data = await getChannels();
      setChannels(data);
    } catch (error) {
      console.error("Error fetching channels:", error);
      handleError(error);
    }
  };

  const fetchData = (pageIndex, formData,giveAll=false) => {
    setSearchQueryError("");
    if (pageIndex < 1) return;
    if (searchQuery === "" || giveAll) {
      startLoading();
    } else {
      setIsLoadingQuery(true);
    }
    const filteredFormData = Object.fromEntries(
      Object.entries(formData).filter(
        ([key, value]) => value !== undefined && value !== ""
      )
    );
    searchUserCall({
      ...filteredFormData,
      page: pageIndex,
      size: 10,
      query: giveAll ? "" : searchQuery,
    })
      .then((result) => {
        Swal.close();
        setIsLoadingQuery(false);
        setData(result.items);
        setCount(result.count);
        setTotalPages(result.totalPages);
        setCurrentPage(result.page);
        setTargetPage(result.page + 1);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      })
      .catch((error) => {
        setIsLoadingQuery(false);
        Swal.close();
        setSearchQueryError(
          error?.response?.data?.statusCode === 500 && "Internal Server error"
        );
        handleError(error);
      });
  };

  const handleExtensiveSearch = (formData) => {
    fetchData(1, formData);
  };

  return (
    <>
      <Fragment>
        <div className="row justify-content-center">
          <div className="col-xl-12 col-xxl-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title w-50">Search user</h4>
                <h4
                  className="card-title text-primary"
                  style={{ width: "90%" }}
                >
                  Search Result ({count})
                </h4>
              </div>
              <div className="card-body">
                <div className="d-flex flex-col">
                  <div className="left-main col-4">
                    <div className="d-flex justify-content-between mb-4">
                      {/* <Button
                        text="Filter Search"
                        variant="pink"
                        data-toggle="modal"
                        onClick={() => setExtensiveSearch(!ExtensiveSearch)}
                      />
                      <Button
                        text="Extensive Search"
                        variant="pink"
                        data-toggle="modal"
                        onClick={() => setExtensiveSearch(!ExtensiveSearch)}
                      /> */}
                    </div>
                    {/* {ExtensiveSearch ? ( */}
                    <ExtensiveSearchForm
                      handleExtensiveSearch={handleExtensiveSearch}
                      startLoading={startLoading}
                      channels={channels}
                      searchQuery={searchQuery}
                    />
                    {/* ) : ( */}
                    {/* <UserSearchForm
                        channels={channels}
                        cities={cities}
                        countries={countries}
                        educationInstitutions={educationInstitutions}
                        handleSearchSubmit={handleSearchSubmit}
                      /> */}
                    {/* )} */}
                  </div>
                  <div
                    style={{
                      width: "0.5px",
                      height: "900px",
                      backgroundColor: "lightgray",
                      padding: "1px",
                      margin: "13px",
                    }}
                  ></div>{" "}
                  <div className="right-main col-8 mt-4">
                    <div className="d-flex row">
                      <div className={`d-flex flex-column mb-2`}>
                        <label>{"Search"}</label>
                        <input
                          value={searchQuery}
                          onChange={(e) => {
                            if(e.target.value === "") {
                              fetchData(1,formData,true)
                            }
                            setSearchQuery(e.target.value);
                          }}
                          className={`bg-beige rounded form-control border-1 w-50`}
                          style={{ height: "51px" }}
                        />
                        <div style={{ paddingLeft: "4px" }}>
                          <p className="mt-2 pl-4">{searchQueryError}</p>
                        </div>
                      </div>

                      {isLoadingQuery && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "15px",
                            width: "100px",
                          }}
                        >
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <table
                      {...getTableProps()}
                      className="table dataTable display"
                    >
                      <thead>
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th {...column.getHeaderProps()}>
                                {column.render("Header")}
                                {column.canFilter
                                  ? column.render("Filter")
                                  : null}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()} className="">
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <tr
                              {...row.getRowProps()}
                              onClick={() => {
                                const userId = row.original.id;
                                history.push({
                                  pathname: `/user/${userId}`,
                                });
                              }}
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              {row.cells.map((cell) => {
                                return (
                                  <td {...cell.getCellProps()}>
                                    {" "}
                                    {cell.render("Cell")}{" "}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <div
                        className="d-flex justify-content-between "
                        style={{marginTop: "70px"}}
                    >
                      <span>
                        Page{" "}
                        <strong>
                          {data.length === 0
                              ? "0 of 0"
                              : currentPage + " of " + totalPages}
                        </strong>
                        {""}
                      </span>
                      <span className="table-index d-none">
                        Go to page :{}
                        <input
                            type="number"
                            className="ml-2"
                            value={targetPage}
                            onChange={(e) => {
                              setTargetPage(e.target.value);
                              fetchData(Number(e.target.value), formData);
                            }}
                        />
                      </span>
                    </div>
                    <div className="text-center mb-3">
                      <div className="filter-pagination  mt-3">
                        <button
                          className=" previous-button"
                          onClick={() => {
                            if (data.length === 0) return;

                            fetchData(1, formData);
                          }}
                          disabled={currentPage === 1 || data.length === 0}
                        >
                          {"<<"}
                        </button>

                        <button
                          className="previous-button"
                          onClick={() => {
                            if (data.length === 0) return;
                            fetchData(currentPage - 1, formData);
                          }}
                          disabled={currentPage === 1 || data.length === 0}
                        >
                          Previous
                        </button>
                        <button
                          className="next-button"
                          onClick={() => {
                            if (data.length === 0) return;
                            fetchData(targetPage, formData);
                          }}
                          disabled={
                            currentPage === totalPages || data.length === 0
                          }
                        >
                          Next
                        </button>
                        <button
                          className=" next-button"
                          onClick={() => {
                            if (data.length === 0) return;
                            fetchData(totalPages, formData);
                          }}
                          disabled={
                            currentPage === totalPages || data.length === 0
                          }
                        >
                          {">>"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
}
