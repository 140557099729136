const Divider = () => {
  return (
    <div
      className=""
      style={{ width: "100%", height: "1px", backgroundColor: "lightgray" }}
    ></div>
  );
};


export default Divider