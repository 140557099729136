import { UPDATE_SEARCH_USER_DATA, UPDATE_USER_DATA } from "./actions/storeActions";
import { combineReducers } from "redux";

const userData = (val = null, action) => {
  switch (action.type) {
    case UPDATE_USER_DATA:
      return action.val;
    default:
      return val;
  }
};

const initialState = {
  formData: {
    firstName: "",
    lastName: "",
    email: "",
    studentEmail: "",
    phoneNumber: "",
    knaekID: "",
    mandateNumber: "",
    iban: "",
    creditCard: "",
    accountOwner: "",
    channelName: "",
    extensiveSearch: false,
  },
};

const formData = (state = initialState.formData, action) => {
  switch (action.type) {
    case UPDATE_SEARCH_USER_DATA:
      return action.data;
    default:
      return state;
  }
};

export default combineReducers({ userData, formData });
