import { useState } from "react";
import DatePicker from "react-datepicker"; // Import DatePicker

const FormField = ({
  label,
  name,
  value,
  onChange,
  type = "text",
  options = [],
  required = false,
  className,
  selectWidth,
  minDate,
  maxDate,
  disabled = false,
}) => {
  const [editableData, setEditableData] = useState(value);

  // Fix state variable name
  const [
    selectedDatePicker,
    setSelectedDatePicker,
  ] = useState(type === "date" && value ? new Date(value) : null);

  const handleContentChange = (event) => {
    if (type === "date") {
      // Check if event is a Date object
      if (event instanceof Date) {
        setEditableData(event.toISOString());
      } else {
        setEditableData(event);
      }
    } else {
      setEditableData(event.target.value);
    }
  };

  return (
    <div className={`form-group mb-3 col-sm-10 ${className}`}>
      <label className="text-black font-w500">{label}</label>
      <div className="contact-name">
        {type === "select" ? (
          <select
            className="form-select"
            style={{
              padding: "14px",
              width: selectWidth,
            }}
            id={name}
            onChange={(e) => {
              handleContentChange(e);
              onChange(label, e.target.value);
            }}
            disabled={disabled}
            value={editableData}
          >
            <option value="" selected>{`Select ${label}`}</option> {/* Set empty value for default option */}
            {options.map((option) =>
              (
              <option key={option.id} value={option.id} selected={option.name === editableData}>
                {option.name}
              </option>
            ))}
          </select>
        ) : type === "date" ? (
          <DatePicker
            className="form-control"
            dateFormat="dd-MM-yyyy"
            selected={selectedDatePicker}
            onChange={(val) => {
              setSelectedDatePicker(val);
              handleContentChange(val);
              onChange(label, val.toISOString());
            }}
            maxDate={maxDate}
            minDate={minDate}
            disabled={disabled}
          />
        ) : (
          <input
            type={type}
            className="form-control"
            name={name}
            value={editableData}
            onChange={(e) => {
              if (disabled) return;
              handleContentChange(e);
              onChange(label, e.target.value);
            }}
            required={required}
            disabled={disabled}
          />
        )}
      </div>
    </div>
  );
};

export default FormField;
