// export const UPDATE_IN_STORE_ACTION = 'UPDATE_IN_STORE_ACTION';
// export const UPDATE_ONLINE_ACTION = 'UPDATE_ONLINE_ACTION';
// export const UPDATE_LOCATIONS = 'UPDATE_LOCATIONS';
// export const UPDATE_CITIES = 'UPDATE_CITIES';
// export const UPDATE_ONLINE_FAVORITES = 'UPDATE_ONLINE_FAVORITES';
// export const UPDATE_IN_STORE_FAVORITES = 'UPDATE_IN_STORE_FAVORITES';
// export const UPDATE_PROFILE = 'UPDATE_PROFILE';
// export const UPDATE_SELECTED_IN_STORE = 'UPDATE_SELECTED_IN_STORE';
// export const UPDATE_SELECTED_ONLINE = 'UPDATE_SELECTED_ONLINE';
// export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';
// export const UPDATE_SELECTED_CITY_FILTER = 'UPDATE_SELECTED_CITY_FILTER';
// export const UPDATE_BACK_FROM_DISCOUNT = 'UPDATE_BACK_FROM_DISCOUNT';
// export const UPDATE_USES_LEFT_OFFLINE = 'UPDATE_USES_LEFT_OFFLINE';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const UPDATE_SEARCH_USER_DATA = 'UPDATE_FORM_DATA';


// export const updateInStoreActionsRS = (list) => ({ type: UPDATE_IN_STORE_ACTION, list });
// export const updateOnlineActionsRS = (list) => ({ type: UPDATE_ONLINE_ACTION, list });
// export const updateLocationsRS = (list) => ({ type: UPDATE_LOCATIONS, list });
// export const updateCitiesRS = (list) => ({ type: UPDATE_CITIES, list });
// export const updateOnlineFavoritesRS = (list) => ({ type: UPDATE_ONLINE_FAVORITES, list });
// export const updateInStoreFavoritesRS = (list) => ({ type: UPDATE_IN_STORE_FAVORITES, list });
// export const updateProfileRS = (val) => ({ type: UPDATE_PROFILE, val });
// export const updateSelectedInStoreDiscountRS = (val) => ({ type: UPDATE_SELECTED_IN_STORE, val });
// export const updateSelectedOnlineDiscountRS = (val) => ({ type: UPDATE_SELECTED_ONLINE, val });
// export const updateLanguageRS = (val) => ({ type: UPDATE_LANGUAGE, val });
// export const updateSelectedCityFilterRS = (val) => ({ type: UPDATE_SELECTED_CITY_FILTER, val });
// export const updateBackFromDiscountRS = (val) => ({ type: UPDATE_BACK_FROM_DISCOUNT, val });
// export const updateUsesLeftOfflineRS = (val) => ({ type: UPDATE_USES_LEFT_OFFLINE, val });
export const updateUserData = (val) => ({ type: UPDATE_USER_DATA, val });

export const updateSearchUserData = (data) => ({
  type: UPDATE_SEARCH_USER_DATA,
  data
});