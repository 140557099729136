import React, {useEffect, useState} from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {Modal} from "react-bootstrap";
import {addNewVouchersCallWithCsv} from "../../../Api/ApiCalls";
import {handleError, hasAValue} from "../../../utils/SharedFunctions";
import {toast} from "react-toastify";
import { toastOptions } from "../../../config/Constants";
import { Alert } from "react-bootstrap";

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export default function  UploadVoucherModal ({showUploadCouponsModal, setShowUploadCouponsModal, actionId}) {


  const [CouponCodes, setCouponCodes] = useState([]);
  const [duplicatedCodes, setDuplicatedCodes] = useState("");
  const [csvFile, setCsvFile] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const today = new Date();
  const todayString = today.toISOString().split('T')[0];


  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const validExtensions = ["csv"];
      const fileExtension = file.name.slice(
        ((file.name.lastIndexOf(".") - 1) >>> 0) + 2
      );
      if (validExtensions.includes(fileExtension.toLowerCase())) {
        setCsvFile(file);
      } else {
        toast.error("Invalid file extension. Please select a CSV file.");
      }
    }
  };

  function AddCoupons() {
    if (hasAValue(csvFile) && csvFile)
     {
      const validExtensions = ["csv"];
      const fileExtension = csvFile.name.slice(
        ((csvFile.name.lastIndexOf(".") - 1) >>> 0) + 2
      );

      if (validExtensions.includes(fileExtension.toLowerCase())) {
        const formData = new FormData();

        formData.append("file", csvFile);
        addNewVouchersCallWithCsv(formData, actionId)
          .then((success) => {
            toast.success("Vouchers received successfully");
            setShowUploadCouponsModal(false)
          })
          .catch((error) => {
            handleError(error);
            if (hasAValue(error.response.data.message)) {
              setDuplicatedCodes(error.response.data.message);
            }
          });
        } else {
          toast.error("Invalid file extension. Please select a CSV file.");
          return;
        }
    } else {
      let errorMessage = "Missing fields: Vouchers";
      toast.error(errorMessage);
    }
  }
  return (
      <>

          <Modal
              className="modal fade"
              size={"l"}
              show={showUploadCouponsModal}
              onHide={setShowUploadCouponsModal}
          >
              <div className="" role="document">
                  <div className="">
                      <form>
                            <div className="modal-header">
                                <h4 className="modal-title fs-20">
                                    Upload Vouchers
                                </h4>
                            </div>
                          <div className="modal-body">
                              <i className="flaticon-cancel-12 close"></i>
                              <div className="add-contact-box">
                                  <div className="add-contact-content">
                                      <div className={"row"}>

                                      <div className={`form-group mb-3`} style={{ width: "300px" }}>
                                            <label className="text-black font-w500">
                                              Upload CSV File Here
                                            </label>
                                            <input
                                              type="file"
                                              className="form-control"
                                              style={{height:"50px"}}
                                              accept=".csv"
                                              onChange={handleFileChange}
                                            />
                                          </div>
                                          <div className={`form-group mb-3`} style={{ width: "300px" }}>
                                            <span className="text-black font-w500">
                                             <a href="/Action_vouchers_template.csv" target="_blank" className={"text-primary"}>Download example</a>
                                            </span>
                                          </div>
                                          {duplicatedCodes.length > 0 && (
                                            <div className="col-12 mb-2">
                                              <Alert variant="light" className="solid alert-square">
                                                <strong>Duplicated Codes </strong>
                                                <br />
                                                <strong> {duplicatedCodes} </strong>
                                              </Alert>
                                            </div>
                                          )}
                                      </div>
                                  </div>
                              </div>
                          </div>
                            <div className="modal-footer">
                                <a className="btn btn-secondary" onClick={() => AddCoupons()}>
                                    Add
                                </a>
                                <a className="btn btn-warning" onClick={() => setShowUploadCouponsModal(false)}>
                                    Discard
                                </a>
                            </div>
                      </form>
                  </div>
              </div>
          </Modal>

      </>
  );

};
