import React from "react";
import Modal from "./Modal";
import { RenderMandateFileContent } from "../constant/modals";

const MandateModal = ({ id, ModalTrigger,src,width,height, setUserData, type }) => {
  return (
    <Modal
      id={id}
      setUserData={setUserData}
      ModalBody={<RenderMandateFileContent src={src} type={type} />}
      ModalTrigger={ModalTrigger}
      isImage={true}
      onlyOneButton={true}
      Theme="mandate_number"
      withHeader={false}
      contentWidth={"800px"}
    />
  );
};
export default MandateModal;
