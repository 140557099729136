export const personalDataLabels = [
  "First Name",
  "Email",
  "Phone Number",
  "Gender",
  "Last Name",
  "Student Email",
  "Birthdate",
];
export const studyLabels = ["Institution","Country","Education Level", "Student City"];
export const appLabel = ["App Status","Knaek-ID","App Language",  "Knaek-ID expiration date"];
export const accountDataLabel = [
  "Account owner",
  "Mandate number",
  "Bank account name",
  "BIC",
  "Channel",
  "Authorization reference",
  "IBAN",
  "Credit Card",
];

export const subscriptionLabel = [
  "Product",
  "Payment method",
  "Status",
  "Price",
  "Renew Product",
  "Commencing date",
  "Cancellation date",
  "Payment status",
  "Expiration date",
];

export const labelMappings = {
  "First Name": "firstName",
  "Country":"countryName",
  Email: "email",
  "Phone Number": "phoneNumber",
  "Country Code": "countryCode",
  Gender: "gender",
  "Last Name": "lastName",
  "Student Email": "studentEmail",
  Birthdate: "birthdate",
  "App Status": "appStatus",
  "App Language":"appLanguage",
  "Mandate number": "mandateNumber",
  Product: "productName",
  "Payment method": "paymentMethod",
  Status: "status",
  Price: "price",
  "Renew Product": "renewProductName",
  "Commencing date": "commencingDate",
  "Cancellation date": "cancellationDate",
  "Expiration date": "expirationDate",
  "Account owner": "accountOwner",
  "Authorization reference": "incassoReference",
  "BIC":"bic",
  "Credit Card":"creditCardNumber",
  "Institution":"institutionId",
  "Student City":"cityId",
  "IBAN":"iban",
  "Bank account name":"bankAccountName",
};

