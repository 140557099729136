// WhileListUserModal.js
import React from "react";
import Modal from "./Modal";
import { CanNotBrWhiteListed, WhiteListUser } from "../constant/modals";

const WhiteListUserModal = ({
  id,
  ModalTrigger,
  userData,
  canBeWhitelisted = true,
    setUserData,
}) => {
  return canBeWhitelisted ? (
    <Modal
      id={id}
      ModalTitle="Do you really want to whitelist this user?"
      ModalBody={<WhiteListUser />}
      ModalTrigger={ModalTrigger}
      Theme="whitelist_user"
      endpoint="whitelist_user"
      userData={userData}
      setUserData={setUserData}
    />
  ) : (
    <Modal
      id={id}
      ModalTitle="Not possible to whitelist a user with an active subscription!"
      ModalBody={<CanNotBrWhiteListed />}
      ModalTrigger={ModalTrigger}
      Theme="not_whitelist_user"
      endpoint=""
      userData={userData}
      onlyOneButton={true}
      setUserData={setUserData}
    />
  );
};

export default WhiteListUserModal;
