import React, { useRef, useState } from "react";
import InputField from "../../common/Input";
import { useSelector, useDispatch } from "react-redux";
import { updateSearchUserData } from "../../../../../redux/actions/storeActions";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Overlay } from "react-bootstrap";

const ExtensiveSearchForm = ({
  handleExtensiveSearch,
  channels,
  searchQuery,
}) => {
  const formData = useSelector((state) => state.formData);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const handleInputChange = (label, value) => {
    const updatedFormData = {
      ...formData,
      [label]: value,
    };
    dispatch(updateSearchUserData(updatedFormData));
  };

  const handleCheckboxChange = () => {
    const updatedFormData = {
      ...formData,
      extensiveSearch: !formData.extensiveSearch,
    };
    dispatch(updateSearchUserData(updatedFormData));
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleExtensiveSearch(formData);
  };

  return (
    <form className="form d-flex flex-column gap-3" onSubmit={handleFormSubmit}>
      <div className="d-flex gap-4 justify-content-between">
        <InputField
          className={"w-50"}
          label="First Name"
          value={formData.firstName}
          onChange={(e) => handleInputChange("firstName", e.target.value)}
        />
        <InputField
          className={"w-50"}
          label="Last Name"
          value={formData.lastName}
          onChange={(e) => handleInputChange("lastName", e.target.value)}
        />
      </div>
      <InputField
        label="Email"
        value={formData.email}
        onChange={(e) => handleInputChange("email", e.target.value)}
      />
      <InputField
        label="Student email"
        value={formData.studentEmail}
        onChange={(e) => handleInputChange("studentEmail", e.target.value)}
      />
      <InputField
        label="Phone number"
        value={formData.phoneNumber}
        onChange={(e) => handleInputChange("phoneNumber", e.target.value)}
      />
      <div className="d-flex gap-4 justify-content-between">
        <InputField
          className={"w-50"}
          label="Knaek-ID"
          value={formData.knaekID}
          onChange={(e) => handleInputChange("knaekID", e.target.value)}
        />
        <InputField
          className={"w-50"}
          label="Mandate number"
          value={formData.mandateNumber}
          onChange={(e) => handleInputChange("mandateNumber", e.target.value)}
        />
      </div>
      <InputField
        label="IBAN"
        value={formData.iban}
        onChange={(e) => handleInputChange("iban", e.target.value)}
      />
      <InputField
        label="Credit card"
        value={formData.creditCard}
        onChange={(e) => handleInputChange("creditCard", e.target.value)}
      />
      <div className="d-flex gap-4 justify-content-between">
        {/*Account owner*/}
        <div className="d-flex flex-column mb-2 w-50">
          <label>Account owner</label>
          <div className="contact-name">
            <select
              className={` rounded form-control border-1 ${formData?.accountOwner? "bg-search":"bg-beige"}`}
              name="user_type"
              onChange={(e) =>
                handleInputChange("accountOwner", e.target.value)
              }
            >
              <option value={""}>All</option>
              <option value={"Knaek_NL"}>Knaek NL</option>
              <option value={"Knaek_BE"}>Knaek BE</option>
            </select>
          </div>
        </div>
        {/*Account owner*/}
        <div className="d-flex flex-column mb-2 w-50">
          <label>Channel</label>
          <div className="contact-name">
            <select
              className={` rounded form-control border-1 ${formData?.channelName? "bg-search":"bg-beige"}`}
              name="user_type"
              onChange={(e) => handleInputChange("channelName", e.target.value)}
            >
              <option value={""}>All</option>
              <option value={"INTRO_PAGE"}>INTRO_PAGE</option>
              <option value={"ANALOGUE"}>ANALOGUE</option>
              <option value={"KNAEK_WEBSITE"}>KNAEK_WEBSITE</option>
            </select>
          </div>
        </div>
      </div>
      <div className="bottom d-flex">
        <div className="d-flex  align-items-center flex-start w-50 mt-3">
          <input
            type="checkbox"
            checked={formData.extensiveSearch}
            onChange={handleCheckboxChange}
          />
          <label className="mx-1 my-1">Extensive search</label>
          <div style={{}}>
            <Overlay target={target.current} show={show} placement="top">
              {({
                placement: _placement,
                arrowProps: _arrowProps,
                show: _show,
                popper: _popper,
                hasDoneInitialMeasure: _hasDoneInitialMeasure,
                ...props
              }) => (
                  <div
                      {...props}
                      style={{
                        position: "absolute",
                        backgroundColor: "#78706C ",
                        padding: "2px 10px",
                        marginBottom: "10px",
                        width: "180px",
                        fontSize: "9px",
                        textAlign: "center",
                        color: "white",
                        borderRadius: 5,
                        ...props.style,
                      }}
                  >
                    <h5 className={"text-white"}>Extensive Search shows old users and users without subscriptions.</h5>
                  </div>
              )}
            </Overlay>
            <svg
              ref={target}
              onMouseEnter={() => setShow(true)}
              onMouseLeave={() => setShow(false)}
              style={{ cursor: "pointer" }}
              width="13px"
              height="13px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="#78706c"
                d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"
              />
            </svg>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-3 w-50">
          <div className="text-end w-100 toolbar toolbar-bottom">
            <button
              className="btn btn-primary sw-btn-next w-75"
              type="submit"
              disabled={searchQuery !== ""}
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ExtensiveSearchForm;
