import React from 'react'

export const ButtonLoading = ({isLoading, text}) => {
  return (
    <>
      {isLoading ? 
        <div
          style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
          }}
        >
          <div className="spinner-border text-white" style={{width: "1.2rem", height: "1.2rem"}}></div>
        </div> : <span>{text}</span>
      }
    </>
  )
}