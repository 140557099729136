import {Redirect, Route, Switch} from "react-router-dom";
import Home from "../pages/internalPages/Home";
import DashboardDark from "../components/Dashboard/DashboardDark";
import Error400 from "../pages/ErrorPages/Error400";
import Error403 from "../pages/ErrorPages/Error403";
import Error404 from "../pages/ErrorPages/Error404";
import Error500 from "../pages/ErrorPages/Error500";
import Error503 from "../pages/ErrorPages/Error503";
import React from "react";
import Login from "../pages/externalPages/auth/Login";
import NewNotification from "../pages/internalPages/Notification/NewNotification";
import NotificationOverview from "../pages/internalPages/Notification/NotificationOverview";
import KnaekIdUpdate from "../pages/internalPages/KnaekId/KnaekIdUpdate";
import KnaekIdAdd from "../pages/internalPages/KnaekId/KnaekIdAdd";
import KnaekIdSearch from "../pages/internalPages/KnaekId/KnaekIdSearch";
import ActionsOverview from "../pages/internalPages/Actions/ActionsOverview";
import ActionsRatingChange from "../pages/internalPages/Actions/ActionsRatingChange";
import {hasAValue} from "../utils/SharedFunctions";
import EducationalInstitutionOverview
    from "../pages/internalPages/EducationalInstitution/EducationalInstitutionOverview";
import introductionEventsOverview from "../pages/internalPages/IntroductionEvents/IntroductionEventsOverview";
import Unsubscribe from "../pages/internalPages/Unscubscribe/Unsubscribe";
import UnsubscribeEventsOverview from "../pages/internalPages/Unscubscribe/UnsubscribeEventsOverview";
import MembershipRegistrationSystemUser
    from "../pages/internalPages/MembershipRegistrationSystemUser/MembershipRegistrationSystemUser";
import MembershipFormOrder from "../pages/internalPages/MembershipRegistrationSystemUser/MembershipFormOrder";
import Locations from "../pages/internalPages/Locations/Locations";
import SnipTechStore from "../pages/internalPages/SnipTechStroe/SnipTechStore";
import UserSearch from "../pages/internalPages/Users/UserSearch";
import Orders from "../pages/internalPages/Orders/Orders";
import {useSelector} from "react-redux";
import CountryCodes from "../pages/internalPages/CountryCodes/CountryCodes";
import ForgotPassword from "../pages/externalPages/auth/ForgotPassword";
import UserInfo from "../pages/internalPages/Users/UserInfo";
import AdminUsers from "../pages/internalPages/AdminUsers/AdminUsers";
import MandateNumber from "../pages/internalPages/MandateNumber/MandateNumber";
import Company from "../pages/internalPages/KnaekManager/Company";
import CompanyDetails from "../pages/internalPages/KnaekManager/CompanyDetails/CompanyDetails";
import Vouchers from "../pages/internalPages/KnaekManager/CompanyDetails/Vouchers";
import UsersSubscriptions from "../pages/internalPages/Users/UsersSubscriptions";


export function privatePages(){
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const userData = useSelector( state => state.userData );


    return(
        <Switch>
            {/*publicPages*/}
            {!hasAValue(userData) && <Route exact path='/' component={Login}/>}
            {!hasAValue(userData) && <Route path='/page-login' component={Login}/>}
            <Route path="/wachtwoord-vergeten/:token" component={ForgotPassword}/>

            {/*privatePages*/}
            {hasAValue(userData) && <Route path='/dashboard' component={Home}/>}
            {hasAValue(userData) &&<Route path='/dashboard-dark' component={DashboardDark}/>}
            {hasAValue(userData) &&<Route path='/page-error-400' component={Error400}/>}
            {hasAValue(userData) &&<Route path='/page-error-403' component={Error403}/>}
            {hasAValue(userData) &&<Route path='/page-error-404' component={Error404}/>}
            {hasAValue(userData) && <Route path='/page-error-500' component={Error500}/>}
            {hasAValue(userData) && <Route path='/page-error-503' component={Error503}/>}
            {hasAValue(userData) && <Route path='/new-notification' component={NewNotification}/>}
            {hasAValue(userData) && <Route path='/notification-overview' component={NotificationOverview}/>}
            {hasAValue(userData) && <Route path='/knaekid-extend' component={KnaekIdUpdate}/>}
            {hasAValue(userData) && <Route path='/knaekid-generate' component={KnaekIdAdd}/>}
            {hasAValue(userData) &&<Route path='/knaekid-search' component={KnaekIdSearch}/>}
            {hasAValue(userData) &&<Route path='/mandate-number' component={MandateNumber}/>}
            {hasAValue(userData) &&<Route path='/actions-overview' component={ActionsOverview}/>}
            {hasAValue(userData) && <Route path='/actions-extend' component={ActionsRatingChange}/>}
            {hasAValue(userData) &&<Route path='/educational-institution-overview' component={EducationalInstitutionOverview}/>}
            {hasAValue(userData) &&<Route path='/admin-overview' component={AdminUsers}/>}
            {hasAValue(userData) &&<Route path='/introduction-events-overview' component={introductionEventsOverview}/>}
            {hasAValue(userData) &&<Route path='/unsubscribe' component={Unsubscribe}/>}
            {hasAValue(userData) &&<Route path='/unsubscribe-events' component={UnsubscribeEventsOverview}/>}
            {hasAValue(userData) &&<Route path='/membership-registration-system-user' component={MembershipRegistrationSystemUser}/>}
            {hasAValue(userData) &&<Route path='/membership-form-order' component={MembershipFormOrder}/>}
            {hasAValue(userData) &&<Route path='/locations' component={Locations}/>}
            {hasAValue(userData) &&<Route path='/sniptech-stores' component={SnipTechStore}/>}
            {hasAValue(userData) &&<Route path='/knaek-manager' component={Company}/>}
            {hasAValue(userData) &&<Route path='/search-user' component={UserSearch}/>}
            {hasAValue(userData) && <Route path="/user/:userId" component={UserInfo} />}
            {hasAValue(userData) && <Route path="/company/:companyId" component={CompanyDetails} />}
            {hasAValue(userData) &&<Route path='/orders' component={Orders}/>}
            {hasAValue(userData) &&<Route path='/country-codes' component={CountryCodes}/>}
            {hasAValue(userData) && <Route path="/actions/vouchers/:actionId" component={Vouchers} />}
            {/*{hasAValue(userData) &&<Route path='/users-subscriptions' component={UsersSubscriptions}/>}*/}


            {!hasAValue(userData) && <Redirect  to="/"/>}
            {hasAValue(userData) && <Redirect to="/dashboard"/>}

        </Switch>
    )
}
