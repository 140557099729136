import React, {useEffect, useState} from "react";
import {useFilters, useGlobalFilter, usePagination, useSortBy, useTable,} from "react-table";
import {useParams} from "react-router-dom/cjs/react-router-dom";
import {vouchers_column_name} from "../../../../components/FilteringTable/Columns";
import {getVouchersByActionId,deleteActionvouchersCall,deleteAllActionvouchersCall} from "../../../../Api/ApiCalls";
import {handleError} from "../../../../utils/SharedFunctions";
import {ApiSearchField} from "../../../../components/ApiSearchField";
import Swal from "sweetalert2";
import {toast} from "react-toastify";

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export default function  ActionCardBody ({}) {


  const [columns, setColumns] = useState([]);
  const [actions, setActions] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [targetPage, setTargetPage] = useState(0);
  const { actionId } = useParams();
  const [checkedAll, setCheckedAll] = useState(false);
  const [selectedVouchers, setSelectedVouchers] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const today = new Date();
  const todayString = today.toISOString().split('T')[0];

  useEffect(() => {
    setColumns(vouchers_column_name);
    getCoupons(1, actionId);
  }, []);

  useEffect(() => {
    if (!searchQuery) {
      getCoupons(1, actionId);
      return;
    }
    getCoupons(currentPage, actionId);
  }, [debouncedSearchQuery]);



  const tableInstance = useTable(
    {
      columns,
      data: actions,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
  } = tableInstance;


function toggleCheckedRows  (row)  {
    const Vouchers = Object.assign([], selectedVouchers);
    if(Vouchers.includes(row.voucher))
    {
        let index = Vouchers.indexOf(row.voucher);
        if (index !== -1)
            Vouchers.splice(index, 1);
    }else
        Vouchers.push(row.voucher)
    setSelectedVouchers(Vouchers)
};

function checkAll(event){
  if( event.target.checked )
      setSelectedVouchers([])
  setCheckedAll( event.target.checked )
}

function handleDeleteResponce(response)
{

    if(response.length > 0)
        {
            let text = "Can't delete used vouchers: </br>"

            response.forEach((value, index) => {
                console.log(value)
                text = text + ' ' + value + ( ( index == response.length - 1 ) ? '' : ' -' )
            });
            Swal.fire({
                title: "Error",
                html: text,
                allowOutsideClick: true,
                allowEscapeKey: false,
                showConfirmButton: false,
                icon: "error"
              });
        }
        getCoupons(currentPage, actionId);
}

function deleteVouchers()
{
    if (!checkedAll)
    {
        if( selectedVouchers.length == 0 )
            toast.error('Please Select Vouchers to delete')
        else{
            deleteActionvouchersCall(actionId, {"vouchers": selectedVouchers})
            .then((result) => {
                handleDeleteResponce(result)
            })
            .catch((err) => {
                toast.error('An Error Occured')
            });
        }
    }else
        deleteAllActionvouchersCall(actionId)
        .then((result) => {
            handleDeleteResponce(result)
        })
        .catch((err) => {
            toast.error('An Error Occured')
        });
}

  return (
      <>
          <div className="col-xl-12 col-xxl-12">
              <div className="card">

                  <div className="card-body">
                      <div className="table-responsive">
                          <div className={`d-flex mb-2 w-100`} style={{}}>
                                <ApiSearchField
                                  searchQuery={searchQuery}
                                  setSearchQuery={setSearchQuery}/>
                                <div className={`d-flex mb-2 align-items-center justify-content-end flex-grow-1`}>
                                    <div className="actions-dropdown">
                                        <div className="btn btn-secondary" onClick={(e) => deleteVouchers()}>
                                            Delete
                                        </div>
                                    </div>
                                </div>
                          </div>
                          {" "}
                          <table {...getTableProps()} className="table dataTable display">
                              <thead>
                              {headerGroups.map((headerGroup) => (
                                  <tr {...headerGroup.getHeaderGroupProps()}>
                                      {headerGroup.headers.map((column) => (
                                          <th {...column.getHeaderProps(
                                            //column.getSortByToggleProps()
                                            )}

                                            onClick={(e) =>
                                                column.id === "id" ? checkAll(e) : null
                                            }>
                                              {column.render("Header")}
                                              <span className="ml-1">

                                              </span>
                                          </th>
                                      ))}
                                  </tr>
                              ))}
                              </thead>
                              <tbody {...getTableBodyProps()}>
                              {page.map((row) => {
                                  prepareRow(row);
                                  return (
                                      <tr {...row.getRowProps()}

                                        onClick={(e) =>
                                            toggleCheckedRows(row.original)
                                        }>
                                          {row.cells.map((cell, index) => {
                                              return (
                                                  <td
                                                      key={index}
                                                      {...cell.getCellProps()}
                                                      className={"cursor-pointer"}
                                                  >
                                                        {cell.render("Cell", { checkedAll : ( checkedAll || selectedVouchers.includes(row.original.voucher) ) ? true : false })}
                                                  </td>
                                              );
                                          })}
                                      </tr>
                                  );
                              })}
                              </tbody>
                          </table>
                          {/* This is only for footer if u require */}
                          <div className="d-flex justify-content-between">
            <span>
              Page{" "}
                <strong>
                {actions.length === 0
                    ? "0 of 0"
                    : currentPage + " of " + totalPages}
              </strong>
                {""}
            </span>
                              <span className="table-index d-none">
              Go to page :{" "}
                                  <input
                                      type="number"
                                      className="ml-2"
                                      value={targetPage}
                                      onChange={(e) => {
                                          setTargetPage(e.target.value);
                                          getCoupons(Number(e.target.value), actionId);
                                      }}
                                  />
            </span>
                          </div>
                          <div className="text-center mb-3">
                              <div className="filter-pagination  mt-3">
                                  <button
                                      className=" previous-button"
                                      onClick={() => {
                                          if (actions.length === 0) return;

                                          getCoupons(1, actionId);
                                      }}
                                      disabled={currentPage === 1 || actions.length === 0}
                                  >
                                      {"<<"}
                                  </button>

                                  <button
                                      className="previous-button"
                                      onClick={() => {
                                          if (actions.length === 0) return;
                                          getCoupons(currentPage - 1, actionId);
                                      }}
                                      disabled={currentPage === 1 || actions.length === 0}
                                  >
                                      Previous
                                  </button>
                                  <button
                                      className="next-button"
                                      onClick={() => {
                                          if (actions.length === 0) return;
                                          getCoupons(targetPage, actionId);
                                      }}
                                      disabled={currentPage === totalPages || actions.length === 0}
                                  >
                                      Next
                                  </button>
                                  <button
                                      className=" next-button"
                                      onClick={() => {
                                          if (actions.length === 0) return;
                                          getCoupons(totalPages, actionId);
                                      }}
                                      disabled={currentPage === totalPages || actions.length === 0}
                                  >
                                      {">>"}
                                  </button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

      </>
  );
    async function getCoupons(pageIndex, actionId) {
        try {
            const result = await getVouchersByActionId(pageIndex, 10, actionId);
            setActions(result.items);
            setTotalPages(result.totalPages);
            setCurrentPage(result.page);
            setTargetPage(result.page + 1);
        } catch (err) {
            console.log(err);
            handleError(err);
        }
    };

};
