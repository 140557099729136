import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from 'react-router-dom';
import  ThemeContext  from "./config/context/ThemeContext";
import {Provider} from "react-redux";
import {persistor, store} from "./redux/store";
import {PersistGate} from "redux-persist/integration/react";

ReactDOM.render(
	<React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter basename='/'>
                    <ThemeContext>
                        <App />
                    </ThemeContext>
                </BrowserRouter>
            </PersistGate>
        </Provider>
	</React.StrictMode>,
  document.getElementById("root")
);
