const InputField = ({ label, value, onChange, placeholder,type="text", className }) => (
  <div className={`d-flex flex-column mb-2 ${className}`}>
    <label>{label}</label>
    <input
      value={value}
      type={type}
      onChange={onChange}
      className={` rounded form-control border-1 ${value? "bg-search":"bg-beige"}`}
      placeholder={placeholder}
    />
  </div>
);

export default InputField;
