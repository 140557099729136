import React, {useEffect, useState, useRef} from "react";
import {useFilters, useGlobalFilter, usePagination, useSortBy, useTable,} from "react-table";
import {discounts_column_name, vouchers_column_name} from "../../../../components/FilteringTable/Columns";
import {useHistory} from "react-router-dom";
import {Modal} from "react-bootstrap";
import DatePicker from "react-datepicker";
import {compareDesc, format, isPast} from 'date-fns'
import {
    addDiscountForCompany,
    deleteDiscountById,
    getDiscountDetailById,
    getDiscountsByCompanyId,
    updateDiscountCall,
    getDiscountsByGroupId
} from "../../../../Api/ApiCalls";
import {handleError, getDiscountStatus} from "../../../../utils/SharedFunctions";
import {toast} from "react-toastify";
import AddVoucherModal from "../../../../components/Actions/Vouchers/AddVoucherModal";
import UploadVoucherModal from "../../../../components/Actions/Vouchers/UploadVoucherModal";
import RichTextEditor from "../../../../components/RichTextEditor";
import {MoreInfo} from "../../../../components/MoreInfo";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import swal from 'sweetalert'

import 'react-tabs/style/react-tabs.css';
import { DiscountType } from "../../../../config/Constants";
import { useHasChanged } from "../../../../hooks/usePrevious";
import equal from "fast-deep-equal";
import { TooltipMessagesTypes } from '../../../../enums/tooltipMessagesTypes';

const FormField = ({
                       label,
                       value,
                       onChange,
                       required = false,
                       type = "text",
                       width, maxDate, placeholder, textArea
                   }) => {
    return (
        <div
            className="form-group mb-3 col-sm-6"
            style={{
                width: width,
            }}
        >
            <label className="text-black font-w500">{label}</label>
            <div className="contact-name">
                {textArea ? <textarea
                    className="form-control w-full"
                    value={value}
                    onChange={onChange}
                    required={required}
                    placeholder={placeholder}
                    aria-multiline={true}
                    rows={6}
                /> : <input
                    className="form-control w-full"
                    value={value}
                    onChange={onChange}
                    required={required}
                    type={type}
                    max={maxDate}
                    placeholder={placeholder}
                />
                }
            </div>
        </div>
    );
};

function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}

export default function  Discounts ({companyId,groups, groupId, groupName, isSendStatistics}) {
    const initialFormData = {
        averageSaving: 0.00,
        amountOfCoupons: 0,
        categoryId: 0,
        price: 0,
        transactionFee: 0,
        minPurchasableVouchers: 0,
        maxPurchasableVouchers: 1,
        companyId: companyId,
        startDate: "",
        endDate: "",
        autoStart: true,
        isGeneralStudentDiscount: false,
        isUnLimited: false,
        canGenerateQrCode: false,
        qrCodeImage: null,
        externalUrl: "",
        groupId: groupId,
        localizations: [
            {
                lang: "nl",
                title: "",
                description: "",
                termsNConditions: "",
            },
            {
                lang: "en",
                title: "",
                description: "",
                termsNConditions: "",
            },
        ],
        usageLimitation: "once_day",
    }
    const [discountType, setDiscountType] = useState(DiscountType.COUPONS);
    const [columns, setColumns] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showCouponsModal, setShowCouponsModal] = useState(false);
    const [showUploadCouponsModal, setShowUploadCouponsModal] = useState(false);
    const [discountId, setDiscountId] = useState(0);
    const [discounts, setDiscounts] = useState([]);
    const [unfilteredDiscounts, setUnfilteredDiscounts] = useState([]);
    const [pageSize, setPageSize] = useState(25); // Default page size
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [targetPage, setTargetPage] = useState(0);
    const [showMore, setShowMore] = useState(false);
    const [formData, setFormData] = useState(initialFormData);
    const [formImageData, setFormImageData] = useState({
        qrCodeImage: null
    });
    const [prevCopounEndDate, setPrevCopounEndDate] = useState()

    const history = useHistory();
    const [searchQuery, setSearchQuery] = useState("");
    const debouncedSearchQuery = useDebounce(searchQuery, 1000);
    const today = new Date();
    const todayString = today.toISOString().split('T')[0];
    const qrCodeRef = useRef(null)

    useEffect(() => {
        setColumns(discounts_column_name);
    }, []);

    useEffect(() => {
        if(discountType === DiscountType.EXTERNAL_COUPON)
        {
            setFormData((prevState) => ({
                ...prevState,
                isUnLimited: true,
                amountOfCoupons: -1
            }));
        }
        else if(discountType === DiscountType.COUPONS){
            setFormData((prevState) => ({
                ...prevState,
                isUnLimited: prevState?.usageDetails?.totalCapacity === -1 ? true : false,
                amountOfCoupons: prevState?.usageDetails?.totalCapacity ?? 0
            }));
        }else{
            setFormData((prevState) => ({
                ...prevState,
                isUnLimited: false,
                amountOfCoupons: prevState?.usageDetails?.totalCapacity ?? 0
            }));
        }
        
    }, [discountType]);


    const tableInstance = useTable(
        {
            columns,
            data: discounts,
            initialState: {pageIndex: 0,  pageSize: pageSize },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
    } = tableInstance;

    useEffect(() => {
        if (!searchQuery) {
            getDiscounts(1, companyId, groupId);
            return;
        }
        getDiscounts(currentPage, companyId, groupId);
    }, [pageSize, debouncedSearchQuery]);
    
    const serverDiscountStateRef = useRef([]);
    const initialDiscountFormState = [initialFormData, DiscountType.COUPONS]
    const discountFormState = [formData, discountType]


    return (
        <>


            <div className="col-xl-12 col-xxl-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">{groupName} - Discounts</h4>
                        <a className="btn btn-secondary" onClick={() => openModalAdd()}>
                            + Add discount
                        </a>
                    </div>

                    <div className="card-body">
                        <div className="table-responsive">
                            <div className={`d-flex mb-2 w-100`} style={{}}>
                                {
                                    unfilteredDiscounts.length > 0 && (
                                        <div className={`d-flex mb-2 align-items-center justify-content-start flex-grow-1`}>
                                            <div className={ "actions-dropdown " + ( showMore ? 'disabled' : '' )}>
                                                <div className="btn btn-secondary" onClick={ (e) => { showPreviousDiscounts(true) }  }>
                                                    Show previous discounts
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                            </div>
                            <table {...getTableProps()} className="table dataTable display">
                                <thead>
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <th key={`${column.id}-${column.Header}`}>
                                                {column.render("Header")}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                {page.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell, index) => {
                                                return (
                                                    <td
                                                        key={`${row.original.id}-${index}`}
                                                        {...cell.getCellProps()}
                                                        className={"cursor-pointer"}
                                                        onClick={() => cell.column.Header === "Options" ? null : openModalEdit(row.original)}
                                                    >
                                                        {cell.column.Header === "Options" ?
                                                            <div className="actions-dropdown">
                                                                <div className="btn btn-primary">
                                                                    Options
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{width:"10px",marginLeft:"3px"}}><path fill="white" d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                                                                </div>
                                                                <ul>
                                                                    <li onClick={() => openModalDuplicate(row.original)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M64 464H288c8.8 0 16-7.2 16-16V384h48v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h64v48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16zM224 304H448c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16H224c-8.8 0-16 7.2-16 16V288c0 8.8 7.2 16 16 16zm-64-16V64c0-35.3 28.7-64 64-64H448c35.3 0 64 28.7 64 64V288c0 35.3-28.7 64-64 64H224c-35.3 0-64-28.7-64-64z"/></svg>
                                                                        Duplicate discount
                                                                    </li>
                                                                    {/* <li onClick={() => AddCoupon(row.original) }>
                                                                        Add Coupon
                                                                    </li> */}
                                                                    {row.original.type === 'VOUCHERS' && (
                                                                        <>
                                                                            <li onClick={() => UploadCoupon(row.original)}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M288 109.3V352c0 17.7-14.3 32-32 32s-32-14.3-32-32V109.3l-73.4 73.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l128-128c12.5-12.5 32.8-12.5 45.3 0l128 128c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L288 109.3zM64 352H192c0 35.3 28.7 64 64 64s64-28.7 64-64H448c35.3 0 64 28.7 64 64v32c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V416c0-35.3 28.7-64 64-64zM432 456a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"/></svg>
                                                                                Upload Vouchers
                                                                            </li>
                                                                            <li onClick={() => history.push("/actions/vouchers/" + row.original.id)}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M40 48C26.7 48 16 58.7 16 72v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24H40zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM16 232v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V232c0-13.3-10.7-24-24-24H40c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V392c0-13.3-10.7-24-24-24H40z"/></svg>
                                                                                Show Vouchers
                                                                            </li>
                                                                        </>
                                                                    )}
                                                                </ul>
                                                            </div>
                                                            :
                                                            cell.render("Cell")
                                                        }
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                            {/* This is only for footer if u require */}
                            <div className="d-flex justify-content-between">
            <span>
              Page{" "}
                <strong>
                {discounts.length === 0
                    ? "0 of 0"
                    : currentPage + " of " + totalPages}
              </strong>
                {""}
            </span>
                                <span className="table-index d-none">
              Go to page :{" "}
                                    <input
                                        type="number"
                                        className="ml-2"
                                        value={targetPage}
                                        onChange={(e) => {
                                            setTargetPage(e.target.value);
                                            getDiscounts(Number(e.target.value), companyId, groupId);
                                        }}
                                    />
            </span>
                                <div className="mx-2">
                                    <label>
                                        <select
                                            className={"form-select form-select-lg"}
                                            value={pageSize}
                                            onChange={(e) => {
                                                setPageSize(Number(e.target.value));
                                                setCurrentPage(1);
                                            }}
                                        >
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                            <div className="text-center mb-3">

                                <div className="filter-pagination  mt-3">
                                    <button
                                        className=" previous-button"
                                        onClick={() => {
                                            if (discounts.length === 0) return;

                                            getDiscounts(1, companyId, groupId);
                                        }}
                                        disabled={currentPage === 1 || discounts.length === 0}
                                    >
                                        {"<<"}
                                    </button>

                                    <button
                                        className="previous-button"
                                        onClick={() => {
                                            if (discounts.length === 0) return;
                                            getDiscounts(currentPage - 1, companyId, groupId);
                                        }}
                                        disabled={currentPage === 1 || discounts.length === 0}
                                    >
                                        Previous
                                    </button>
                                    <button
                                        className="next-button"
                                        onClick={() => {
                                            if (discounts.length === 0) return;
                                            getDiscounts(targetPage, companyId, groupId);
                                        }}
                                        disabled={currentPage === totalPages || discounts.length === 0}
                                    >
                                        Next
                                    </button>
                                    <button
                                        className=" next-button"
                                        onClick={() => {
                                            if (discounts.length === 0) return;
                                            getDiscounts(totalPages, companyId, groupId);
                                        }}
                                        disabled={currentPage === totalPages || discounts.length === 0}
                                    >
                                        {">>"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Modal
                className="modal fade"
                size={"xl"}
                show={showModal}
                onHide={confirmModal}
            >
                <div className="" role="document">
                    <div className="">
                        <form>
                            <div className="modal-header">
                                <h4 className="modal-title fs-20"> {showEdit ? "Update Discount" : "Add Discount"} </h4>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={() => confirmModal()}
                                    data-dismiss="modal"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <i className="flaticon-cancel-12 close"></i>
                                <div className="add-contact-box">
                                    <div className="add-contact-content">
                                        <div className={"row"}>
                                            <div className="form-group mb-3 col-sm-12 col-md-12">
                                                <div className={"row"}>

                                                    <section className={"form-group mb-3 col-sm-12 col-md-12 align-content-center"}>
                                                        <strong className="text-black font-weight-bold">
                                                            Discount type
                                                        </strong>
                                                    </section>


                                                    <section className={"form-group mb-3 col-sm-4 col-md-4 cursor-pointer"}
                                                             onClick={() => setDiscountType(DiscountType.COUPONS)}>
                                                        <div
                                                            className={`card cursor-pointer ${discountType === DiscountType.COUPONS ? "border-2 border-info" : ""}`}>
                                                            <div className="card-body cursor-pointer">
                                                                <h3 className="text-primary font-w500 cursor-pointer">
                                                                    Coupons
                                                                </h3>
                                                                <label className="text-black font-w500 cursor-pointer">
                                                                    Default for all the app discounts.
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </section>

                                                    <section className={"form-group mb-3 col-sm-4 col-md-4 cursor-pointer"}
                                                             onClick={() => setDiscountType(DiscountType.VOUCHERS)}>
                                                        <div
                                                            className={`card cursor-pointer ${discountType === DiscountType.VOUCHERS ? "border-2 border-info" : ""}`}>
                                                            <div className="card-body cursor-pointer">
                                                                <h3 className="text-primary font-w500">
                                                                    Vouchers
                                                                </h3>
                                                                <label className="text-black font-w500 cursor-pointer">
                                                                    Using vouchers type will activate the webshop and
                                                                    the user should go to the webshop to buy voucher.
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </section>

                                                    <section className={"form-group mb-3 col-sm-4 col-md-4 cursor-pointer"}
                                                            onClick={() => setDiscountType(DiscountType.EXTERNAL_COUPON)}>
                                                        <div
                                                            className={`card cursor-pointer ${discountType === DiscountType.EXTERNAL_COUPON ? "border-2 border-info" : ""}`}>
                                                            <div className="card-body cursor-pointer">
                                                                <h3 className="text-primary font-w500">
                                                                    External Url
                                                                </h3>
                                                                <label className="text-black font-w500 cursor-pointer">
                                                                    Default for all the app discounts.
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </section>

                                                </div>

                                            </div>

                                            {formData.localizations.map((localization, index) => (
                                                <div className="w-50" key={index}>
                                                    <h4
                                                        style={{
                                                            textAlign: "center",
                                                            padding: "10px",
                                                            fontSize: "25px",
                                                        }}
                                                    >
                                                        {localization.lang.includes("nl") ? "Dutch" : "English"}
                                                    </h4>
                                                    <FormField
                                                        label="Title"
                                                        placeholder={localization.lang.includes("nl") ? "10% korting op alles" : "10% discount on everything"}
                                                        value={localization.title}
                                                        onChange={(e) =>
                                                            handleInputChange(e, "title", e.target.value, index)
                                                        }
                                                        required={true}
                                                        width="100%"
                                                    />
                                                    <FormField
                                                        label="Description (app)"
                                                        placeholder={localization.lang.includes("nl") ? "Kom langs in de winkel voor servies, tosti-ijzers, alles voor de schoonmaak en nog veel meer." : "Shop tableware, grills, everything for cleaning and lots more."}
                                                        value={localization.description}
                                                        onChange={(e) =>
                                                            handleInputChange(e, "description", e.target.value, index)
                                                        }
                                                        required={true}
                                                        width="100%"
                                                        textArea={true}
                                                    />
                                                    {discountType === DiscountType.VOUCHERS && (
                                                        <RichTextEditor
                                                            key={`RTE-${index}`}
                                                            label="Conditions (email)"
                                                            onChange={(value) => handleEditorChange(value, index)}
                                                            initialContent={localization.termsNConditions}
                                                        />
                                                    )}

                                                </div>
                                            ))}
                                            <h3 className={"text-primary mt-3"}>Configuration</h3>

                                            { formData.id && (
                                                <div className="form-group mb-3 col-sm-4 col-md-4 mt-3 text-center">
                                                    <div className="form-group">
                                                        <label className="text-primary font-w600">
                                                            Total { discountType === DiscountType.VOUCHERS ? 'Vouchers' : 'Coupons' } : { formData?.usageDetails?.totalCapacity < 0 ? <i className="text-danger la la-infinity "></i> : formData?.usageDetails?.totalCapacity}
                                                        </label>
                                                    </div>
                                                </div>
                                            )}
                                            { formData.id && (
                                                <div className="form-group mb-3 col-sm-4 col-md-4 mt-3 text-center">
                                                    <div className="form-group">
                                                        <label className="text-primary font-w600">
                                                            Used { discountType === DiscountType.VOUCHERS ? 'Vouchers' : 'Coupons' } : { formData?.usageDetails?.totalUsage }
                                                        </label>
                                                    </div>
                                                </div>
                                            )}
                                            { formData.id && (
                                                <div className="form-group mb-3 col-sm-4 col-md-4 mt-3 text-center">
                                                    <div className="form-group">
                                                        <label className="text-primary font-w600">
                                                            Remaining { discountType === DiscountType.VOUCHERS ? 'Vouchers' : 'Coupons' } : { formData?.usageDetails?.totalCapacity < 0 ? <i className="text-danger la la-infinity "></i> : formData?.usageDetails?.totalRemaining}
                                                        </label>
                                                    </div>
                                                </div>
                                            )}

                                            {/*Average Saving*/}
                                            <div className="form-group mb-3 col-sm-10 col-md-6">
                                                <label className="text-black font-w500">
                                                    Average Saving
                                                </label>
                                                <div className="contact-name">
                                                    <input
                                                        type={"number"}
                                                        min={0.01}
                                                        className="form-control"
                                                        name="averageSaving"
                                                        required="required"
                                                        value={formData.averageSaving}
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                "averageSaving",
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            {/*Limitation mode*/}
                                            <div className="form-group mb-3 col-sm-10 col-md-6">
                                                <label className="text-black font-w500">
                                                    Limitation mode
                                                </label>
                                                <select
                                                    required
                                                    value={formData?.usageLimitation}
                                                    className="form-control form-control-md"
                                                    onChange={(e) =>
                                                        handleInputChange(
                                                            e,
                                                            "usageLimitation",
                                                            e.target.value
                                                        )
                                                    }
                                                >
                                                    <option value={"once_day"}>{"Once a day"}</option>
                                                    <option value={"single_use"}>{"Single Use"}</option>
                                                </select>
                                            </div>

                                            {/*Start date*/}
                                            <div className="form-group mb-3 col-sm-10 col-md-6">
                                                <label className="text-black font-w500">
                                                    Start date
                                                </label>
                                                <div className="contact-name">
                                                    <DatePicker
                                                        className="form-control"
                                                        dateFormat="dd-MM-yyyy"
                                                        selected={formData.startDate ? new Date(formData.startDate) : ""}
                                                        onChange={(val) => {
                                                            handleDateChange("startDate",val)
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            {/*End date*/}
                                            <div className="form-group mb-3 col-sm-10 col-md-6">
                                                <label className="text-black font-w500">
                                                    End date
                                                </label>
                                                <div className="contact-name">
                                                    <DatePicker
                                                        className="form-control"
                                                        dateFormat="dd-MM-yyyy"
                                                        selected={formData.endDate ? new Date(formData.endDate) : ""}
                                                        onChange={(val) => {
                                                            handleDateChange(
                                                                "endDate",
                                                                val
                                                            )
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            {/*Limited coupons*/}
                                            {discountType !== DiscountType.VOUCHERS && <div className="form-group mb-3 col-sm-10 col-md-6">
                                                <label className="text-black font-w500 d-flex align-items-center">
                                                    Limited coupons? (<i
                                                    className={"text-danger la la-infinity "}></i> =
                                                        <input
                                                            disabled={discountType === DiscountType.EXTERNAL_COUPON}
                                                            type="checkbox"
                                                            name="isUnLimited"
                                                            checked={formData.isUnLimited}
                                                            onChange={(e) => { handleInputChange(e, "isUnLimited", e.target.checked); handleLimitedCoupons(e) }}
                                                            className="form-check-input cursor-pointer limited-coupons-checkbox"
                                                        />)
                                                </label>
                                                <div className="input-group mb-3">
                                                    { formData.isUnLimited ?
                                                        <div className="disabled-field">
                                                            <i className={"text-danger la la-infinity "}></i>
                                                        </div>
                                                        :
                                                        <input
                                                            type="number"
                                                            min={0}
                                                            disabled={formData.isUnLimited}
                                                            className="form-control"
                                                            name="amountOfCoupons"
                                                            required="required"
                                                            value={formData.amountOfCoupons}
                                                            onChange={(e) => handleInputChange(e, "amountOfCoupons", e.target.value)}
                                                            onBlur={validateAmountOfCoupons}
                                                        />
                                                    }
                                                </div>

                                            </div>}

                                            {/*Min purchasable voucher*/}
                                            {discountType == DiscountType.VOUCHERS &&  <div className="form-group mb-3 col-sm-10 col-md-6">
                                                <label className="text-black font-w500">
                                                    Min purchasable voucher
                                                </label>
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="number"
                                                        min={0}
                                                        className="form-control"
                                                        name="minPurchasableVouchers"
                                                        required="required"
                                                        value={formData?.minPurchasableVouchers}
                                                        onChange={(e) => handleInputChange(e, "minPurchasableVouchers", e.target.value)}
                                                    />
                                                </div>
                                            </div>}

                                            {/*Max purchasable voucher*/}
                                            {discountType == DiscountType.VOUCHERS &&  <div className="form-group mb-3 col-sm-10 col-md-6">
                                                <label className="text-black font-w500">
                                                    Max purchasable voucher
                                                </label>
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="number"
                                                        min={0}
                                                        className="form-control"
                                                        name="maxPurchasableVouchers"
                                                        required="required"
                                                        value={formData?.maxPurchasableVouchers}
                                                        onChange={(e) => handleInputChange(e, "maxPurchasableVouchers", e.target.value)}
                                                    />
                                                </div>
                                            </div>}

                                            {/*Price*/}
                                            {discountType == DiscountType.VOUCHERS &&  <div className="form-group mb-3 col-sm-10 col-md-6">
                                                <label className="text-black font-w500">
                                                    Price
                                                </label>
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="number"
                                                        min={0}
                                                        className="form-control"
                                                        name="price"
                                                        required="required"
                                                        value={formData.price}
                                                        onChange={(e) => handleInputChange(e, "price", e.target.value)}
                                                    />
                                                </div>
                                            </div>}

                                            {/*Transaction Fee*/}
                                            {discountType == DiscountType.VOUCHERS &&
                                            <div className="form-group mb-3 col-sm-10 col-md-6">
                                                <label className="text-black font-w500">
                                                Transaction Fee <span className="text-primary">(Ideal: €0.32 | Bancontact: €0.39)</span>
                                                </label>
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="number"
                                                        min={0}
                                                        className="form-control"
                                                        name="transactionFee"
                                                        required="required"
                                                        value={formData.transactionFee}
                                                        onChange={(e) => handleInputChange(e, "transactionFee", e.target.value)}
                                                    />
                                                </div>
                                            </div>}

                                            <div className="form-group mb-3 col-sm-10 col-md-6">
                                                <label className="text-black font-w500">
                                                    Group
                                                </label>
                                                <select
                                                    required
                                                    value={formData?.groupId}
                                                    className="form-control form-control-md"
                                                    onChange={(e) =>
                                                        handleInputChange(
                                                            e,
                                                            "groupId",
                                                            Number(e.target.value)
                                                        )
                                                    }
                                                >

                                                    {groups.map((group, index) => (
                                                            <option value={group.id}>{group.name}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            {/*External Url*/}
                                            {discountType == DiscountType.EXTERNAL_COUPON &&  <div className="form-group mb-3 col-sm-10 col-md-12">
                                                <label className="text-black font-w500">
                                                    External Url
                                                </label>
                                                <div className="input-group mb-3">
                                                    <input
                                                        className="form-control"
                                                        name="externalUrl"
                                                        required="required"
                                                        placeholder="http://www.example.com"
                                                        value={formData.externalUrl}
                                                        onChange={(e) => handleInputChange(e, "externalUrl", e.target.value)}
                                                    />
                                                </div>
                                            </div>}

                                            {/*Can this discount auto start?*/}
                                            {discountType !== DiscountType.VOUCHERS &&
                                                <div className="form-group mb-3 col-sm-10 col-md-8">
                                                    <div className="form-group mb-3">
                                                        <input
                                                            type="checkbox"
                                                            name="autoStart"
                                                            checked={formData.autoStart}
                                                            onChange={handleInputChange}
                                                            className="form-check-input cursor-pointer mt-0 me-2"
                                                        />
                                                        <label className="text-black font-w500 mb-0">
                                                            Can this discount auto start? <span className="text-primary">(Auto start when previous coupons have been used)</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            }
                                            
                                            {/*General Student Discount*/}
                                            <div className={`form-group mb-3 col-sm-10 col-md-4 d-flex justify-content-${(discountType !== DiscountType.VOUCHERS) ? `end` : `start`}`}>
                                                <div className={`form-group mb-3 d-flex align-items-${(discountType !== DiscountType.VOUCHERS) ? `center` : `end pb-1`}`}>
                                                    <input
                                                        type="checkbox"
                                                        name="isGeneralStudentDiscount"
                                                        checked={formData.isGeneralStudentDiscount}
                                                        onChange={handleInputChange}
                                                        className="form-check-input cursor-pointer mt-0 me-2"
                                                    />
                                                    <label className="text-black font-w500 mb-0">
                                                        General Student Discount
                                                    </label>
                                                        <MoreInfo
                                                            content={[TooltipMessagesTypes.GENERAL_STUDENT_DISCOUNT]}/>
                                                </div>
                                            </div>

                                            {/*Files*/}
                                            {discountType == DiscountType.COUPONS &&
                                                <>
                                                    <h3 className={"text-primary mt-3"}>Files</h3>


                                                    {/*QR/Bar Code*/}
                                                    <div className="form-group mb-3 col-sm-10 col-md-6">
                                                        <div className="row">
                                                            <div className="form-group mb-3 col-sm-12 col-md-12">
                                                                <label
                                                                    className="text-black font-w500 d-flex align-items-center justify-content-between">
                                                                    <div className="">
                                                                        QR/Bar code/Etc
                                                                        <MoreInfo
                                                                            content={[TooltipMessagesTypes.DIMENSIONS_250_250, TooltipMessagesTypes.FORMAT_JPG, TooltipMessagesTypes.SIZE_50]}/>
                                                                    </div>

                                                                    <div className="form-group">
                                                                        <input
                                                                            type="checkbox"
                                                                            name="canGenerateQrCode"
                                                                            checked={formData.canGenerateQrCode}
                                                                            onChange={(e) => handleCanGenerateQrCode(e)}
                                                                            className="form-check-input cursor-pointer mt-0 me-2"
                                                                        />
                                                                        <label className="text-black font-w500 mb-0">
                                                                            Display Knaek-ID as QR code
                                                                        </label>
                                                                    </div>

                                                                </label>
                                                                <div className="contact-name">
                                                                    <input
                                                                        type="file"
                                                                        className="form-control"
                                                                        name="qrCode"
                                                                        required={formData?.canGenerateQrCode && "required"}
                                                                        disabled={formData?.canGenerateQrCode}
                                                                        onChange={(e) => handleInputChangeFile(e)}
                                                                        ref={qrCodeRef}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="form-group mb-3 col-sm-12 col-md-12">
                                                                <div>
                                                                    <div>

                                                                        <div
                                                                            className="image-container p-1"
                                                                            style={{
                                                                                width: "250px",
                                                                                height: "250px",
                                                                                overflow: "hidden",
                                                                                border: "1px solid #ccc",
                                                                            }}
                                                                        >
                                                                        {
                                                                          formImageData?.qrCodeImage && (
                                                                            <img
                                                                                id="uploadedImage"
                                                                                src={formImageData?.qrCodeImage} // Use the preview URL here
                                                                                alt="Uploaded Image"
                                                                                style={{width: "100%", height: "100%"}}
                                                                            />
                                                                          )
                                                                        }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }


                                        </div>
                                    </div>
                                </div>
                            </div>
                            {!showEdit && (
                                <div className="modal-footer">
                                    <a className="btn btn-secondary" onClick={() => addDiscount()}>
                                        Add
                                    </a>
                                    <a className="btn btn-warning" onClick={() => confirmModal()}>
                                        Discard
                                    </a>
                                </div>
                            )}

                            {showEdit && (
                                <div className="modal-footer">
                                    <a className="btn btn-secondary" onClick={() => updateDiscount()}>
                                        Save
                                    </a>
                                    <a className="btn btn-warning" onClick={() => confirmModal()}>
                                        Cancel
                                    </a>
                                    <a className="btn btn-danger" onClick={() => deleteDiscount()}>
                                        Delete
                                    </a>
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            </Modal>

            <AddVoucherModal showCouponsModal={showCouponsModal} setShowCouponsModal={setShowCouponsModal} actionId={discountId} ></AddVoucherModal>
            <UploadVoucherModal showUploadCouponsModal={showUploadCouponsModal} setShowUploadCouponsModal={setShowUploadCouponsModal} actionId={discountId} ></UploadVoucherModal>

        </>
    );

    async function showPreviousDiscounts(showMore)
    {
        const Items = Object.assign([], unfilteredDiscounts);
        setDiscounts(Items)
        setShowMore(showMore)
    }

    async function UploadCoupon(discount)
    {
        setShowUploadCouponsModal(true)
        setDiscountId(discount.id)
    }

    async function getDiscounts(pageIndex, companyId, groupId, show = false) {
        try {
            const result = await getDiscountsByGroupId(pageIndex, pageSize, companyId, groupId, debouncedSearchQuery);

            let items = result.items

            setUnfilteredDiscounts(items)

            if(!show)
                items = items.filter( (item) =>  getDiscountStatus(item.startDate, item.endDate) != 'Expired')

            setDiscounts(items);
            setTotalPages(result.totalPages);
            setCurrentPage(result.page);
            setTargetPage(result.page + 1);
        } catch (err) {
            console.log(err);
            handleError(err);
        }
    };

    async function openModalAdd() {
        resetDiscountData()
        setShowModal(true);
        setShowEdit(false);
    };

    async function openModalDuplicate(element) {
        const result = await getDiscountDetailById(companyId, element.id);
        setShowModal(true);
        setShowEdit(false)
        
        const prevTotalCapacity = result.usageDetails?.totalCapacity
        const duplicateUsageDetails = {totalCapacity:prevTotalCapacity, totalRemaining:0, totalUsage:0}
        const duplicateIsUnLimited = prevTotalCapacity === -1 ? true: false;
        setFormData({
            autoStart: result.autoStart,
            isGeneralStudentDiscount: result.isGeneralStudentDiscount,
            id: result.id,
            averageSaving: result.averageSaving,
            amountOfCoupons: result.amountOfCoupons,
            minPurchasableVouchers: result.minPurchasableVouchers,
            maxPurchasableVouchers: result.maxPurchasableVouchers,
            price: result.price,
            transactionFee: result.transactionFee,
            canGenerateQrCode: result.canGenerateQrCode,
            companyId: companyId,
            startDate: result.startDate,
            endDate: result.endDate,
            usageLimitation: result.usageLimitation,
            externalUrl: result.externalUrl,
            usageDetails: duplicateUsageDetails,
            groupId: result.groupId,
            isUnLimited: duplicateIsUnLimited,
            localizations: [

                {
                    lang: "nl",
                    title: result.localization?.nl?.subtitle,
                    description: result.localization?.nl?.description,
                    termsNConditions: result.localization?.nl?.termsNConditions,
                },
                {
                    lang: "en",
                    title: result.localization?.en?.subtitle,
                    description: result.localization?.en?.description,
                    termsNConditions: result.localization?.en?.termsNConditions,
                },

            ],
        });
        setDiscountType(result.type)
        setFormImageData({
            qrCodeImage: result.qrCodeUrl
        })
    };

    async function openModalEdit(element) {
        const result = await getDiscountDetailById(companyId, element.id);
        setShowEdit(true);
        setShowModal(true);
        const discountDetails = {
            autoStart: result.autoStart,
            isGeneralStudentDiscount: result.isGeneralStudentDiscount,
            id: result.id,
            isUnLimited: result?.amountOfCoupons === -1,
            averageSaving: result.averageSaving,
            minPurchasableVouchers: result.minPurchasableVouchers,
            maxPurchasableVouchers: result.maxPurchasableVouchers,
            price: result.price,
            transactionFee: result.transactionFee,
            amountOfCoupons: result.amountOfCoupons,
            canGenerateQrCode: result.canGenerateQrCode,
            companyId: companyId,
            startDate: result.startDate,
            endDate: result.endDate,
            usageLimitation: result.usageLimitation,
            usageDetails: result.usageDetails,
            externalUrl: result.externalUrl,
            groupId: result.groupId,
            localizations: [
                {
                    lang: "nl",
                    title: result.localization?.nl?.subtitle,
                    description: result.localization?.nl?.description,
                    termsNConditions: result.localization?.nl?.termsNConditions,
                },
                {
                    lang: "en",
                    title: result.localization?.en?.subtitle,
                    description: result.localization?.en?.description,
                    termsNConditions: result.localization?.en?.termsNConditions,
                },

            ],
        }
        setFormData(discountDetails);
        setFormImageData({
            qrCodeImage: result.qrCodeUrl
        })

        setDiscountType(result.type)

        setPrevCopounEndDate(result.endDate)

        serverDiscountStateRef.current = [discountDetails, result.type]
    };


    async function handleEditorChange (value, index) {
        setFormData((prevState) => {
            return {
                ...prevState,
                localizations: prevState.localizations.map((loc, i) =>
                    i === index ? { ...loc, termsNConditions: value } : loc
                ),
            }
        });
    };

    async function handleInputChange(e, fieldName, value, index) {
        const {name, type, checked} = e.target;
        const update = {...formData, [name]: value};
        

        // if localization has changed
        if(index>=0){
            setFormData((prevState) => ({
                ...prevState,
                localizations: prevState.localizations.map((loc, i) =>
                    i === index ? {...loc, [fieldName]: value} : loc
                ),
            }));
            return
        }

        switch (type) {
            case "checkbox":
                setFormData({...update, [name]: checked});
                break;
            case "number":
                setFormData((prevState) => ({
                    ...prevState,
                    [fieldName]: Number(value),
                }));
                break;
            default:
                setFormData((prevState) => ({
                    ...prevState,
                    [fieldName]: value,
                }));
        }
    };

    async function handleDateChange(fieldName, value) {

        setFormData(prevFormData => ({
            ...prevFormData,
            [fieldName]: format(value, 'yyyy-MM-dd'),

        }));
    }
    async function handleInputChangeFile(e) {
        const {name, files} = e.target;
        const file = files[0];
        if (file) {
            if((file.size / (1024*1024)).toFixed(2) > .05)
            {
                toast.error("Size Must be equal or less than 50K")
                return false;
            }
            if(!['image/png', 'image/jpg', 'image/jpeg'].includes(file.type))
            {
                toast.error("Image must be of png or jpg format")
                return false;
            }
            const reader = new FileReader();

            // Function to run once the file is read
            reader.onload = function (upload) {
                var img = new Image;

                img.src = reader.result;

                img.onload = function() {
                    if(name == 'qrCode' && ( img.width != 250 || img.height != 250 ) )
                    {
                        toast.error("Image dimensions must be : 250 * 250")
                        return false;
                    }
                    // Set the state with the new image URL for preview
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        [`${name}Image`]: file,

                    }));

                    setFormImageData(prevFormData => ({
                        ...prevFormData,
                        [`${name}Image`]: upload.target.result,
                    }));
                };
            };

            // Read the file as a data URL (base64)
            reader.readAsDataURL(file);
        }
    }

    async function handleLimitedCoupons(e) {
        if(e.target.checked)
            {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    amountOfCoupons: -1,

                }));

            }else{

                setFormData(prevFormData => ({
                    ...prevFormData,
                    amountOfCoupons: 0,

                }));
            }
    }

    function validateAmountOfCoupons(event) {
        const usedCoupons = formData?.usageDetails?.totalUsage
        const isUnlimited = formData?.isUnLimited
        const amountOfCoupons = Number(event.target.value)
        const prevAmountOfCoupons = formData?.usageDetails?.totalCapacity
        
        if(!isUnlimited && amountOfCoupons < usedCoupons){
            swal('Not valid Amount', "Total amount of coupons can't be less than used amount of coupons.", 'error');
            setFormData(prevFormData => ({
                ...prevFormData,
                amountOfCoupons: prevAmountOfCoupons < 0 ? 0 : prevAmountOfCoupons,
            }));
        }
      }

    async function updateDiscount() {
        try {
            let formDataWithoutId = {...formData};
            delete formDataWithoutId.id;

            if(Number(formData.minPurchasableVouchers) > Number(formData.maxPurchasableVouchers)){
                toast.error('Min Purchasable Vouchers Can\'t be more than Max Purchasable coupons !')
                return false;
            }
            if(compareDesc(new Date(formData.startDate), new Date(formData.endDate)) === -1){ //return 1 if the first date is before the second, -1 if the first date is after the second or 0 if dates are equal.
                toast.error('Start Date Can\'t be after End date !')
                return false;
            }
            // manually end discount options
            if(isSendStatistics && !isPast(new Date(prevCopounEndDate)) && isPast(new Date(formData.endDate))){ 
                const isConfirmed = await swal({
                    title: "Are you sure?",
                    text: "Do you want a report with the results from this coupon promotion to be sent to the partner?",
                    icon: "warning",
                    buttons: {
                        cancel: true,
                        no :{
                            text: "No",
                            value: "no",
                            className: "swal-button--danger"
                        },
                        yes : {
                            text: "Yes",
                            value: "yes",
                            className: "swal-button--info"
                        },
                    },
                  }).then((value) => {
                    switch (value) {
                        case "yes":
                            formDataWithoutId.isSendManuallyEndedDiscountReport = true
                            return true;
                        case "no":
                            formDataWithoutId.isSendManuallyEndedDiscountReport = false
                            return true;
                        default:
                            return false;
                    }
                  })
             
                if(!isConfirmed) return false
            }
          
            if (discountType != DiscountType.EXTERNAL_COUPON){
                delete formDataWithoutId.externalUrl;
            }
            const formDataToSend = getDiscountDataToSend(formDataWithoutId)
            const result = await updateDiscountCall(formDataToSend, companyId, formData.id); // Sending formDataToSend to the API function
            setDiscounts((prevData) => {
                return prevData.map((item) => {
                    if (item?.id === result?.id) {
                        return {
                            ...item,
                            id: result.id,
                            averageSaving: result.averageSaving,
                            amountOfCoupons: result.amountOfCoupons,
                            isUnLimited: result.isUnLimited,
                            companyId: companyId,
                            startDate: result.startDate,
                            endDate: result.endDate,
                            usageLimitation: result.usageLimitation,
                            minPurchasableVouchers: result.minPurchasableVouchers,
                            maxPurchasableVouchers: result.maxPurchasableVouchers,
                            price: result.price,
                            localizations: [
                                {
                                    lang: "en",
                                    title: result.localization.en.subtitle,
                                    description: result.localization.en.description,
                                    termsNConditions: result.localization.en.termsNConditions,
                                },
                                {
                                    lang: "nl",
                                    title: result.localization.nl.subtitle,
                                    description: result.localization.nl.description,
                                    termsNConditions: result.localization.nl.termsNConditions,
                                },
                            ],
                        };
                    }
                    return item;
                });
            });
            setDiscountType(result.type)

            toast.success("Discount Updated successfully");
            await getDiscounts(currentPage, companyId, groupId);
            setShowModal(false);
        } catch (err) {
            handleError(err);
        }
    };


    async function addDiscount() {
        try {
            if(Number(formData.minPurchasableVouchers) > Number(formData.maxPurchasableVouchers)){
                toast.error('Min Purchasable Vouchers Can\'t be more than Max Purchasable coupons !')
                return false;
            }
            if(compareDesc(new Date(formData.startDate), new Date(formData.endDate)) === -1){ //return 1 if the first date is before the second, -1 if the first date is after the second or 0 if dates are equal.
                toast.error('Start Date Can\'t be after End date !')
                return false;
            }
            let formDataToSend = getDiscountDataToSend(formData)

            await addDiscountForCompany(formDataToSend, companyId); // Sending formDataToSend to the API function
            toast.success("Discount Added successfully");
            await getDiscounts(currentPage, companyId, groupId);
            setShowModal(false);

        } catch (err) {
            handleError(err);
        }
    };

    function getDiscountDataToSend(formData) {
        const formDataToSend = new FormData();
        Object.keys(formData).forEach((key) => {
            if (key === "localizations") {
                const localizationsArray = formData[key].map(localization => {
                    let localizationRecord = {
                        lang: localization.lang,
                        title: localization.title,
                        description: localization.description,
                    }

                    if(discountType == DiscountType.VOUCHERS)
                        localizationRecord.termsNConditions = localization.termsNConditions

                    return localizationRecord;
                });

                formDataToSend.append("localizations", JSON.stringify(localizationsArray));
            } else if(["startDate", "endDate"].includes(key)) {
                let newDate = new Date(formData[key])
                newDate = newDate.getFullYear() + '-' + ( newDate.getMonth() + 1 ) + '-' + newDate.getDate() + ' GMT';
                formDataToSend.append(key, newDate);
            }else if(key !== "backgroundImage"){
                formDataToSend.append(key, formData[key]);
            }
        });

        if (formData.isUnLimited) {
            formDataToSend.set('amountOfCoupons', -1);
        } else {
            formDataToSend.set('amountOfCoupons', formData.amountOfCoupons);
        }

        if (discountType != DiscountType.EXTERNAL_COUPON)
            formDataToSend.delete('externalUrl');

        formDataToSend.append('type', discountType)
        formDataToSend.set('localizations', JSON.stringify(formDataToSend.get('localizations')))
        return formDataToSend;
    }

    async function deleteDiscount() {
        try {
            await deleteDiscountById(companyId, formData.id);
            setDiscounts((prevDiscounts) =>
                prevDiscounts.filter((discount) => discount.id !== formData.id)
            );
            toast.success("Discount Deleted successfully");
            setShowModal(false);
            setShowEdit(false);
        } catch (err) {
            setShowModal(false);
            setShowEdit(false);
            handleError(err);
        }
    };

    function resetDiscountData() {
        setDiscountType(DiscountType.COUPONS)
        setFormImageData({qrCodeImage: null})
        setFormData(initialFormData)
    }

    function confirmModal(){
        if(!showEdit && equal(initialDiscountFormState, discountFormState)) {
            setShowModal(false)
            return
        }

        if(showEdit && equal(serverDiscountStateRef.current, discountFormState)) {
            setShowModal(false)
            return
        }

        swal({
            title: "Are you sure?",
            text: "You want to discard your changes?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((response) => {
            if (response) {
                setShowModal(false)
            }
          });
    }

    function handleCanGenerateQrCode(e){
        handleInputChange(e, "canGenerateQrCode", e.target.checked);
        if(e.target.checked){
            qrCodeRef.current.value = null
            setFormImageData(prevFormData => ({
                ...prevFormData,
                [`qrCodeImage`]: null,
            }));
            setFormData(prevFormData => ({
                ...prevFormData,
                [`qrCodeImage`]: null,

            }));
        }else{
            setFormData(prevFormData => {
                delete prevFormData.qrCodeImage
                return {
                    ...prevFormData,
                }
            });
        }
    }

};
