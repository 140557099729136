import React, {useEffect, useState} from "react";
import {appUrls} from "../../../config/Constants";
import {getNotificationsReciepentsCall} from "../../../Api/ApiCalls";
import loader from "../../../style/images/loader.gif";

export default function Step4({
                                  notificationItem,
                                  titleEn,
                                  bodyEn,
                                  titleNl,
                                  bodyNl,
                                  mode,
                                  type,
                                  userListForTestMode,
                                  goNextStep,
                                  goBack,
                                  saveNotification,
                                  selectedFiltersForProductionMode,
                                  finalRecipients
                              }) {
    const [receiversCount, setReceiversCount] = useState(null)
    const types = [
        {
            name: 'URL', value: 'url'
        },
        {
            name: 'instore', value: 'instore'
        },
        {
            name: 'Online', value: 'cashback'
        },
        {
            name: 'Jobs', value: 'jobs'
        },
    ]


    useEffect(() => {
        let body = selectedFiltersForProductionMode
        body.cityIds = [];
        body.countryId = selectedFiltersForProductionMode.country;
        selectedFiltersForProductionMode.cities.map(item => {
            body.cityIds.push(item.value)
        })
        getNotificationsReciepentsCall(removeEmptyValues(body)).then(result => {
            setReceiversCount(result.total_recipients)
        })
    }, [])
    const removeEmptyValues = (obj) => {
        return Object.fromEntries(
          Object.entries(obj).filter(([_, value]) =>
            value !== null &&
            value !== undefined &&
            value !== "" &&
            value !== 0 &&
            value !== false &&
            !(Array.isArray(value) && value.length === 0) &&
            !(typeof value === 'object' && !Array.isArray(value) && Object.keys(value).length === 0)
          )
        );
      };
    return (
        <>
            <section>
                <div className="row justify-content-center">
                    <div className="col-lg-6 mb-2 ">
                        <div className="card border-top shadow">
                            <div className="card-header border-0 pb-0">
                                <h2 className="card-title">Overview</h2>
                            </div>
                            <div className="card-body pb-0">

                                <ul className="list-group list-group-flush">
                                    {/*<li className="list-group-item d-flex px-0 justify-content-between">*/}
                                    {/*    <strong>Mode</strong>*/}
                                    {/*    <span className="mb-0">{mode}</span>*/}
                                    {/*</li>*/}
                                    {/*<li className="list-group-item d-flex px-0 justify-content-between">*/}
                                    {/*    <strong>Type</strong>*/}
                                    {/*    <span className="mb-0">{type}</span>*/}
                                    {/*</li>*/}
                                    <li className="list-group-item d-flex px-0 justify-content-between row">
                                        <strong className={"col-3"}>Title (EN)</strong>
                                        <span className={"col-9"}>{titleEn}</span>
                                    </li>
                                    <li className="list-group-item d-flex px-0 justify-content-between row">
                                        <strong className={"col-3"}>Body (EN)</strong>
                                        <span className={"col-9"}>{bodyEn}</span>
                                    </li>
                                    <li className="list-group-item d-flex px-0 justify-content-between row">
                                        <strong className={"col-3"}>Title (NL)</strong>
                                        <span className={"col-9"}>{titleNl}</span>
                                    </li>
                                    <li className="list-group-item d-flex px-0 justify-content-between row">
                                        <strong className={"col-3"}>Body (NL)</strong>
                                        <span className={"col-9"}>{bodyNl}</span>
                                    </li>

                                    {/*url*/}
                                    <li className="list-group-item d-flex px-0 justify-content-between flex-column">
                                        <strong>Notification item</strong>
                                        {type.toString().includes("url") &&
                                            <span className="mb-0">{notificationItem.url}</span>}


                                        {/*online*/}

                                        {type.toString().includes("cashback") && <div className="widget-media mt-4">
                                            <ul className="timeline">
                                                <li className={"cursor-pointer"}>
                                                    <div className={"timeline-panel"}>
                                                        <div className="media me-4">
                                                            <img alt="" width="50"
                                                                 src={notificationItem.store.logo}/>
                                                        </div>
                                                        <div className="media-body">
                                                            <h5 className="mb-1">{notificationItem.store.title}</h5>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>}


                                        {/*instore*/}
                                        {type.toString().includes("instore") && <div className="widget-media mt-4">
                                            <ul className="timeline">
                                                <li className={"cursor-pointer"}>
                                                    <div className={"timeline-panel"}>
                                                        <div className="media me-4">
                                                            <img alt="" width="50"
                                                                 src={appUrls.backend_api_image + notificationItem.action.iconId}/>
                                                        </div>
                                                        <div className="media-body">
                                                            <h5 className="mb-1">{notificationItem.action.companyName}</h5>
                                                            <small className="d-block">
                                                                {notificationItem.action.localization.nl.subtitle}
                                                            </small>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>}

                                        {/*jobs*/}

                                        {type.toString().includes("job") && <div className="widget-media mt-4">
                                            <ul className="timeline">
                                                <li className={"cursor-pointer"}>
                                                    <div className={"timeline-panel"}>
                                                        <div className="media me-4">
                                                            <img alt="" width="50"
                                                                 src={notificationItem.job.logo}/>
                                                        </div>
                                                        <div className="media-body">
                                                            <h5 className="mb-1">{notificationItem.job.jobTitle}</h5>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>}


                                    </li>

                                    {mode.includes("production") &&
                                        <li className="list-group-item d-flex flex-column px-0 justify-content-start">
                                            <strong>Recipients</strong>
                                            <span className="m-1  d-flex flex-column justify-content-end text-right">
                                             <span
                                                 className="m-1 text-right">Country:
                                                 {
                                                    selectedFiltersForProductionMode.country == "" ?
                                                        " Both Countries"
                                                        :
                                                            selectedFiltersForProductionMode.country === 1 ?
                                                            " Netherlands"
                                                            :
                                                            " Belgium"
                                                }
                                            </span>
                                             <span className="m-1 text-right">Cities:
                                                 {selectedFiltersForProductionMode.cities.map((item) => {
                                                     return (<span className="m-1 text-right">{item.label}</span>)
                                                 })}</span>
                                                <span className="m-1 text-right">Users Type:
                                                    <span style={{textTransform: "capitalize"}}>
                                                        &nbsp;{
                                                            selectedFiltersForProductionMode.limitation != '' ?
                                                            selectedFiltersForProductionMode.limitation == 'limited_unlimited' ?
                                                                selectedFiltersForProductionMode.limitation.replaceAll('_', ' & ')
                                                                :
                                                                selectedFiltersForProductionMode.limitation.replaceAll('_', ' ')
                                                            :
                                                            'Limited & Unlimited'
                                                        }
                                                        </span>
                                                </span>
                                                <span className="m-1 text-right">Users Gender:
                                                    <span style={{textTransform: "capitalize"}}> {selectedFiltersForProductionMode.gender != '' ? selectedFiltersForProductionMode.gender : 'All Genders'}</span>
                                                </span>
                                                <span className="m-1 text-right">Account Owner:
                                                    <span style={{textTransform: "capitalize"}}> {selectedFiltersForProductionMode.accountOwner != '' ? selectedFiltersForProductionMode.accountOwner?.replaceAll('_', ' ') : 'Both'}</span>
                                                </span>
                                                {!!selectedFiltersForProductionMode.session && <span
                                                    className="m-1 text-right">Users Session: {selectedFiltersForProductionMode.session} days ago</span>}
                                                {!!selectedFiltersForProductionMode.group && <span
                                                    className="m-1 text-right">Target Group:
                                                    <span style={{textTransform: "capitalize"}}> {selectedFiltersForProductionMode.group}</span>
                                                    </span>
                                                    }
                                        </span>
                                        </li>}
                                </ul>
                            </div>
                            <div className="card-footer pt-0 pb-0 text-center">
                                <div className="row">
                                    <div className="col-6 pt-3 pb-3 border-right">
                                        <h3 className="mb-1 text-primary text-uppercase">
                                            {
                                                types.filter(item => { return item.value == type })[0]?.name
                                            }
                                        </h3>
                                        <span>Type</span>
                                    </div>
                                    <div className="col-6 pt-3 pb-3 d-flex flex-column align-items-center">
                                        {
                                            !receiversCount ?
                                                <img src={loader} style={{width : '30px'}} />
                                                :
                                                <h3 className="mb-1 text-primary">{receiversCount}</h3>
                                        }
                                        <span>Recipients</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="text-end toolbar toolbar-bottom p-2">
                <button className="btn btn-secondary sw-btn-prev me-1"
                        onClick={() => goBack()}>Prev
                </button>

                {mode.includes("production") && <button className="btn btn-primary sw-btn-next ms-1"
                                                        onClick={() => saveNotification()}>Save
                </button>}
            </div>
        </>
    );

};
