import { useEffect, useState } from "react";
import FormField from "../common/FormField";
import { getCities, getEducationInstitutions } from "../../../../Api/ApiCalls";
import { labelMappings } from "../constant";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const EntityModal = ({
  userData,
  submitUpdateUser,
  showEdit,
  isUpdating,
  setShowModal,
}) => {
  const [cities, setCities] = useState([]);
  const [educationInstitution, setEducationInstitution] = useState([]);
  const [modalFormData, setModalFormData] = useState(userData);
  const [modifiedData, setModifiedData] = useState({});
  const [isCrucialDataModified, setIsCrucialDataModified] = useState(false);
  const [includedDates, setIncludedDates] = useState(false);

  useEffect(() => {
    getCitiesForDropDown();
    getEducationInstitutionForDropDown();
    const element = document.querySelector('.PhoneInputInput');
    if (element) {
      element.style.border = 'none';
    }
    // setModalFormData(userData);
  }, []);

  const getCitiesForDropDown = async () => {
    try {
      getCities()
        .then((res) => {
          setCities(res);
        })
        .catch((err) => console.log(err));
    } catch (err) {}
  };

  const getEducationInstitutionForDropDown = async () => {
    try {
      getEducationInstitutions()
        .then((res) => {
          setEducationInstitution(res);
        })
        .catch((err) => console.log(err));
    } catch (err) {}
  };

  const handleModalFormUpdate = (name, value) => {
    let changedEntity = {};
    const criticalLabels = [
      "Account owner",
      "Channel",
      "Mandate number",
      "Incasso reference",
      "Bank account name",
      "IBAN",
      "BIC",
      "Credit Card",
    ];

    if (criticalLabels.includes(name)) {
      setIsCrucialDataModified(true);
    }

    if (name === "countryCode" || name === "phoneNumber") {

      // If one of them changes, update both to ensure consistency
      const { countryCode, phoneNumber } = modalFormData;

      changedEntity = {
        countryCode: name === "countryCode" ? value : countryCode,
        phoneNumber: name === "phoneNumber" ? value : phoneNumber
      };
    } else {
      // Update other fields normally
      changedEntity = { [labelMappings[name]]: value };
    }

    setModifiedData((prevData) => ({
      ...prevData,
      ...changedEntity
    }));
  };

  var date = new Date();
  date.setFullYear(date.getFullYear() - 14);

  return (
    <>
      <div className="modal-body">
        <i className="flaticon-cancel-12 close"></i>
        <div className="add-contact-box">
          <div className="add-contact-content">
            <div className="row">
              {/* Personal Data */}
              <label className="h2" style={{ color: "#f53c79" }}>
                Personal Data
              </label>
              <FormField
                label="First Name"
                name="firstName"
                value={modalFormData?.firstName}
                onChange={handleModalFormUpdate}
                className={"col-md-3"}
              />
              <FormField
                label="Email"
                name="email"
                value={modalFormData?.email}
                onChange={handleModalFormUpdate}
                className={"col-md-3"}
              />

              <FormField
                  label="Country Code"
                  name="countryCode"
                  value={modalFormData?.countryCode}
                  onChange={handleModalFormUpdate}
                  className={"col-md-3"}
              />

              <FormField
                  label="Phone Number"
                  name="phoneNumber"
                  value={modalFormData?.phoneNumber}
                  onChange={handleModalFormUpdate}
                  className={"col-md-3"}
              />


              <FormField
                label="Last Name"
                name="lastName"
                value={modalFormData?.lastName}
                onChange={handleModalFormUpdate}
                className={"col-md-3"}
              />
              <FormField
                label="Student Email"
                name="studentEmail"
                value={modalFormData?.studentEmail}
                onChange={handleModalFormUpdate}
                className={"col-md-3"}
              />
              <FormField
                label="Birthdate"
                name="birthdate"
                value={modalFormData?.birthdate}
                onChange={handleModalFormUpdate}
                className={"col-md-3"}
                type="date"
                maxDate={date}
              />
              <FormField
                label="Gender"
                name="gender"
                value={modalFormData?.gender}
                onChange={handleModalFormUpdate}
                className={"col-md-3"}
                type="select"
                options={[
                  { name: "male" },
                  { name: "female" },
                  { name: "others" },
                ]}
              />
            </div>
            <div className="">
              {/* Study */}

              <div className="">
                <label className="h2" style={{ color: "#f53c79" }}>
                  Study
                </label>
                <div className="d-flex">
                  <div className="w-50">
                    <FormField
                      label="Institution"
                      name="institution"
                      value={modalFormData?.education?.institutionName}
                      onChange={handleModalFormUpdate}
                      type="select"
                      options={educationInstitution}
                      selectWidth="520px"
                    />
                  </div>

                  <div className="w-50" style={{ marginLeft: "35px" }}>
                    <FormField
                      label="Student City"
                      name="studentCity"
                      value={modalFormData?.cityName}
                      onChange={handleModalFormUpdate}
                      type="select"
                      options={cities}
                      selectWidth="520px"
                    />
                  </div>
                </div>
              </div>
              {/* App */}

            </div>
            <div className="row">
              {/* Account Data */}
              <label className="h2" style={{ color: "#f53c79" }}>
                Account Data
              </label>
              <FormField
                label="Account owner"
                name="accountOwner"
                value={modalFormData?.accountOwner}
                onChange={handleModalFormUpdate}
                type="select"
                options={[{ name: 'Knaek NL', id: 'Knaek_NL'  }, { name: 'Knaek BE', id: 'Knaek_BE'  }]}
                className={"col-md-3"}
              />
              <FormField
                label="Mandate number"
                name="mandateNumber"
                value={modalFormData?.mandateNumber}
                onChange={handleModalFormUpdate}
                className={"col-md-3"}
              />
              <FormField
                label="Bank account name"
                name="bankAccountName"
                value={modalFormData?.paymentAccountDetails?.bankAccountName}
                onChange={handleModalFormUpdate}
                className={"col-md-3"}
              />
              <FormField
                label="BIC"
                name="bic"
                value={modalFormData?.paymentAccountDetails?.bic}
                onChange={handleModalFormUpdate}
                className={"col-md-3"}
              />
              <FormField
                label='Authorization reference'
                name='authorizationReference'
                value={modalFormData?.incassoReference}
                className={'col-md-3'}
                disabled
              />
              <FormField
                label="IBAN"
                name="iban"
                value={modalFormData?.paymentAccountDetails?.iban}
                onChange={handleModalFormUpdate}
                className={"col-md-3"}
              />
              <FormField
                label="Credit Card"
                name="creditCardNumber"
                value={modalFormData?.paymentAccountDetails?.creditCardNumber}
                onChange={handleModalFormUpdate}
                className={"col-md-3"}
              />
            </div>
          </div>
        </div>
      </div>
      {showEdit && (
        <div className="modal-footer">
          <button
            type="button"
            className="btn"
            style={{ backgroundColor: "#135846", color: "white" }}
            onClick={() =>
              submitUpdateUser(modifiedData, isCrucialDataModified)
            }
          >
            {isUpdating ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="spinner-border text-white" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              "Save"
            )}
          </button>
          <button
            type="button"
            onClick={() => setShowModal(false)}
            className="btn btn-warning"
            style={{ backgroundColor: "#E23428", color: "white" }}
          >
            <i className="flaticon-delete-1"></i> Cancel
          </button>
        </div>
      )}
    </>
  );
};

export default EntityModal;
