import React, {Fragment, useContext, useEffect, useState} from 'react';
import PerfectScrollbar from "react-perfect-scrollbar";
import {updateExpirationDateCodeCall, updateRatingActionExpCall} from "../../../Api/ApiCalls";
import {toast} from "react-toastify";
import {toastOptions} from "../../../config/Constants";
import {handleError, hasAValue} from "../../../utils/SharedFunctions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


export default function ActionsRatingChange() {
    const [knaekIds, setKnaekIds] = useState([])
    const [rating, setRating] = useState(undefined)


    return (
        <>
            <Fragment>
                <div className="row justify-content-center">
                    <div className="col-xl-12 col-xxl-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Knaaek Id Extend</h4>
                            </div>
                            <div className="card-body">

                                <section>
                                    <div className="row justify-content-center">
                                        <div className="col-lg-6 mb-2">
                                            <div className="form-group mb-3">
                                                <label className="text-label">Knaek-id's</label>
                                                <textarea
                                                    rows="23"
                                                    name="title"
                                                    className="form-control mb-3"
                                                    defaultValue={knaekIds}
                                                    onChange={val => {
                                                        convertToList(val.target.value)
                                                    }}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 mb-2">
                                            <div className="form-group mb-3">
                                                <label className="text-label">Knaek-ID’s</label>
                                                <div className="form-group mb-3">
                                                    <div className="card border-0 pb-0">
                                                        <div className="card-body border-top shadow rounded">
                                                            <PerfectScrollbar
                                                                style={{height: "435px"}}
                                                                id="DZ_W_Todo2"
                                                                className="widget-media overflow-auto height370 ps ps--active-y"
                                                            >
                                                                <ul className="timeline">
                                                                    {knaekIds.map((item, i) => {
                                                                        return (
                                                                            <li key={i} className={""}>
                                                                                <div className={"timeline-panel"}>
                                                                                    <div className="media-body">
                                                                                        <h5 className="mb-1">{item}</h5>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </PerfectScrollbar>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="col-lg-6 mb-2">
                                                <div className="form-group mb-3">
                                                    <label className="text-black font-w500">Rating</label>
                                                    <div className="contact-name">
                                                        <input type="text" className="form-control"
                                                               name="name" required="required"
                                                               defaultValue={rating}
                                                               onChange={(val) => setRating(val.target.value)}
                                                        />
                                                        <span className="validation-text"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <div className="text-end toolbar toolbar-bottom p-2">
                                    <button className="btn btn-primary sw-btn-next ms-1"
                                            onClick={() => updateExpirationDateCode()}>Extend
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        </>
    )

    function convertToList(items) {
        let itemsString = items.replace(/,/g, ' ')
        itemsString = itemsString.replace(/-/g, ' ')
        let array2 = itemsString.toString().split(/\s+/)
        setKnaekIds(array2)
    }

    function updateExpirationDateCode() {
        if (hasAValue(knaekIds) && hasAValue(rating)) {
            let data = {ids: knaekIds, rating}
            updateRatingActionExpCall(data).then(success => {
                toast.success(success.data.message, toastOptions)
                // setTimeout(() => {
                //     window.location = "/"
                // }, 3000)
            }).catch(error => {
                handleError(error)
            })
        }
    }


}
